import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import { Link } from 'react-router-dom';
import { LinkList, LinkListItem } from 'design-react-kit';
import sprite2 from "../assets/svg/sprite.svg";
import siape from '../assets/img/siape.png';
import certificazione_edifici from '../assets/img/certificazione_edifici.png';

function Ape() {
  return (
    <div>
    <HeaderTop />
          <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="servizi">Servizi</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Certificazione Energetica degli edifici
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Certificazione Energetica degli edifici</h1>
                            <div className="row">
                                <div className="col-md-9">
                                La certificazione energetica degli edifici è uno dei più importanti strumenti per la comprensione e la definizione delle prestazioni energetiche del patrimonio edilizio nazionale attraverso la redazione di un attestato di prestazione energetica (APE) che riporta la classe energetica dell’immobile certificato.
                                </div>
                                <div className="col-md-3">
                                <Link className="nav-link text-right" to="statistiche"><span class="btn btn-primary text-white">Dati Nazionali</span></Link>
                                </div>
                           </div>
                            <div className="row border-top row-column-border row-column-menu-left">
                                <aside className="col-lg-4">
                                    <div className="sticky-wrapper navbar-wrapper">
                                    <nav
                                        className="navbar it-navscroll-wrapper it-top-navscroll navbar-expand-lg"
                                    >
                                        <button
                                        className="custom-navbar-toggler"
                                        type="button"
                                        aria-controls="navbarNav"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                        data-target="#navbarNav"
                                        >
                                        <span className="it-list"></span>Sezioni
                                        </button>
                                        <div className="navbar-collapsable" id="navbarNav">
                                        <div className="overlay"></div>
                                        <div className="close-div sr-only">
                                            <button className="btn close-menu" type="button">
                                            <span className="it-close"></span>Chiudi
                                            </button>
                                        </div>
                                        <a className="it-back-button" href="/#">
                                            <svg className="icon icon-sm icon-primary align-top">
                                            <use xlinkhref="/assets/bootstrap-italia/dist/svg/sprite.svg#it-chevron-left"></use>
                                            </svg>
                                            <span>Torna indietro</span></a
                                        >
                                        <div className="menu-wrapper">
                                            <div className="link-list-wrapper menu-link-list">
                                            <h3 className="no_toc">Sezioni</h3>
                                            <ul className="link-list">
                                                <li className="nav-item">
                                                <a className="nav-link" href="#descrizione"
                                                    ><span>Descrizione</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="#nazionale"
                                                    ><span>Portale Nazionale</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link font-weight-normal pt-0" href="#normative"
                                                    ><span>Normativa Nazionale</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="#regionale"
                                                    ><span>Portali Regionali</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link font-weight-normal pt-0" href="#normativeregionali"
                                                    ><span>Normativa Regionale</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a rel="noreferrer" href="http://www.portale4e.it/gestionale/Materiale_Articoli/Cittadini/APE/APE - Vademecum per il cittadino (versione stampabile).pdf" target="_blank"><span>Vademecum</span></a>
                                                </li>                                                
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </nav>
                                    </div>
                                </aside>
                                <section className="col-lg-8 it-page-sections-container">
                                    <article id="descrizione" className="it-page-section anchor-offset">
                                    <h4>Descrizione</h4>
                                    <p className="text-serif">
                                    L’attestato di prestazione energetica (APE) è un certificato, redatto da un certificatore, che attesta la prestazione e la classe energetica di un immobile e indica gli interventi migliorativi più convenienti per rendere l’edificio efficiente.

                                    </p>
                                    
                                    </article>
                                    <hr></hr>
                                    <article id="nazionale" className="it-page-section anchor-offset mt-5">
                                    <h4>LE PRESTAZIONI ENERGETICHE NAZIONALI</h4>
                                    <p className="text-serif">
                                    <img src={certificazione_edifici} className="float-right ml-2 mw-300 border" alt="certificazione energetica degli edifici"/>L’APE contiene una ricchezza di informazioni estremamente rilevante, a partire dall’etichetta energetica di ogni singolo immobile, fino ad arrivare alle emissioni di anidride carbonica prodotte dal settore edile. Tali informazioni, se messe a sistema, permettono di svolgere analisi e valutazioni sul patrimonio edilizio nazionale.<br/>
                                    Dal 2020, ENEA e Comitato Termotecnico Italiano (CTI) collaborano attivamente alla redazione del Rapporto Annuale sulla Certificazione Energetica degli Edifici dal 2020, nel quale si fotografano e si valutano i punti chiave relativi alla certificazione energetica locale e nazionale, anche attraverso l’analisi delle informazioni contenute negli APE.<br/>
                                    Attraverso i link di seguito è possibile consultare le due annualità finora pubblicate:<br/>
                                    <ul>
                                        <li><a href="https://www.efficienzaenergetica.enea.it/pubblicazioni/rapporto-annuale-sulla-certificazione-energetica-degli-edifici-2020.html" target="_blank" rel="noreferrer">2020</a> </li>
                                        <li><a href="https://www.efficienzaenergetica.enea.it/pubblicazioni/rapporto-annuale-sulla-certificazione-energetica-degli-edifici-2021.html" target="_blank" rel="noreferrer">2021</a> </li>
                                    </ul>
                                    <img src={siape} className="float-right ml-2 mw-300 border" alt="siape"/>Le elaborazioni ricavate dagli APE nazionali possono essere consultate anche tramite il <b>Sistema Informativo sugli Attestati di Prestazione Energetica (SIAPE)</b>, istituito con D.M. 26/06/2015 e creato e gestito da ENEA.<br/>
                                    Il SIAPE è lo strumento nazionale per la raccolta degli APE di edifici e unità immobiliari e ha lo scopo primario di restituire una immagine dettagliata dello stato dell'arte della riqualificazione energetica del parco edilizio nazionale.<br/>
                                    Per accedere al Portale SIAPE clicca <a href="https://siape.enea.it/" target="_blank" rel="noreferrer">qui</a><br/>
                                    Per ulteriori informazioni sul SIAPE clicca <a href="https://www.efficienzaenergetica.enea.it/servizi-per/pubblica-amministrazione/per-le-regioni/sistema-informativo-sugli-attestati-di-prestazione-energetica.html" target="_blank" rel="noreferrer">qui</a>
                                    </p>
                                    </article>
                                    <article id="normative" className="it-page-section anchor-offset mt-5">
                                    <h4>Normativa Nazionale</h4>
                                    <p className="text-serif">
                                    A livello nazionale, il principale riferimento legislativo in tema di efficienza energetica è costituito dal D.Lgs. 192/2005 che, congiuntamente a tutti i disposti legislativi di modifica e integrazione, rappresenta il recepimento delle Direttive Europee sulla prestazione energetica degli edifici (Energy Performance Building Directive - EPBD).<br/>
                                    In Italia, la classe energetica degli edifici è stata introdotta nel 2005, con il recepimento della Direttiva Europea 2002/91/CE (EPBD I), tramite l’Attestato di Certificazione Energetica (ACE) sostituito poi nel 2015, recependo la Direttiva 2010/31/UE (EPBD recast), con l’Attestato di Prestazione Energetica (APE), in cui si è modificato anche il metodo di calcolo per la determinazione della classe energetica.<br/>

                                    L’attuale metodologia di calcolo per la certificazione energetica, nonché i requisiti minimi di efficienze energetica per alcune categorie di edificio sono contenuti nella serie di Decreti Interministeriali 26/06/2015 suddivisa in tre decreti interministeriali che contengono le disposizioni attuative previste dalla Legge 90/2013. In particolare, sono suddivisi in:
                                    <LinkList>
                                            <LinkListItem>
                                                <span>
                                                    <a href="https://www.mise.gov.it/index.php/it/normativa/decreti-interministeriali/2032966-decreto-interministeriale-26-giugno-2015-applicazione-delle-metodologie-di-calcolo-delle-prestazioni-energetiche-e-definizione-delle-prescrizioni-e-dei-requisiti-minimi-degli-edifici" target="_blank" rel="noreferrer" className="pl-0">
                                                    <svg className="icon">
                                                    <use href={ sprite2 + "#it-pa" }></use>
                                                    </svg> D.M. “Requisiti minimi” </a>
                                                </span>
                                                <p>definisce le attuali modalità di calcolo della prestazione energetica degli edifici e i nuovi requisiti minimi di efficienza per gli edifici di nuova costruzione e per quelli sottoposti a ristrutturazione. Il D.M. stabilisce inoltre le tipologie e i livelli di intervento, in funzione dei quali i requisiti minimi e le prescrizioni da rispettare possono variare, e introduce la definizione di edificio ad energia quasi zero (NZEB)</p>
                                            </LinkListItem>
                                            <LinkListItem>
                                                <span>
                                                    <a href="https://www.mise.gov.it/index.php/it/normativa/decreti-interministeriali/2032968-decreto-interministeriale-26-giugno-2015-adeguamento-linee-guida-nazionali-per-la-certificazione-energetica-degli-edifici" target="_blank" rel="noreferrer" className="pl-0">
                                                    <svg className="icon">
                                                        <use href={ sprite2 + "#it-pa" }></use>
                                                    </svg> D.M. “Adeguamento linee guida nazionali per la certificazione energetica degli edifici” </a>
                                                </span>
                                                <p>uniforma l’applicazione della certificazione energetica degli edifici, concedendo alle Regioni due anni di tempo per adeguare i propri strumenti regionali, qualora già in vigore</p>                                        
                                             </LinkListItem>
                                            <LinkListItem>
                                                <span> 
                                                    <a href="https://www.mise.gov.it/index.php/it/normativa/decreti-interministeriali/2032967-decreto-interministeriale-26-giugno-2015-schemi-e-modalita-di-riferimento-per-la-compilazione-della-relazione-tecnica-di-progetto-ai-fini-dell-applicazione-delle-prescrizioni-e-dei-requisiti-minimi-di-prestazione-energetica-negli-edifici" target="_blank" rel="noreferrer" className="pl-0">
                                                <svg className="icon">
                                                    <use href={ sprite2 + "#it-pa" }></use>
                                                </svg>	D.M. “Schemi e modalità di riferimento per la compilazione della relazione tecnica di progetto ai fini dell’applicazione delle prescrizioni e dei requisiti minimi di prestazione energetica negli edifici”</a>
                                                </span>
                                                <p>definisce gli schemi di relazione tecnica di progetto, adeguati al nuovo quadro normativo, in funzione delle diverse tipologie di intervento previste. </p>                                     
                                             </LinkListItem>
                                            
                                        </LinkList>
                        
                                  

                                    <p className="mt-3">Di seguito sono riportarti una serie di chiarimenti al DM 26/06/2015. </p>
                                    <h6>APE e riquisiti minimi</h6>
                                    <LinkList>
                                            <LinkListItem>
                                                <span>
                                                    <a href="https://www.mise.gov.it/index.php/it/assistenza/domande-frequenti/2033507-efficienza-energetica-degli-edifici-faq" target="_blank" rel="noreferrer" className="pl-0">
                                                    <svg className="icon">
                                                    <use href={ sprite2 + "#it-pa" }></use>
                                                    </svg> Prima serie </a>
                                                </span>
                                            </LinkListItem>
                                            <LinkListItem>
                                                <span>
                                                    <a href="https://www.mise.gov.it/index.php/it/assistenza/domande-frequenti/2034997-ape-attestato-di-prestazione-energetica-nuova-serie-di-faq" target="_blank" rel="noreferrer" className="pl-0">
                                                    <svg className="icon">
                                                        <use href={ sprite2 + "#it-pa" }></use>
                                                    </svg> Seconda serie </a>
                                                </span>
                                             </LinkListItem>
                                            <LinkListItem>
                                                <span> 
                                                    <a href="https://www.mise.gov.it/images/stories/documenti/Allegato-3-FAQ-Efficienza-Energetica-Edifici-Terza-serie.pdf" target="_blank" rel="noreferrer" className="pl-0">
                                                <svg className="icon">
                                                    <use href={ sprite2 + "#it-pa" }></use>
                                                </svg>	Terza serie</a>
                                                </span>
                                             </LinkListItem>                                            
                                        </LinkList>
                                

                                    <h6>Contabilizzazione</h6>
                                    <LinkList>
                                        <LinkListItem>
                                            <span>
                                                <a href="https://www.mise.gov.it/index.php/it/assistenza/domande-frequenti/2036671-termoregolazione-e-contabilizzazione-del-calore-domande-frequenti-faq" target="_blank" rel="noreferrer" className="pl-0">
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-pa" }></use>
                                                </svg> Documento del 9 giugno 2017 </a>
                                            </span>
                                            <p>FAQ - Termoregolazione e contabilizzazione del calore</p>
                                        </LinkListItem>                                         
                                    </LinkList>
                                    <p> La serie delle specifiche tecniche UNI/TS 11300 costituisce l’ossatura operativa indispensabile per redigere gli attestati di prestazione energetica. Tali specifiche tecniche sono state elaborate dal CTI e sono disponibili sul catalogo dell’UNI. Le UNI/TS 11300 vengono aggiornate e adeguate nel tempo, recependo gli algoritmi di calcolo definiti dal Comitato Europeo di Normazione (CEN) e tenendo conto dell’evoluzione di nuove soluzioni tecnologiche nel mercato.
                                    Ulteriori informazioni sulla serie delle UNI/TS11300 possono essere consultate presso il <a href="https://www.cti2000.eu/la-uni-ts-11300/" target="_blank" rel="noreferrer">sito del Comitato Termotecnico Italiano (CTI)</a>.</p> 
                                    </p>
                                    </article>
                                    <hr></hr>
                                    <article id="regionale" className="it-page-section anchor-offset mt-5">
                                    <h4>LE PRESTAZIONI ENERGETICHE REGIONALI</h4>
                                    <p className="text-serif">
                                    Come funziona la certificazione energetica nella tua Regione o Provincia Autonoma? L’emissione degli APE è di competenza regionale. Per avere maggiori dettagli è bene consultare i siti regionali appositamente dedicati, dove potrai trovare:
                                    <ul>
                                        <li>La normativa vigente che regola la certificazione;</li>
                                        <li>L’elenco dei certificatori abilitati;</li>
                                        <li>L’elenco degli enti di formazione per l’abilitazione;</li>
                                        <li>Dati aggregati sugli APE redatti ad oggi;</li>
                                        <li>Sezione dedicata ai certificatori.</li>
                                    </ul>
                                    </p>
                                    <p className="text-serif">
                                        Di seguito i link per per consultare i siti informativi regionali sulla certificazione energetica!<br/><br/>
                                        <a href="//apeabruzzo.enea.it/" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Abruzzo</span></span></a>
                                        <a href="http://portalebandi.regione.basilicata.it/PortaleBandi/detail-istanzeonline.jsp?id=339568" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Basilicata </span></span></a>
                                        <a href="https://www.agenziacasaclima.it/it/certificazione-edifici-1405.html" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Bolzano </span></span></a>
                                        <a href="https://www.regione.calabria.it/website/organizzazione/dipartimento11/" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Calabria </span></span></a>
                                        <a href="http://sid.sviluppocampania.it/WebAccesso/Login.aspx" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Campania </span></span></a>
                                        <a href="http://energia.regione.emilia-romagna.it/certificazione-energetica/certificazione-energeticadegli-edifici" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Emilia-Romagna </span></span></a>
                                        <a href="https://energia.regione.fvg.it/home" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Friuli-Venezia Giulia </span></span></a>
                                        <a href="//apelazio.enea.it" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Lazio </span></span></a>
                                        <a href="https://servizi.regione.liguria.it/page/welcome/CERTIFICAZIONE_ENERGETICA_CITTADINI" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Liguria </span></span></a>
                                        <a href="http://www.cened.it/" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Lombardia </span></span></a>
                                        <a href="//apemarche.enea.it" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Marche </span></span></a>
                                        <a href="//apemolise.enea.it" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Molise </span></span></a>
                                        <a href="https://www.regione.piemonte.it/web/temi/sviluppo/sviluppo-energetico-sostenibile/sistemainformativo-per-prestazione-energetica-degli-edifici-sipee" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Piemonte </span></span></a>
                                        <a href="https://www.apepuglia.enea.it/" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Puglia </span></span></a>
                                        <a href="http://www.regione.sardegna.it/j/v/2419?s=1&v=9&c=93219&es=6603&na=1&n=10&tb=15028" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Sardegna  </span></span></a>
                                        <a href="//www.apesicilia.enea.it/" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Sicilia  </span></span></a>
                                        <a href="http://www.siert.regione.toscana.it/" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Toscana  </span></span></a>
                                        <a href="http://www.energia.provincia.tn.it/certificazione_edifici/" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Trento  </span></span></a>
                                        <a href="http://ape.regione.umbria.it/" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Umbria  </span></span></a>
                                        <a href="http://www.regione.vda.it/energia/certificazioneenergetica/default_i.aspx" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Valle d’Aosta  </span></span></a>
                                        <a href="http://www.regione.veneto.it/web/energia/prestazione-energetica-degli-edifici" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary mr-5"><span class="chip-label">Veneto  </span></span></a>
                                    </p>
                                    </article>
                                    <article id="normativeregionali" className="it-page-section anchor-offset mt-5">
                                    <h4>Normativa Regionale</h4>
                                    <p className="text-serif">
                                    Le normative regionali presenti riguardano solo quelle regioni il cui sistema di invio degli attestati di prestazione energetica è gestito da ENEA.
                                        <LinkList>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://apelazio.enea.it\rif-legislativi.php" target="_blank" rel="noreferrer">APE LAZIO </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://apeabruzzo.enea.it/rif-legislativi.php" target="_blank" rel="noreferrer">APE ABRUZZO </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.apepuglia.enea.it/rif-legislativi.php" target="_blank" rel="noreferrer">APE PUGLIA </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.apecalabria.enea.it/rif-legislativi.php" target="_blank" rel="noreferrer">APE CALABRIA </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.apemarche.enea.it/riferimenti-legislativi" target="_blank" rel="noreferrer">APE MARCHE </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.apemolise.enea.it/rif-legislativi.php" target="_blank" rel="noreferrer">APE MOLISE </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.apesicilia.enea.it/riferimenti-legislativi" target="_blank" rel="noreferrer">APE SICILIA </a></span>
                                            </LinkListItem>
                                        </LinkList>
                                    </p>
                                    </article>
                                </section>
                                </div>

                            </div>

                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Ape;
