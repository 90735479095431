import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../scss/bootstrap-italia-custom.scss";
import "../App.css";
import { Link } from 'react-router-dom';
import attivita from '../assets/img/attivita.jpg';


function Attivita() {
  return (
    <div>
    <HeaderTop />
           <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Attività
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Attività</h1>
                            <p className="mt-4">
                            Le attività, che sono iniziate nell’ultimo quadrimestre del 2021 si protrarranno nei successivi quattro anni, e porteranno allo sviluppo di un prodotto che sarà il punto di riferimento nazionale per la prestazione energetica degli edifici.<br/><br/> 
                            La fase di implementazione, per natura di questo progetto, sarà continua ed in costante aggiornamento man mano che le sorgenti dati si renderanno disponibili. <br/><br/>

                            Al fine di progettare il portale in tutte le sue funzionalità è stato essenziale definire e catalogare i contenuti in base all’utenza.<br/>
                            In base al Decreto Legislativo 10 giugno 2020 e alle esigenze di interrogare altri database sono state definite le funzioni che il portale deve fornire agli utenti. Questa attività, coinvolge ENEA, MiTe, gli enti Locali, Regionali e Nazionali che dovranno fornire accesso ai vari database. <br/> 

                            Le attività si sono state divise in::
                                <ul>
                                    <li>Analisi dei servizi richiesti e delle categorie di utenza;</li>
                                    <li>Progettazione delle funzionalità pubbliche;</li>
                                    <li>Progettazione delle funzionalità private;</li>
                                    <li>Identificazione ed import dei dati necessari per l’implementazione delle varie funzionalità.</li>
                                </ul>
                                La struttura del nucleo principale del portale e delle relative attività, è infatti pensata come un flusso non sequenziale quindi non è stato pensato come una serie di task temporali, come indicato nella seguente figura, ma come un prodotto in continuo aggiornamento.
                                <div className="text-center my-5">
                                <img className="m-auto w-100 mw-70"
                                    src={attivita}
                                    alt='Edificio'
                                    title='Edificio'
                                />
                                </div>
                            </p>
                            </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Attivita;