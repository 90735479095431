import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import { Link } from 'react-router-dom';
import news1 from "../assets/img/news1.jpg";
import news2 from "../assets/img/news2.jpg";
import news3 from "../assets/img/news3.jpg";
import sprite2 from "../assets/svg/sprite.svg";



function Notizie() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Notizie
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Notizie</h1>
                            <p className="mt-4">
                                <div className="row py-4">
                                    <div className="col-xs-12 col-md-4">
                                        <div className="card-wrapper">
                                        <div className="card card-img no-after rounded shadow">
                                            <div className="img-responsive-wrapper">
                                            <div className="img-responsive img-responsive-panoramic">
                                                <figure className="img-wrapper">
                                                <img src={news1} title="news1" alt="news1" />
                                                </figure>
                                                
                                            </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="category-top">
                                                    <svg className="icon">
                                                    <use href={ sprite2 + "#it-calendar" }></use>
                                                    </svg>
                                                    <span>21 Marzo 2022</span>
                                                </div>
                                            <h4 className="card-title">
                                            Al via nelle scuole dell’Emilia Romagna la 4° edizione della White Energy Week  
                                            </h4>
                                            <p className="card-text">
                                            Prende il via oggi, in 25 classi di istituti scolastici di secondo grado dell’Emilia Romagna, la quarta edizione della White Energy Week, il progetto per le competenze trasversali e l’orientamento (P.C.T.O.) promosso da Seaside, ESCo del Gruppo Italgas, e sviluppato in collaborazione con ENEA e Ufficio Scolastico Regionale dell’Emilia Romagna.
                                            </p>
                                            <div className="mt-5">
                                            <a className="read-more" href="https://www.efficienzaenergetica.enea.it/vi-segnaliamo/al-via-nelle-scuole-dell-emilia-romagna-la-4-edizione-della-white-energy-week.html" target="_blank" rel="noreferrer">
                                                <span className="text">Leggi tutto</span>
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-arrow-right" }></use>
                                                </svg>
                                            </a>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div> 
                                    <div className="col-xs-12 col-md-4">
                                        <div className="card-wrapper">
                                        <div className="card card-img no-after rounded shadow">
                                            <div className="img-responsive-wrapper">
                                            <div className="img-responsive img-responsive-panoramic">
                                                <figure className="img-wrapper">
                                                <img src={news2} title="news2" alt="news2" />
                                                </figure>
                                                
                                            </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="category-top">
                                                    <svg className="icon">
                                                    <use href={ sprite2 + "#it-calendar" }></use>
                                                    </svg>
                                                    <span>18 Marzo 2022</span>
                                                </div>
                                            <h4 className="card-title">
                                            Online il Quaderno dell’efficienza energetica dedicato alle fonderie 
                                            </h4>
                                            <p className="card-text">
                                            È online la pubblicazione sulle fonderie, curata da Chiara Martini, Fabrizio Martini, Marcello Salvio e Claudia Toro del Dipartimento Unità Efficienza Energetica, che costituisce il terzo volume della collana “Quaderni dell’efficienza energetica” realizzata da ENEA nell’ambito dell’Accordo di Programma MiSE (ora in capo al MiTE) - ENEA: “Ricerca di Sistema Elettrico PTR 2019 – 2021”.
                                            </p>
                                            <div className="mt-5">
                                            <a className="read-more" href="https://www.efficienzaenergetica.enea.it/vi-segnaliamo/online-il-quaderno-dell-efficienza-energetica-dedicato-alle-fonderie.html" target="_blank" rel="noreferrer">
                                                <span className="text">Leggi di più</span>
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-arrow-right" }></use>
                                                </svg>
                                            </a>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                        <div className="card-wrapper">
                                        <div className="card card-img no-after rounded shadow">
                                            <div className="img-responsive-wrapper">
                                            <div className="img-responsive img-responsive-panoramic">
                                                <figure className="img-wrapper">
                                                <img src={news3} title="news3" alt="news3" />
                                                </figure>
                                                
                                            </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="category-top">
                                                    <svg className="icon">
                                                    <use href={ sprite2 + "#it-calendar" }></use>
                                                    </svg>
                                                    <span>16 Marzo 2022 </span>
                                                </div>
                                            <h4 className="card-title">
                                            Orizzonti e prospettive del SIAPE nell’ultimo numero di “Energia e Dintorni” 
                                            </h4>
                                            <p className="card-text">
                                            E’ stato pubblicato sull’ultimo numero di “Energia e Dintorni”, la rivista del CTI (Comitato Termotecnico Italiano), l’intervento a firma dei ricercatori e tecnici del Dipartimento Unità Efficienza Energetica (DUEE) dell’ENEA, Francesca Pagliaro, Vincenzo Del Fatto e Roberto Guida, sul tema del SIAPE, il Sistema Informativo sugli Attestati Di Prestazione Energetica.
                                            </p>
                                            <div className="mt-5">
                                            <a className="read-more" href="https://www.efficienzaenergetica.enea.it/vi-segnaliamo/orizzonti-e-prospettive-del-siape-nell-ultimo-numero-di-energia-e-dintorni.html" target="_blank" rel="noreferrer">
                                                <span className="text">Leggi di più</span>
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-arrow-right" }></use>
                                                </svg>
                                            </a>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                                    <div className="row">
                                    <div className="col text-center">
                                        <a href="https://www.efficienzaenergetica.enea.it/vi-segnaliamo.html" target="_blank" rel="noreferrer" className="btn btn-primary mt-2 w-100">Tutte le news</a>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </p>
                            </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Notizie;