import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import odesse from "../assets/img/odesse.png";
import "../App.css";
import { Link } from 'react-router-dom';


function Odesse() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <a href="/software">Software</a><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Odesse
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Odesse</h1>
                            <div className="row">
                                <p className="col-md-8">

                                ENEA mette a disposizione dei progettisti il software ODESSE, uno strumento di modellazione dinamica per stimare la fattibilità tecnico-economica di interventi di riqualificazione energetica, anche estesi a più edifici. Il software offre un supporto determinante nel valutare l’integrazione di più tecnologie ad alta efficienza energetica (fonti rinnovabili, cogenerazione, solar cooling ecc.) in funzione delle caratteristiche meteo del sito e delle richieste del contesto territoriale, in modo da aumentarne l’efficienza e la competitività.<br/>

                                Per approfondimenti clicca <a href="https://www.enea.it/it/Ricerca_sviluppo/lenergia/ricerca-di-sistema-elettrico/accordo-di-programma-mise-enea-2009-2011/razionalizzazione-e-risparmio-nelluso-dellenergia-elettrica/tecnologie-per-lefficienza-energetica-nei-servizi/odesse-1" target={"_blank"} rel="noreferrer">qui</a> <br/>
                                Per scaricare il software vai alla seguente <a href="https://www.enea.it/it/Ricerca_sviluppo/lenergia/ricerca-di-sistema-elettrico/accordo-di-programma-mise-enea-2009-2011/razionalizzazione-e-risparmio-nelluso-dellenergia-elettrica/tecnologie-per-lefficienza-energetica-nei-servizi/odesse-1/download-del-software-odesse" target={"_blank"} rel="noreferrer">pagina</a> <br/>
                                </p>
                                <img className="col-md-4 mw-300 h-100" src={odesse} title="odesse" alt="odesse" />
                            </div>
                           
                         </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Odesse;