import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import sire from "../assets/img/sire.png";
import "../App.css";
import { Link } from 'react-router-dom';


function Sire() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                               <Link to="/software">Software</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Sire
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Sire</h1>
                            <div className="row">
                                <p className="col-md-8">
                                L’applicativo S.I.R.E. offre la possibilità di effettuare in modo speditivo una “pre-analisi” energetica degli edifici e consente di avere un quadro delle potenzialità e delle opportunità offerte da un ventaglio diversificato di interventi di riqualificazione proposti. <br/>
                                Il tool consente di quantificare direttamente in prima approssimazione la prestazione energetica dell’edificio oggetto di analisi, di ipotizzare l’attuazione di alcuni interventi di riqualificazione “pre-costituiti” a partire dallo stato di fatto, di valutare l’esito di tali azioni sia in termini di riduzione dei consumi energetici che di contenimento delle spese imputabili alle fatturazioni energetiche. S.I.R.E. genera un Report che evidenzia una graduatoria degli interventi proposti in funzione alla loro convenienza tecnica ed economica.<br/>
                                Per approfondimenti vai <a href="https://www.enea.it/it/seguici/events/es-pa-s-i-r-e/save-the-date-presentazione-dell2019applicativo-enea-s-i-r-e" target={"_blank"} rel="noreferrer">qui</a>.<br/>
                                Per utilizzare l’applicativo clicca <a href="https://sire.enea.it/" target={"_blank"} rel="noreferrer">qui</a>                             
                                </p>
                                <img className="col-md-4 mw-300 h-100" src={sire} title="sire" alt="sire" />
                            </div>
                           
                         </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Sire;