import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import { Link } from 'react-router-dom';
import evento1 from "../assets/img/evento1.png";
import evento2 from "../assets/img/evento2.jpg";
import sprite2 from "../assets/svg/sprite.svg";



function Eventi() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Eventi
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Eventi</h1>
                            <p className="mt-4">
                                <div className="row py-4">
                                    <div className="col-xs-12 col-md-4">
                                        <div className="card-wrapper">
                                        <div className="card card-img no-after rounded shadow">
                                            <div className="img-responsive-wrapper">
                                            <div className="img-responsive img-responsive-panoramic">
                                                <figure className="img-wrapper">
                                                <img src={evento1} title="evento1" alt="evento1" />
                                                </figure>
                                                
                                            </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="category-top">
                                                    <svg className="icon">
                                                    <use href={ sprite2 + "#it-calendar" }></use>
                                                    </svg>
                                                    <span>24 marzo 2022 , Napoli </span>
                                                </div>
                                            <h6 className="card-title">
                                            Energy Med 2022: Sostenibilità e innovazione. Le opportunità per le imprese nell’ambito dei servizi offerti da Enterprise Europe Network 
                                            </h6>
                                            <div className="mt-5">
                                            <a className="read-more" href="https://www.enea.it/it/seguici/events/energy-med-2022/energy-med-2022-sostenibilita-e-innovazione-le-opportunita-per-le-imprese-nell2019ambito-dei-servizi-offerti-da-enterprise-europe-network" target="_blank" rel="noreferrer">
                                                <span className="text">Leggi tutto</span>
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-arrow-right" }></use>
                                                </svg>
                                            </a>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div> 
                                    <div className="col-xs-12 col-md-4">
                                        <div className="card-wrapper">
                                        <div className="card card-img no-after rounded shadow">
                                            <div className="img-responsive-wrapper">
                                            <div className="img-responsive img-responsive-panoramic">
                                                <figure className="img-wrapper">
                                                <img src={evento2} title="evento2" alt="evento2" />
                                                </figure>
                                                
                                            </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="category-top">
                                                    <svg className="icon">
                                                    <use href={ sprite2 + "#it-calendar" }></use>
                                                    </svg>
                                                    <span>28 - 31 marzo 2022, Il Cairo (Egitto) </span>
                                                </div>
                                            <h6 className="card-title">
                                            Save the date! MeetMED Week
                                            </h6>
                                            <div className="mt-5">
                                            <a className="read-more" href="https://www.enea.it/it/seguici/events/meetmed/save-the-date-meetmed-week-1" target="_blank" rel="noreferrer">
                                                <span className="text">Leggi di più</span>
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-arrow-right" }></use>
                                                </svg>
                                            </a>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                        <div className="card-wrapper">
                                        <div className="card card-img no-after rounded shadow">
                                            <div className="img-responsive-wrapper">
                                            <div className="img-responsive img-responsive-panoramic">
                                                <figure className="img-wrapper">
                                                <img src={evento2} title="evento2" alt="evento2" />
                                                </figure>
                                                
                                            </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="category-top">
                                                    <svg className="icon">
                                                    <use href={ sprite2 + "#it-calendar" }></use>
                                                    </svg>
                                                    <span>16 Marzo 2022 </span>
                                                </div>
                                            <h6 className="card-title">
                                            Rivoluzione verde e transizione ecologica - La Missione 2 del PNRR tra obiettivi green, caro energia, innovazione e informazione
                                            </h6>
                                            <div className="mt-5">
                                            <a className="read-more" href="https://www.enea.it/it/seguici/events/giornalisti/rivoluzione-verde-e-transizione-ecologica-la-missione-2-del-pnrr-tra-obiettivi-green-caro-energia-innovazione-e-informazione" target="_blank" rel="noreferrer">
                                                <span className="text">Leggi di più</span>
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-arrow-right" }></use>
                                                </svg>
                                            </a>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                                    <div className="row">
                                    <div className="col text-center">
                                        <a href="https://www.enea.it/it/seguici/events" target="_blank" rel="noreferrer" className="btn btn-primary mt-2 w-100">Tutti gli eventi</a>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </p>
                            </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Eventi;