import React, { useEffect, useState } from "react";

import "../App.css";
//import sprite2 from "../assets/svg/sprite.svg";
// import { Link } from "react-router-dom";
import {Button,Modal,ModalBody,ModalFooter } from 'design-react-kit';
import { Row,Col } from 'design-react-kit';
import sprite2 from "../assets/svg/sprite.svg";
// import Map from "./Mappaimmobile.js";




  const ContentLibrettiImpianto= (item,props,simval,simvalTot) => {

    return(
      <div>
        <article id="dettaglioape" className="it-page-section anchor-offset">

            <div className="card-wrapper card-teaser-wrapper">
                <div className="card  shadow mt-3 rounded">
                <div className="card-body">

                    <h5 className="card-title">
                      <svg className="icon mr-2">
                        <use href={ sprite2 + "#it-camera" }></use>
                        </svg>
                        CODICE LIBRETTO: 1132016
                    </h5>
                    <div className="card-text mt-2">


                        <h5 className="mb-5">Dettaglio impianti</h5>
                        <Row  className="mb-3">
                          <Col xs='12'>
                              <dl className='row'>
                                <dt className='col-sm-5'>Tipologia</dt>
                                <dd className='col-sm-7'>
                                  GRUPPI TERMICI O CALDAIE
                                </dd>
                                <dt className='col-sm-5'>Data di installazione</dt>
                                <dd className='col-sm-7'>
                                   11/10/2021
                                </dd>
                                <dt className='col-sm-5'>Fabbricante</dt>
                                <dd className='col-sm-7'>
                                  VAILLANT
                                </dd>
                                <dt className='col-sm-5'>Modello</dt>
                                <dd className='col-sm-7'>
                                   ECOTEC INTRO VMW 18/24 AS 1/1
                                </dd>
                                <dt className='col-sm-5 mt-2'>Matricola</dt>
                                <dd className='col-sm-7'>
                                  195376842
                                </dd>
                                <dt className='col-sm-5 mt-2'>Potenza termica utile</dt>
                                <dd className='col-sm-7'>
                                  18.30 (kW)
                                </dd>
                                <dt className='col-sm-5 mt-2'>Rendimento termico utile</dt>
                                <dd className='col-sm-7'>
                                  96 (%)
                                </dd>

                              </dl>

                          </Col>




                        </Row>
                        <hr/>
                        <Row  className="mb-3">
                          <Col xs='12'>
                              <dl className='row'>
                                <dt className='col-sm-5'>Tipologia</dt>
                                <dd className='col-sm-7'>
                                   MACCHINE FRIGORIFERE / POMPE DI CALORE
                                </dd>
                                <dt className='col-sm-5'>Data di installazione</dt>
                                <dd className='col-sm-7'>
                                   26/07/2021
                                </dd>
                                <dt className='col-sm-5'>Fabbricante</dt>
                                <dd className='col-sm-7'>
                                  DAIKIN
                                </dd>
                                <dt className='col-sm-5'>Modello</dt>
                                <dd className='col-sm-7'>
                                   EVLQ08CAV3
                                </dd>
                                <dt className='col-sm-5 mt-2'>Matricola</dt>
                                <dd className='col-sm-7'>
                                  J017375
                                </dd>
                                <dt className='col-sm-5 mt-2'>Potenza termica nominale</dt>
                                <dd className='col-sm-7'>
                                  7.30 (kW)
                                </dd>
                                <dt className='col-sm-5 mt-2'>Potenza assorbita nominale</dt>
                                <dd className='col-sm-7'>
                                  1.66 (kW)
                                </dd>

                              </dl>

                          </Col>




                        </Row>
                    </div>
                </div>
              </div>
            </div>

        </article>

        </div>

      )
  }


function LibrettiImpianto(props) {

  const [openLibrettiImpianto, toggleModalLibrettiImpianto] = useState(false);
  const [contentModalLibrettiImpianto, setContentModalLibrettiImpianto] = useState('');
  // const [smileestate, setSmileestate] = useState(smile1);
  // const [smileinverno, setSmileinverno] = useState(smile2);
  const toggleLibrettiImpianto = () => toggleModalLibrettiImpianto(!openLibrettiImpianto);




  const openModalLibrettiImpianto = (item) => {

          setContentModalLibrettiImpianto(ContentLibrettiImpianto(item,props));
          toggleModalLibrettiImpianto(true);

   };



  useEffect(() => {



  }, []);



    return (
      <div>
              <div className="">

                      <p className="card-text text-sans-serif">
                          <table className="table  w-100">
                            <thead>
                              <tr>
                                <th scope="col">Codice Libretto</th>
                                <th scope="col">Num. impianti</th>
                                <th scope="col">Responsabile Libretto</th>
                                <th scope="col">Terzo responsabile</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                                  <tr>
                                    <td>1132016 </td>
                                    <td>2</td>
                                    <td>Alessandro Bianchi</td>
                                    <td>F.T. Impianti S.R.L</td>
                                    <td>
                                    <Button color='primary' onClick={() => openModalLibrettiImpianto([])} className='mr-3'  size='xs'>
                                      Dettagli
                                    </Button>
                                    </td>
                                  </tr>

                            </tbody>
                          </table>
                            <Modal fade centered isOpen={openLibrettiImpianto} toggleLibrettiImpianto={toggleLibrettiImpianto} labelledBy='esempio14' size='lg'>

                              <ModalBody>
                                {contentModalLibrettiImpianto}
                              </ModalBody>
                              <ModalFooter>
                                <Button color='secondary' onClick={toggleLibrettiImpianto} size='xs'>
                                  Chiudi
                                </Button>
                              </ModalFooter>
                            </Modal>
                      </p>
                    </div>
                  </div>


    )

};

export default LibrettiImpianto;
