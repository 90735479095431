import React, { useEffect, useState } from "react";
import L from "leaflet";
import { MapContainer, GeoJSON, useMap } from "react-leaflet";
import axios from "axios";


const MyData = () => {
  const [datiRegione, setDatiApe] = useState();
  const [data, setData] = useState();
  const mappa = useMap();
  mappa.dragging.disable();
  mappa.touchZoom.disable();
  mappa.doubleClickZoom.disable();
  mappa.scrollWheelZoom.disable();


  function listItem(temp,regione){

    let stringa='<div className="card card-teaser rounded w-100 p-0"><div className="card-body w-100"><h5 className="card-title">'+regione+'</h5><br/><ul className="list-group">'

    Object.keys(temp).map((key, value) =>{

      if(key!=='cod_reg'){
          let val=temp[key]
          stringa+=  '<li className="list-group-item d-flex justify-content-between align-items-center p-2 text-uppercase font-weight-bold">'+String(key.replaceAll('_',' '))+' <span className="badge badge-light p-1">'+(String(val)!=='null'?Math.round(String(val)):'n.d.')+'</span></li>'
      }
      return stringa
      }
    )
    stringa+='</ul></div></div>'

    return stringa
  }

  const getValueMeta= (id,arr=[])=>{
      if(arr)
        return (arr.filter(s=>(s.cod_reg)===(id)).pop())
    }


  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        "/data/regioni.geo.json"
      );
      setData(response.data);
    };
    const getdatiRegione = async () => {
      const response = await axios.get(
         "//pnpe2.enea.it/api/data/pnpe2_ape_regione_18_23"
      //  "http://localhost:3001/api/data/gis/pnpe2_gis_ape_regione"
      );
      //console.log(response.data.data);
      //setDatiApe(response.data.data);
      setDatiApe(response.data);
      getData();

    };
    getdatiRegione();

  }, []);
  function style(feature) {
    return {
      fillColor: '#3388ff',
      weight: 3,
      opacity: 1,
      color: '#fff', //Outline color
      fillOpacity: .5
    };
  }





  if (data) {
    // These next 3 lines purely for debuggins:
    const geojsonObject = L.geoJSON(data);

    mappa.fitBounds(geojsonObject.getBounds());
    // end debugging

    const onEachRegion = (feature, layer) =>{
      let temp=getValueMeta(feature.properties.reg,datiRegione)

      layer.on('mouseover', function (e) {
        e.target.setStyle({
          fillColor: '#3388ff',
          weight: 3,
          opacity: 1,
          color: '#666', //Outline color
          fillOpacity: 1
      });
        if(typeof (temp)!='undefined'){

         // const testo='<div><b>Numero APE:</b> '+String(temp.ape)+'</div>'




          layer.bindPopup(listItem(temp,feature.properties.nome) + '<br/><br/>* Anni dal 2018 al 2023.',{minWidth:'200', autoPan: false, className: 'popup-fixed'}).openPopup(); // here add openPopup()

        }else{
          layer.bindPopup(('Dato non disponibile'),{minWidth:'200', autoPan: false, className: 'popup-fixed'}).openPopup(); // here add openPopup()

        }
      });
      layer.on('mouseout', function (e) {
        e.target.setStyle({
          fillColor: '#3388ff',
          weight: 3,
          opacity: 1,
          color: '#fff', //Outline color
          fillOpacity: .5
         });
      });
    }

    return <GeoJSON data={data}  onEachFeature={onEachRegion} style={style}/>

  } else {
    return null;
  }
};


const Map = (props) => {
  return (
    <MapContainer
      className={props.className}

      doubleClickZoom={false}
      id="mapId"
      zoom={8}
      center={[67.0166015625, 26.31311263768267]}
      zoomSnap= {0.1}
      zoomControl={false}
      attributionControl={false}

    >
      <MyData/>


    </MapContainer>
  );
};

export default Map;
