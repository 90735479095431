import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import victoria from "../assets/img/victoria.png";
import "../App.css";
import { Link } from 'react-router-dom';


function Victoria() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                               <Link to="/software">Software</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Victoria
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>V.I.C.T.O.R.I.A.</h1>
                            <div className="row">
                                <p className="col-md-8">
                                VICTORIA è un software «as a Service», finalizzato a fornire un ausilio per valutare gli effetti di possibili interventi di incremento dell’efficienza energetica e/o produzione da fonte rinnovabile su edifici pubblici, sia dal punto di vista energetico che economico, a valle dell’applicazione degli incentivi previsti dal Conto termico.<br/>
                                E’ rivolto in particolare alle pubbliche amministrazioni locali (come i Comuni), che avranno la possibilità di valutare gli effetti energetico-economici degli interventi previsti su una pluralità di edifici, ottenendo una stima dell’ammontare degli incentivi e una prima valutazione dei risparmi energetici annui e dei tempi di ritorno semplici, sia senza che con incentivo. <br/>
                                Per ulteriori approfondimenti clicca <a href="https://www.espa.enea.it/prodotti-e-servizi/software-per-la-pianificazione-territoriale-degli-investimenti-di-efficientamento-energetico-degli-edifici-pubblici-e-affiancamento-per-il-suo-utilizzo.html" target={"_blank"} rel="noreferrer">qui</a>. <br/>
                                Per utilizzare l’applicativo accedi <a href="https://victoria.enea.it/#/login" target={"_blank"} rel="noreferrer">qui</a><br/>
                                </p>
                                <img className="col-md-4 mw-300 h-100" src={victoria} title="victoria" alt="victoria" />
                            </div>
                           
                         </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Victoria;