import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../scss/bootstrap-italia-custom.scss";
import "../App.css";
 
const Error = () => {
    return (
        <div>
            <HeaderTop />
            <div class="container my-4">
                <section class="jumbotron text-center">
                <div class="row">
                    <h1 class="alert text-center mx-auto p-1 bg-danger text-white my-5">Errore: Pagina non trovata!</h1>
                </div>
                </section>
            </div>
        <FooterBottom />
        </div>
    );
} 
export default Error;