import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../scss/bootstrap-italia-custom.scss";
import "../App.css";
import { Link } from 'react-router-dom';


function Tecnologie() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Tecnologie
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Tecnologie</h1>
                            <p className="mt-4">
                            Da questa sezione è possibile accedere alla piattaforma SIMTE per avere  informazioni sulle prestazioni tecnico-ambientali e i costi attuali e attesi, gli aspetti normativi e di mercato per un ampio spettro di tecnologie per la produzione, la trasformazione e l'uso finale dell'energia dei dispositivi per uso domestico residenziale e commerciale. Pur rivolgendo particolare attenzione alle tecnologie innovative, efficienti e sostenibili, per finalità di completezza e di confronto SIMTE comprende anche le tecnologie energetiche tradizionali.<br/>
                            Per esplorare le tecnologie vai alla pagina:<br/>
                            <a className="nav-link text-center" href="http://simte.enea.it/tecnologie.php?idSettore=3" target="_blank" rel="noreferrer"><span class="btn btn-primary text-white">Tecnologie Energetiche</span></a>

                            </p>
                            </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Tecnologie;