import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import condomini from "../assets/img/condomini.png";
import "../App.css";
import { Link } from 'react-router-dom';


function Condomini() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="/software">Software</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Condomini+4.0 
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Condomini+4.0 </h1>
                            <div className="row">
                                <p className="col-md-8">
                                CONDOMINI+ 4.0 è un’applicazione per smartphone e tablet dall’ENEA per supportare i tecnici e i responsabili per l’analisi della vulnerabilità energetica-strutturale degli edifici condominiali. Essa consente di misurare in modo semplice i consumi energetici e le caratteristiche strutturali degli edifici condominiali per una prima valutazione degli interventi di messa in sicurezza, riqualificazione ed efficientamento.<br/>
                                 Per garantire la massima affidabilità e sicurezza, l’utilizzo dell’app è consentito esclusivamente a tecnici abilitati (periti, geometri, architetti ed ingegneri) che operano nel settore dell’edilizia con particolare specializzazione sugli aspetti strutturali ed impiantistici. <br/>
                                 E’ stato inoltre previsto che in assenza di dati o di informazioni specialistiche non sia possibile ottenere risultati. <br/>
                                Per approfondimenti clicca qui. Condomini+ 4.0 è gratuitamente scaricabile da <a href="https://apps.apple.com/it/app/condomini-4-0/id1441285205" target={"_blank"} rel="noreferrer">Apple store</a>.<br/>
                                L’impiego dell’applicatvio non sostituisce la conduzione della Diagnosi Energetica.
                                </p>
                                <img className="col-md-4 mw-300 h-100" src={condomini} title="condomini" alt="condomini" />
                            </div>
                           
                         </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Condomini;