import React, { Component } from 'react';
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import './components/globals';
import 'bootstrap-italia';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/home';
import Progetto from './pages/progetto';
import Attivita from './pages/attivita';
import Partner from './pages/partner';
import Pnrr from './pages/pnrr';
import Normative from './pages/normative';
import Formazione_professionale from './pages/formazione_professionale';
import Kcom from './pages/kcom';
import Elearn from './pages/elearn';
import Glossario from './pages/glossario';
import Servizi from './pages/servizi';
import Software from './pages/software';
import Tecnologie from './pages/tecnologie';
import Statistiche from './pages/statistiche';
import StatisticheNew from './pages/statisticheNew';
import MieiImmobili from './pages/mieiimmobili';
import Passaporto from './pages/passaporto';
import Cassetto from './pages/cassetto';
import Banchedati from './pages/banche_dati';
import Pubblicazioni from './pages/pubblicazioni';
import Ape from './pages/ape';
import Detrazionifiscali from './pages/detrazioni_fiscali';
import Diagnosienergetiche from './pages/diagnosi_energetiche';
import Contotermico from './pages/contotermico';
import Certificatibianchi from './pages/certificati_bianchi';
import Fondonazionale from './pages/fondo_nazionale';
import Prepac from './pages/prepac';
import Notizie from './pages/notizie';
import Eventi from './pages/eventi';
import Contatti from './pages/contatti';
import Accessibilita from './pages/accessibilita';
import Sire from './pages/sire';
import Safeschool from './pages/safeschool';
import Abimviewer from './pages/abimviewer';
import Condomini from './pages/condomini';
import Docet from './pages/docet';
import Test4ecasa from './pages/test4ecasa';
import Shadowindow from './pages/shadowindow';
import Victoria from './pages/victoria';
import Odesse from './pages/odesse';
import Harp from './pages/harp';
import Nzeb from './pages/nzeb';
import EdCaratteristiche from './pages/ed_caratteristiche';
import EdTipologie from './pages/ed_tipologie';
import Epc from './pages/epc';
import Mappasito from './pages/mappasito';
import Error from './pages/error';
import './App.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-YLPQW7TFPV');

class App extends Component {

render() {
    return (

       <BrowserRouter>
            <Switch>
             <Route path="/" component={Home} exact/>
             <Route path="/progetto" component={Progetto}/>
             <Route path="/servizi" component={Servizi}/>
             <Route path="/attivita" component={Attivita}/>
             <Route path="/partner" component={Partner}/>
             <Route path="/pnrr" component={Pnrr}/>
             <Route path="/normative" component={Normative}/>
             <Route path="/formazione_professionale" component={Formazione_professionale}/>
             <Route path="/kcom" component={Kcom}/>
             <Route path="/elearn" component={Elearn}/>
             <Route path="/glossario" component={Glossario}/>
             <Route path="/servizi" component={Servizi}/>
             <Route path="/software" component={Software}/>
             <Route path="/tecnologie" component={Tecnologie}/>
             <Route path="/statistiche" component={Statistiche}/>
             <Route path="/statisticheNew" component={StatisticheNew}/>
             <Route path="/banche_dati" component={Banchedati}/>
             <Route path="/pubblicazioni" component={Pubblicazioni}/>
             <Route path="/ape" component={Ape}/>
             <Route path="/detrazioni_fiscali" component={Detrazionifiscali}/>
             <Route path="/diagnosi_energetiche" component={Diagnosienergetiche}/>
             <Route path="/conto_termico" component={Contotermico}/>
             <Route path="/certificati_bianchi" component={Certificatibianchi}/>
             <Route path="/fondo_nazionale" component={Fondonazionale}/>
             <Route path="/prepac" component={Prepac}/>
             <Route path="/notizie" component={Notizie}/>
             <Route path="/eventi" component={Eventi}/>
             <Route path="/contatti" component={Contatti}/>
             <Route path="/accessibilita" component={Accessibilita}/>
             <Route path="/sire" component={Sire}/>
             <Route path="/safeschool" component={Safeschool}/>
             <Route path="/abimviewer" component={Abimviewer}/>
             <Route path="/condomini" component={Condomini}/>
             <Route path="/docet" component={Docet}/>
             <Route path="/test4ecasa" component={Test4ecasa}/>
             <Route path="/shadowindow" component={Shadowindow}/>
             <Route path="/victoria" component={Victoria}/>
             <Route path="/odesse" component={Odesse}/>
             <Route path="/harp" component={Harp}/>
             <Route path="/nzeb" component={Nzeb}/>
             <Route path="/ed_caratteristiche" component={EdCaratteristiche}/>
             <Route path="/ed_tipologie" component={EdTipologie}/>
             <Route path="/nzeb" component={Nzeb}/>
             <Route path="/cassetto/:id" component={Cassetto}/>
             <Route path="/mieiimmobili" component={MieiImmobili}/>
             <Route path="/passaporto" component={Passaporto}/>
             <Route path="/epc" component={Epc}/>
             <Route path="/mappasito" component={Mappasito}/>
             <Route component={Error}/>
           </Switch>
      </BrowserRouter>

    );
  }
}

export default App;
