import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import safeschool from "../assets/img/safeschool.png";
import "../App.css";
import { Link } from 'react-router-dom';


function Safeschool() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="/software">Software</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Safe School
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                            <h1>Safe School</h1>
                                <div className="row">
                                     <p className="col-md-8">SAFE SCHOOL 4.0 è un’applicativo che consente di misurare in modo semplice i consumi energetici e le caratteristiche strutturali delle scuole per una prima valutazione degli interventi di messa in sicurezza, riqualificazione ed efficientamento. Per garantire la massima affidabilità e sicurezza, l’utilizzo della App è consentito esclusivamente a tecnici abilitati (periti, geometri, architetti ed ingegneri) che operano nel settore dell’edilizia scolastica con particolare specializzazione sugli aspetti strutturali ed impiantistici. Per approfondimenti clicca <a href="http://italiainclassea.enea.it/safe-school-4-0-app/" target={"_blank"} rel="noreferrer">qui</a>. <br/>
                                    Safe School 4.0 è  gratuitamente scaricabile da <a href="https://play.google.com/store/apps/details?id=it.enea.safeschool40&amp;hl=it" target={"_blank"} rel="noreferrer">Google play</a>  e  <a href="https://apps.apple.com/it/app/safeschool-4-0/id1342169959" target={"_blank"} rel="noreferrer">Apple store</a>.<br/>
                                    L’impiego dell’applicatvio non sostituisce la conduzione della Diagnosi Energetica.</p>
                                    <img className="col-md-4 mw-300 h-100" src={safeschool} title="safeschool" alt="safeschool" />
                                 </div>
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Safeschool;