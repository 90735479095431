import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../scss/bootstrap-italia-custom.scss";
import "../App.css";
import { Link } from 'react-router-dom';
import mappasitoimg from '../assets/img/mappasitoimg.jpg';

function Mappasito() {
  return (
    <div>
    <HeaderTop />
          <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Mappa del sito
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="container">
                        <div>
                            <h1>Mappa del sito</h1>
                            <p className="mt-4 text-center">
                                <img className="m-auto w-100"
                                    src={mappasitoimg}
                                    alt='Mappa del sito'
                                    title='Mappa del sito'
                                />
                            </p>
                            </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Mappasito;