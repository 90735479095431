import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import shadowindow from "../assets/img/shadowindow.png";
import "../App.css";
import { Link } from 'react-router-dom';


function Shadowindow() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                               <Link to="/software">Software</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                ShadoWindow
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>ShadoWindow</h1>
                            <div className="row">
                                <p className="col-md-8">
                                ShadoWindow è un software gratuito che consente di eseguire in modo facile e veloce il calcolo del risparmio annuo di energia che si ottiene con l’installazione di una schermatura solare a protezione di una superficie vetrata. Basato su una metodologia coerente con le normative tecniche di riferimento, ShadoWindow è stato ideato per supportare gli utenti che devono inviare a ENEA i dati del risparmio energetico ottenuto con l’installazione di una schermatura solare, per poter usufruire delle detrazioni fiscali per la riqualificazione energetica degli edifici (ECOBONUS).<br/> 
                                Vista la semplicità di utilizzo, ShadoWindow può essere utilizzato anche per confrontare le diverse soluzioni disponibili sul mercato, facilitando così la scelta della soluzione più efficace da adottare. Il software, corredato da video tutorial, è disponibile al seguente <a href="https://strumenti-detrazionifiscali.enea.it/" target={"_blank"} rel="noreferrer">link</a>. <br/> 
                                Per scaricare l'applicativo previa registrazione clicca <a href="https://www.shadowindow.it" target={"_blank"} rel="noreferrer">qui</a> 
                                </p>
                                <img className="col-md-4 mw-300 h-100" src={shadowindow} title="shadowindow" alt="shadowindow" />
                            </div>
                           
                         </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Shadowindow;