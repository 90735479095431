import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../scss/bootstrap-italia-custom.scss";
import "../App.css";
import { Link } from 'react-router-dom';
import progetto from '../assets/img/progetto.png';

function Progetto() {
  return (
    <div>
    <HeaderTop />
         <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Progetto
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Progetto</h1>
                            <p className="mt-4">
                            <img src={progetto} className="float-right ml-2 mw-300 border" alt="progetto"/> L’aggiornamento del <b>Decreto Legislativo 19 agosto 2005, n. 192 (di seguito Dlgs 192/05) con il Decreto Legislativo 10 giugno 2020, n. 48, che attua la Direttiva Europea 2018/844/UE</b>, introduce ulteriori indicazioni per la promozione del miglioramento della prestazione energetica degli edifici, in considerazione delle condizioni locali e climatiche esterne, nonché delle prescrizioni relative al clima degli ambienti interni e all'efficacia sotto il profilo dei costi delle azioni previste, ottimizzando il rapporto tra oneri e benefici per la collettività. Tale Decreto aggiornato, all’articolo 4-quater, introduce il Portale Nazionale sulla prestazione energetica degli edifici.<br/><br/>

                            Il Progetto di sviluppo del Portale è stato elaborato da un gruppo di lavoro del Dipartimento Unità per l’Efficienza Energetica (DUEE) di ENEA e riguarda la realizzazione di una piattaforma informatica, detta <b>Portale Nazionale sulla Prestazione Energetica degli Edifici (PnPE<sup>2</sup>, https://pnpe2.enea.it)</b>, basata su una serie di moduli funzionali, molteplici database distribuiti e un ambiente centralizzato ENEA. <br/><br/>
                            Partendo da una prima versione in cui i servizi sono limitati ai dati in possesso di ENEA, lo sviluppo della piattaforma crescerà in base alla disponibilità dei dati che verranno messi via via a disposizione dagli altri attori coinvolti nel progetto. Il Portale permette la ricerca, la lettura e l’elaborazione dei dati con varie modalità e strumenti i cui risultati sono consultabili dalle classi di utenza previste attraverso un’interfaccia web avanzata ad elevato grado di usabilità.<br/><br/>
                            La proposta si basa su un’accurata analisi delle fonti dati, dei servizi e delle classi dell’utenza. La metodologia utilizzata ha consentito di identificare le funzionalità di cui il Portale sarà dotato in relazione alle richieste dell’utenza finale.<br/>
                            La metodologia utilizzata consentirà di identificare le funzionalità di cui il Portale sarà dotato in relazione alle richieste dell’utenza finale.<br/><br/>
                            Le funzionalità proposte dal Portale sono implementate su un’architettura modulare che consente di fruire di funzioni di interrogazione e di gestione dati con specifiche elaborazioni basate su algoritmi complessi. I risultati intermedi e finali di tali elaborazioni saranno integrati con modelli di supporto alle decisioni e infine, resi accessibili all’utente attraverso una moderna interfaccia con funzionalità GIS consultabile tramite il Web. <br/><br/>
                            Il punto chiave per ENEA nella realizzazione del PnPE<sup>2</sup> è l’interconnessione con i database richiesti nella normativa, ai quali si affiancano le diverse basi dati già sviluppate internamente e per questo facilmente integrabili nella programmazione del portale. In fase di applicazione delle potenzialità del portale, infatti, sono già integrabili i dati presenti nei siti regionali di attestati delle prestazioni energetiche (APE-R), i siti regionali di catasto impianti termici (CIT-R), i portali per le politiche di sviluppo territoriale (ESPA-PAES), i portali per le diagnosi energetiche delle imprese (AUDIT 102), solo per citarne alcuni gestiti da ENEA. 
                            </p>
                            </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Progetto;