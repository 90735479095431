import React, { useEffect, useState } from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import ListaImmobili from "../components/ListaImmobili.js";
import "../App.css";
import { Link } from 'react-router-dom';
import { CardBody, CardTitle, CardText,  Card,  Row, Col, Input } from 'design-react-kit';
import axios from "axios";

function Software() {
  let store=JSON.parse(localStorage.getItem("utente"))??''

  const [data, setData] = useState([]);
  const [storeUser, setStoreUser] = useState(false);
//  const [login, setLogin] = useState(false);
  const [codicefiscale, setCodicefiscale] = useState("");
  const [password, setPassword] = useState("");

  //const [loading, setLoading] = useState(true);

  const accesso = (event) => {
    event.preventDefault();
    if ((typeof password === 'string' && password.trim().length === 0) || (typeof codicefiscale === 'string' && codicefiscale.trim().length === 0)) {
      alert('Codice fiscale o Password errate')

    }else if(codicefiscale === 'PNNLSN73L29H501J'){

      let now = new Date().getTime();
      localStorage.setItem('setupTime', now);

      localStorage.setItem("utente", true);

      setStoreUser(true)
    }else{
      alert('Codice fiscale o Password errate')

    }


  }



  const setDati = (values) =>{
    let arr=[]
    const tempArr=[]
      const listItems=values.map((value,key) =>{
        let chiave=value.foglio+'-'+value.particella+'-'+value.subalterno
        if(!(arr.indexOf(chiave) > -1)){
          arr.push(chiave)
          tempArr.push(value)

        }
        // tempArr[value.foglio+''+value.sezione+''+value.particella+''+value.subalterno]=value
        // return (
        // {listItems}
        // );

      })



    console.log(tempArr)

    //setTemp(tempArray)
    setData(tempArr)
  }
  //
  //



    useEffect(() => {
      let hours = 20; // to clear the localStorage after 1 hour
                     // (if someone want to clear after 8hrs simply change hours=8)
      let now = new Date().getTime();
      let setupTime = localStorage.getItem('setupTime');
      if (setupTime !== null) {
          if(now-setupTime > hours*60*1000) {
              localStorage.clear()
          }
      }


      if(store){
        setStoreUser(true)
        //setLoading(true);
        axios.get(
          "//pnpe2.enea.it/api/data/pnpe2_ape_ecobonus?codicefiscale=eq.XXXXXXXXXXXXXXX"
          //"//pnpe2.enea.it/api/data/pnpe2_ape_ecobonus?codicefiscale=eq.FRLFNC63P20F052X"
        )
        .then((response)=>{
          setDati(response.data)
            //setDati({'datiJson':response.data,'idRegioneSel':0,'annoSel':0})
        })
        .finally(()=>{
        //    setLoading(false);
          }
        )
        .catch((e)=>{
          //console.log(e)
        })

      }else{
        setStoreUser(false)
      }





    }, [store]);




  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                I miei immobili
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>

                <section id="intro" className="container px-4 my-4">
                  <div>
                    {
                      (storeUser) ? (
                        <>
                        <h1>I miei immobili (Alessandro Pannicelli)</h1>
                          <Row  className="mb-3">
                            <Col>
                              <div className='card-wrapper '>

                                <Card teaser noWrapper className='rounded shadow'>
                                  <CardBody>
                                    <CardTitle className='d-none'>
                                    Filtri ricerca
                                    </CardTitle>
                                    <CardText className="mt-5">
                                      <ListaImmobili dati={data}/>
                                    </CardText>
                                  </CardBody>
                                </Card>
                              </div>

                            </Col>
                          </Row>
                          </>
                        )
                        : (
                          <>
                          <h1>Autenticazione utente</h1>
                            <Row  className="mb-3">
                              <Col>
                                <div className='card-wrapper '>

                                  <Card teaser noWrapper className='rounded shadow'>
                                    <CardBody>
                                    <CardTitle>
                                    Per accedere inserire Codice fiscale e Password
                                    </CardTitle>
                                      <CardText className="mt-5">
                                      <div className="Auth-form-container">
                                            <form className="Auth-form" onSubmit={accesso}>
                                              <div className="Auth-form-content">

                                                  <Input
                                                    type='text'
                                                    label='Codice fiscale'
                                                    id='exampleInputText'

                                                    onChange={(e) => setCodicefiscale(e.target.value)}
                                                  />
                                                  <Input
                                                      type='password'
                                                      id='exampleInputPassword'
                                                      label='Password'
                                                      onChange={(e) => setPassword(e.target.value)}

                                                    />
                                                <div className="d-grid gap-2 mt-3">
                                                  <button type="submit" className="btn btn-primary">
                                                    Accedi
                                                  </button>
                                                </div>

                                              </div>
                                            </form>
                                          </div>
                                        </CardText>
                                      </CardBody>
                                    </Card>
                                  </div>

                                </Col>
                              </Row>
                              </>
                        )
                    }
                  </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Software;
