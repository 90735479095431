import React from 'react';
// import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

import { Line,Bar,Pie  } from 'react-chartjs-2';


const BarChartoptionsLog = {
  scales: {
    x: {
      display: false,
    },
    y: {
      display: true,
      type: 'logarithmic',
    }
  }
};
const BarChartoptions = {
  scale: {
    ticks: { beginAtZero: true },

  },
};
const options = {
  scale: {
    ticks: { beginAtZero: true },
    title:'prova',
  },
};
const LineChart = (props) => (
  <>
    {props.titolo!=='' && <h6>{props.titolo}</h6>}
    <Line  data={props.data} options={options} />
  </>
);
const BarChart = (props) => (
  <>
    {props.titolo!=='' && <h6>{props.titolo}</h6>}
    <Bar  data={props.data} options={BarChartoptions}/>
  </>
);
const BarChartLog = (props) => (
  <>
    {props.titolo!=='' && <h6>{props.titolo}</h6>}
    <Bar  data={props.data} options={BarChartoptionsLog}/>
  </>
);
const PieChart = (props) => (
  <>
    {props.titolo!=='' && <h6>{props.titolo}</h6>}
    <Pie  data={props.data} options={options} />
  </>
);
function GetChart(props) {
  if(props.tipo==='line')
    return <LineChart data={props.data} titolo={props.titolo}/>
  if(props.tipo==='bar')
    return <BarChart data={props.data} titolo={props.titolo}/>
  if(props.tipo==='barlog')
    return <BarChartLog data={props.data} titolo={props.titolo}/>
  if(props.tipo==='pie')
    return <PieChart data={props.data} titolo={props.titolo}/>
}



export default GetChart;
