import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import elearn from "../assets/img/elearn.png";
import { Link } from 'react-router-dom';
import { Button} from 'design-react-kit';

function Elearn() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="/formazione_professionale">Formazione</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                ENEA e-Learn
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Piattaforma di e-Learning ENEA </h1>
                            <div className="row">
                                <p className="col-md-8">
                                La piattaforma ENEA E-learn, nella sezione "Efficienza Energetica", offre un vasta scelta di corsi e risorse suddivise per settori : edilizia residenziale, per la pubblica amministrazione, nei distretti industriali e nei sistemi produttivi. <br/>
                                Ogni settore ha problemi specifici di efficientamento e può acquisire maggior valore, non solo a livello economico risparmiando sul combustibile, ma anche grazie alla qualità dell’innovazione tecnologica e al minor impatto ambientale.<br/> 
                                Più in generale l’efficienza contribuisce alla maggior sostenibilità di tutto il sistema energetico del Paese e non ultimo, al benessere dei cittadini.
                                </p>
                                <img className="col-md-4 mw-300 h-100" src={elearn} title="elearn" alt="elearn" />
                            </div>
                           
                         </div>
                        
                    </div>
                </section>
                <div className="text-center">
               <a href="http://www.formazione.enea.it" target="_blank" rel="noreferrer"><Button className="m-auto" color='primary'>Accedi</Button></a>
                </div>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Elearn;