import React, { useEffect, useState } from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import sprite2 from "../assets/svg/sprite.svg";
import sprite from "../assets/svg/ponmetroca.svg";
import { Fire,Moisture, TagsFill, BuildingExclamation, HouseFill } from 'react-bootstrap-icons';
import "../App.css";
import epgl from "../assets/img/epgl.png";
import { Link } from 'react-router-dom';
import { CardBody, CardTitle, CardText,  Card,  Row, Col, Input, LinkList, LinkListItem } from 'design-react-kit';
import axios from "axios";

function Passaporto() {
  let store=JSON.parse(localStorage.getItem("utente"))??''

  const [data, setData] = useState([]);
  const [storeUser, setStoreUser] = useState(false);
//  const [login, setLogin] = useState(false);
  const [codicefiscale, setCodicefiscale] = useState("");
  const [password, setPassword] = useState("");

  //const [loading, setLoading] = useState(true);

  const accesso = (event) => {
    event.preventDefault();
    if ((typeof password === 'string' && password.trim().length === 0) || (typeof codicefiscale === 'string' && codicefiscale.trim().length === 0)) {
      alert('Codice fiscale o Password errate')

    }else if(codicefiscale === 'PNNLSN73L29H501J'){

      let now = new Date().getTime();
      localStorage.setItem('setupTime', now);

      localStorage.setItem("utente", true);

      setStoreUser(true)
    }else{
      alert('Codice fiscale o Password errate')

    }

  }

  const setDati = (values) =>{
    let arr=[]
    const tempArr=[]
      const listItems=values.map((value,key) =>{
        let chiave=value.foglio+'-'+value.particella+'-'+value.subalterno
        if(!(arr.indexOf(chiave) > -1)){
          arr.push(chiave)
          tempArr.push(value)

        }
        // tempArr[value.foglio+''+value.sezione+''+value.particella+''+value.subalterno]=value
        // return (
        // {listItems}
        // );

      })



    console.log(tempArr)

    //setTemp(tempArray)
    setData(tempArr)
  }
  //
  //



    useEffect(() => {
      let hours = 20; // to clear the localStorage after 1 hour
                     // (if someone want to clear after 8hrs simply change hours=8)
      let now = new Date().getTime();
      let setupTime = localStorage.getItem('setupTime');
      if (setupTime !== null) {
          if(now-setupTime > hours*60*1000) {
              localStorage.clear()
          }
      }


      if(store){
        setStoreUser(true)
        //setLoading(true);
        axios.get(
          "//pnpe2.enea.it/api/data/pnpe2_ape_ecobonus?codicefiscale=eq.XXXXXXXXXXXXXXX"
          //"//pnpe2.enea.it/api/data/pnpe2_ape_ecobonus?codicefiscale=eq.FRLFNC63P20F052X"
        )
        .then((response)=>{
          setDati(response.data)
            //setDati({'datiJson':response.data,'idRegioneSel':0,'annoSel':0})
        })
        .finally(()=>{
        //    setLoading(false);
          }
        )
        .catch((e)=>{
          //console.log(e)
        })

      }else{
        setStoreUser(false)
      }





    }, [store]);




  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="/mieiimmobili">I miei immobili</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Passaporto dell'immobile
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>

                <section id="intro" className="container px-4 my-4">
                    <div>
                    {
                      (storeUser) ? (
                        <>
                        <div className="row">
                        <div>
                            <h1>Passaporto dell'immobile</h1>
                            <div className="row">
                                <div className="col-md-2">
                               
                                        <span class="btn btn-primary btn-xs text-white p-2">
                                            <svg className="icon icon-white icon-sm mr-1">
                                                <use href={ sprite + "#ca-home" }></use>
                                            </svg>
                                            <u><Link className="text-white" to="/mieiimmobili">I MIEI IMMOBLI</Link></u>
                                        </span>
                                </div>
                                <div className="col-md-9 text-justify">
                                Il Passaporto dell'immobile  è il servizio del Portale riservato ai privati cittadini e alla PA che consente la visualizzazione del profilo energetico-catastatale delle unità immobiliari di proprietà dell'utente. </div>
                                
                            </div>
                            <div className="row border-top row-column-border row-column-menu-left">
                                <aside className="col-lg-2">
                                    <div className="sticky-wrapper navbar-wrapper">
                                    <nav
                                        className="navbar it-navscroll-wrapper it-top-navscroll navbar-expand-lg"
                                    >
                                        <button
                                        className="custom-navbar-toggler"
                                        type="button"
                                        aria-controls="navbarNav"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                        data-target="#navbarNav"
                                        >
                                        <span className="it-list"></span>Sezioni
                                        </button>
                                        <div className="navbar-collapsable" id="navbarNav">
                                        <div className="overlay"></div>
                                        <div className="close-div sr-only">
                                            <button className="btn close-menu" type="button">
                                            <span className="it-close"></span>Chiudi
                                            </button>
                                        </div>
                                        <a className="it-back-button" href="/#">
                                            <svg className="icon icon-sm icon-primary align-top">
                                            <use xlinkhref= { sprite2 + "#it-chevron-left" }></use>
                                            </svg>
                                            <span>Torna indietro</span></a
                                        >
                                        <div className="menu-wrapper">
                                            <div className="link-list-wrapper menu-link-list">
                                            <h3 className="no_toc">Sezioni</h3>
                                            <ul className="link-list">
                                                <li className="nav-item">
                                                <a className="nav-link" href="#descrizione"
                                                    ><span>Unità immobiliare</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="#normative"
                                                    ><span>Energia</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="https://www.gse.it/servizi-per-te/efficienza-energetica/conto-termico/modulistica" target="_blank" rel="noreferrer"
                                                    ><span>Impianti</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="https://www.gse.it/servizi-per-te/efficienza-energetica/conto-termico/modulistica" target="_blank" rel="noreferrer"
                                                    ><span>Bonus Fiscali</span></a
                                                >
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </nav>
                                    </div>
                                </aside>
                                <section className="col-lg-10 it-page-sections-container">
                                    <article id="descrizione" className="it-page-section anchor-offset">
                                    <h4>Descrizione</h4>
                                    <p className="text-serif text-justify">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In blandit iaculis gravida. Nunc condimentum ornare lorem hendrerit tincidunt. Interdum et malesuada fames ac ante ipsum primis in faucibus. In nec justo ut massa pretium faucibus. Pellentesque consequat ipsum a ex fringilla iaculis. Suspendisse venenatis varius erat, posuere faucibus justo tristique ut. Ut sed tincidunt eros. Sed maximus at ipsum a convallis. Curabitur commodo vehicula varius.
                                    </p>
                                    </article>
                                    <hr></hr>
                                    <article id="normative" className="it-page-section anchor-offset mt-5">
                                        <div className="">
                                            <div
                                                className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal card-teaser-block-1"
                                            > 
                                              <div className="row">
                                                <div className="col-md-6">
                                                  <div className="card card-teaser card-bg-light rounded text-dark">
                                                    <div className="avatar size-lg mr-3">
                                                    <HouseFill />
                                                    </div>
                                                    <div className="card-body">
                                                        <h5 className="card-title pb-3">
                                                        <Link to="/sire" className="text-dark">UNITA' IMMOBILIARE</Link>
                                                        </h5>
                                                        <p className="card-text text-sans-serif text-dark">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In blandit iaculis gravida. Nunc condimentum ornare lorem hendrerit tincidunt. Interdum et malesuada fames ac ante ipsum primis in faucibus. In nec justo ut massa 
                                                        </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="card card-flex rounded text-dark">
                                                    
                                                    <div className="card-body p-0">
                                                       <img className="col-md-4 mw-300 h-100 pl-0" src={epgl} title="epgl" alt="epgl" />
                                                    </div>
                                                    <div className=" ">
                                                        <div className="bg-success">
                                                          <div class="form-check">
                                                            <input className="bg-white" id="nzeb" type="checkbox" checked="checked"></input>
                                                            <label for="nzeb" className="text-white nzeb">EDIFICIO AD ENERGIA QUASI ZERO</label>
                                                          </div>
                                                        </div>
                                                      <div className="bg-warning text-white p-2"><span><small>CLASSE ENERGERICA</small></span><span className="ml-3 font-weight-bold">E</span></div>
                                                      <div className="bg-light text-success p-2 text-center"><div className="font-weight-bold">EPgl,nren</div><div className="font-weight-bold">263.8</div><div className="font-weight-bold"><small>Kwh/m<sup>2</sup> anno</small></div></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                                className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal card-teaser-block-2"
                                            >
                                                <div className="card card-teaser card-bg-light rounded text-dark">
                                                <div className="avatar size-lg mr-3">
                                                <Fire />
                                                </div>
                                                <div className="card-body">
                                                    <h5 className="card-title pb-3">
                                                    <Link to="/sire" className="text-dark">ENERGIA</Link>
                                                    </h5>
                                                    <p className="card-text text-sans-serif text-dark">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In blandit iaculis gravida. Nunc condimentum ornare lorem hendrerit tincidunt. Interdum et malesuada fames ac ante ipsum primis in faucibus. In nec justo ut massa 
                                                    </p>
                                                </div>
                                                </div>
                                                <div className="card card-teaser card-bg-light rounded">
                                                <div className="avatar size-lg mr-3">
                                                <Moisture />
                                                </div>
                                                <div className="card-body">
                                                    <h5 className="card-title pb-3">
                                                    <Link to="/victoria" className="text-dark">IMPIANTI</Link>                   
                                                    </h5>
                                                    <p className="card-text text-sans-serif text-dark">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In blandit iaculis gravida. Nunc condimentum ornare lorem hendrerit tincidunt. Interdum et malesuada fames ac ante ipsum primis in faucibus. In nec justo ut massa 
                                                    </p>
                                                </div>
                                                </div>
                                                <div className="card card-teaser card-bg-light rounded">
                                                <div className="avatar size-lg mr-3">
                                                    <TagsFill />
                                                </div>
                                                <div className="card-body">
                                                    <h5 className="card-title pb-3">
                                                    <Link to="/docet" className="text-dark">BONUS FISCALI</Link>       
                                                    </h5>
                                                    <p className="card-text text-sans-serif text-dark">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In blandit iaculis gravida. Nunc condimentum ornare lorem hendrerit tincidunt. Interdum et malesuada fames ac ante ipsum primis in faucibus. In nec justo ut massa 
                                                    </p>
                                                </div>
                                                </div>
                                                <div className="card card-teaser card-bg-light rounded">
                                                <div className="avatar size-lg mr-3">

                                                <BuildingExclamation />

                                                </div>
                                                <div className="card-body">
                                                    <h5 className="card-title pb-3">
                                                    <Link to="/safeschool" className="text-dark">RIQUALIFICAZIONE IMMOBILE</Link>       
                                                    </h5>
                                                    <p className="card-text text-sans-serif text-dark">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In blandit iaculis gravida. Nunc condimentum ornare lorem hendrerit tincidunt. Interdum et malesuada fames ac ante ipsum primis in faucibus. In nec justo ut massa 
                                                    </p>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </section>
                            </div>
                        </div>
                    </div>
                          </>
                        )
                        : (
                          <>
                          <h1>Autenticazione utente</h1>
                            <Row  className="mb-3">
                              <Col>
                                <div className='card-wrapper '>

                                  <Card teaser noWrapper className='rounded shadow'>
                                    <CardBody>
                                    <CardTitle>
                                    Per accedere inserire Codice fiscale e Password
                                    </CardTitle>
                                      <CardText className="mt-5">
                                      <div className="Auth-form-container">
                                            <form className="Auth-form" onSubmit={accesso}>
                                              <div className="Auth-form-content">

                                                  <Input
                                                    type='text'
                                                    label='Codice fiscale'
                                                    id='exampleInputText'

                                                    onChange={(e) => setCodicefiscale(e.target.value)}
                                                  />
                                                  <Input
                                                      type='password'
                                                      id='exampleInputPassword'
                                                      label='Password'
                                                      onChange={(e) => setPassword(e.target.value)}

                                                    />
                                                <div className="d-grid gap-2 mt-3">
                                                  <button type="submit" className="btn btn-primary">
                                                    Accedi
                                                  </button>
                                                </div>

                                              </div>
                                            </form>
                                          </div>
                                        </CardText>
                                      </CardBody>
                                    </Card>
                                  </div>

                                </Col>
                              </Row>
                              </>
                        )
                    }
                  </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Passaporto;
