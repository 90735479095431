import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../scss/bootstrap-italia-custom.scss";
import "../App.css";
import { Link } from 'react-router-dom';
import edificio1 from '../assets/img/edificio1.png';
import edificio2 from '../assets/img/edificio2.png';
import edificio3 from '../assets/img/edificio3.png';


function EdCaratteristiche() {
  return (
    <div>
    <HeaderTop />
         <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Caratteristiche degli Edifici
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Caratteristiche del sistema edificio-impianto</h1>
                            <p className="mt-4">
                            Per sistema edificio-impianto si intende un sistema complesso, nel quale si sommano tutti gli effetti fisici determinati dall’interazione dello stesso con l’ambiente esterno mediante flussi di materia ed energia, caratterizzato da diverse parti interdipendenti tra di loro e le cui interazioni ed interconnessioni ne determinano le prestazioni energetiche. <br/>
                            È proprio la sinergia delle diverse competenze che ne determina la qualità e quindi la funzionalità operativa dell’intero manufatto.<br/>
                            <div className="text-center my-5">
                            <img className="m-auto w-100 mw-50"
                                src={edificio1}
                                alt='Edificio'
                                title='Edificio'
                            />
                            </div>
                            Il sistema edificio-impianto va quindi inteso come un sistema composto da due macro elementi: la parte “involucro” e la parte “impianto”.<br/>
                            L’<b>involucro</b> è la superficie di controllo che delimita il sistema termodinamico "edificio", ed ha la funzione di energia e massa e di mediare le condizioni climatiche esterne al fine di contribuire al raggiungimento delle condizioni di comfort negli ambienti confinati<br/>
                            L’<b>impianto</b> è la parte attiva del sistema ed ha l'obiettivo di raggiungere le condizioni di comfort termoigrometrico ottimale, limitare i consumi energetici e gli impatti sull'ambiente esterno.<br/>
                            <div className="text-center my-5">
                            <img className="m-auto w-100 mw-50"
                                src={edificio2}
                                alt='Edificio'
                                title='Edificio'
                            />
                            </div>
                            Entrambi devono lavorare in un processo integrato e complesso attraverso sistemi di controllo ed azioni correttive, che si attivano in base alle diverse condizioni climatiche, con l’obiettivo di massimizzarne la sinergia e la funzionalità operativa e non comprometterne le prestazioni energetiche del manufatto.<br/>
                            <div className="text-center my-5">
                            <img className="m-auto w-100 mw-50"
                                src={edificio3}
                                alt='Edificio'
                                title='Edificio'
                            />
                            </div>
                            Aspetti tecnologici, funzionali e spaziali dell’intero sistema edilizio ed impiantistico rappresentano gli elementi essenziali per la gestione integrata dei servizi energetici degli edifici con l’obiettivo di razionalizzare e ottimizzare prestazioni e consumi.<br/>
 
 
                            </p>
                            </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default EdCaratteristiche;