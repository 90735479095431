import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../scss/bootstrap-italia-custom.scss";
import "../App.css";
import { Link } from 'react-router-dom';
import per from "../assets/img/per.png";
import paes from "../assets/img/paes.png";
import simte from "../assets/img/simte.png";
import sprite2 from "../assets/svg/sprite.svg";



function Banchedati() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Banche dati
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Banche dati</h1>
                            <p className="mt-4">
                            In questa sezione è possibile accedere alle banche dati sviluppate da ENEA a supporto delle politiche di efficienza energetica e sostenibilità alla pubblica amministrazione.
                            </p>
                            <div className="row py-4">
                                <div className="col-xs-12 col-md-4">
                                    <div className="card-wrapper">
                                    <div className="card card-img no-after rounded shadow">
                                        <div className="img-responsive-wrapper">
                                        <div className="img-responsive img-responsive-panoramic">
                                            <figure className="img-wrapper">
                                            <img src={paes} title="paes" alt="paes" />
                                            </figure>
                                            
                                        </div>
                                        </div>
                                        <div className="card-body">
                                        
                                        <h4 className="card-title">
                                        PAES
                                        </h4>
                                        <p className="card-text">
                                        Piattaforma informatica a supporto delle Pubbliche Amministrazioni Locali per la redazione e il monitoraggio dei PAES ( Piani d’Azione per l’Energia Sostenibile) con simulazione delle buone pratiche e supporto alle azioni di mitigazione dell'impatto ambientale.
                                        </p>
                                        <div className="mt-5">
                                        <a className="read-more" href="https://www.paes.enea.it/" target="_blank" rel="noreferrer">
                                            <span className="text">Vai alla piattaforma</span>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-arrow-right" }></use>
                                            </svg>
                                        </a>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div> 
                                <div className="col-xs-12 col-md-4">
                                    <div className="card-wrapper">
                                    <div className="card card-img no-after rounded shadow">
                                        <div className="img-responsive-wrapper">
                                        <div className="img-responsive img-responsive-panoramic">
                                            <figure className="img-wrapper">
                                            <img src={per} title="per" alt="per" />
                                            </figure>
                                            
                                        </div>
                                        </div>
                                        <div className="card-body">
                                        
                                        <h4 className="card-title">
                                        P<sup>2</sup>ER
                                        </h4>
                                        <p className="card-text">
                                        Piattaforma informatica di supporto alle Regioni per la valutazione ed il monitoraggio dei Piani Energetici Regionali (PER) attraverso una vista regionale dei dati energetici, ambientali, demografici, socio-economici elaborati da ENEA ed altri enti pubblici.
                                        </p>
                                        <div className="d-none">
                                        <a className="read-more" href="/#" target="_blank" rel="noreferrer">
                                            <span className="text">Vai alla piattaforma</span>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-arrow-right" }></use>
                                            </svg>
                                        </a>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-4">
                                    <div className="card-wrapper">
                                    <div className="card card-img no-after rounded shadow">
                                        <div className="img-responsive-wrapper">
                                        <div className="img-responsive img-responsive-panoramic">
                                            <figure className="img-wrapper">
                                            <img src={simte} title="simte" alt="simte" />
                                            </figure>
                                            
                                        </div>
                                        </div>
                                        <div className="card-body">
                                        
                                        <h4 className="card-title">
                                        SIMTE BDN
                                        </h4>
                                        <p className="card-text">
                                        SIMTE-BDN è un applicativo dotato di uno strumento di ricerca avanzata che consente la consultazione smart, su base geografica,  delle principali normative relative al settore energetico di utilità per pubbliche amministrazioni e privati cittadini.  
                                        </p>
                                        <div className="d-none">
                                        <a className="read-more" href="/#" target="_blank" rel="noreferrer">
                                            <span className="text">Vai alla piattaforma</span>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-arrow-right" }></use>
                                            </svg>
                                        </a>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Banchedati;