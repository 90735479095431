import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import abimviewer from "../assets/img/abimviewer.png";
import "../App.css";
import { Link } from 'react-router-dom';


function Abimviewer() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <a href="/software">Software</a><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Abimviewer
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>ABIM Viewer</h1>
                            <div className="row">
                                <p className="col-md-8">

                                Il software A BIM Viewer, sviluppato da ENEA e Logical Soft nell’ambito della campagna “Italia in Classe A”, offre ai professionisti della filiera dell’edilizia uno strumento in grado di far dialogare il mondo del Building Information Modeling (BIM) con quello delle diagnosi energetiche degli edifici. Il software, integrando in qualsiasi file IFC (industry Foundation Classes) tutte le informazioni legate a una diagnosi energetica, ne permette il trasferimento dei risultati all’interno del modello BIM dell’edificio. A BIM Viewer è, quindi, anche un editor gratuito di modelli IFC che, attraverso un’interfaccia semplice ed intuitiva, permette la compilazione e la visualizzazione di parametri energetici (Pset) personalizzati, proposti da ENEA, per restituire i risultati di una diagnosi energetica.<br/>

                                Per approfondimenti clicca <a href="https://www.efficienzaenergetica.enea.it/vi-segnaliamo/arriva-a-bim-viewer-il-software-che-fa-dialogare-diagnosi-energetiche-degli-edifici-e-modello-bim.html" target={"_blank"} rel="noreferrer">qui</a> <br/>
                                Per scaricare il software vai alla seguente <a href="https://logicalsoft.s3.eu-west-1.amazonaws.com/operativi/abimviewer/Downloader_A_BIM_VIEWER.zip" target={"_blank"} rel="noreferrer">pagina</a> <br/>
                                </p>
                                <img className="col-md-4 mw-300 h-100" src={abimviewer} title="odesse" alt="odesse" />
                            </div>
                           
                         </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Abimviewer;