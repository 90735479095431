import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import { Link } from 'react-router-dom';
import axios from "axios";
import sprite2 from "../assets/svg/sprite.svg";
import { Row,Col } from 'design-react-kit';
import Map from "../components/Mappaimmobile.js";
import Ape from "../components/Apeimmobile.js";
import Bonus from "../components/Bonusimmobile.js";
import LibrettiImpianto from "../components/LibrettiImpiantoimmobile.js";


function Cassetto() {

  // const [data, setData] = useState([]);
//  const [loading, setLoading] = useState(true);
  const [datoCatastale, setDatoCatastale] = useState(true);
  const [datiImmobile, setDatiImmobile] = useState([]);
  const [apeCod, setApeCod] = useState([]);
  const [cpidCod, setCpidCod] = useState([]);
  const { id } = useParams();




  const getApeCpid = (values) =>{

    axios.get(
      "//pnpe2.enea.it/api/data/pnpe2_ape_ecobonus?foglio=eq."+values.foglio+"&particella=eq."+values.particella+"&subalterno=eq."+values.subalterno
    )
    .then((response)=>{
        let tempArrApe=[]
        let tempArrCpid=[]
        response.data.map((key, value) =>{
            tempArrApe.push(key.codide)
            if (!tempArrCpid.includes(key.cpid)) {
              tempArrCpid.push(key.cpid);
            }
            return []
          }
        )

        setApeCod(tempArrApe)
        console.log(tempArrCpid)
        setCpidCod(tempArrCpid)
    //  //pnpe2.enea.it/api/data/ape_validi_new?or=(codide.eq.5811127000089167,codide.eq.5811121000424179,codide.eq.5811121000376312)
        //setDati({'datiJson':response.data,'idRegioneSel':0,'annoSel':0})
    })
    .finally(()=>{
        // setLoading(false);
      }
    )
    .catch((e)=>{
      //console.log(e)
    })
  }
  //
  //



    useEffect(() => {

    //  setLoading(true);
      axios.get(
        "//pnpe2.enea.it/api/data/pnpe2_ape_ecobonus?id=eq."+id
      )
      .then((response)=>{
        let temp=response.data
        console.log(temp)
        setDatiImmobile({
            regione:temp[0].regione,
            provincia:temp[0].provincia,
            comune:temp[0].comune,
            annoc:temp[0].annoc,
            indirizzo:temp[0].indirizzo,
            civico:temp[0].civico,
            codcat:temp[0].codcat,
            foglio:temp[0].foglio,
            particella:temp[0].particella,
            subalterno:temp[0].subalterno
          })

        let daticatastali={codcat:temp[0].codcat,foglio:temp[0].foglio,particella:temp[0].particella,subalterno:temp[0].subalterno}
        getApeCpid(daticatastali)
        setDatoCatastale({codcat:temp[0].codcat,foglio:temp[0].foglio,particella:temp[0].particella,subalterno:temp[0].subalterno})
          //setDati({'datiJson':response.data,'idRegioneSel':0,'annoSel':0})
      })
      .finally(()=>{
        //  setLoading(false);
        }
      )
      .catch((e)=>{
        //console.log(e)
      })



    }, []);






  return (
    <div>
    <HeaderTop />
          <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="/mieiimmobili">I miei immobili</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Passaporto dell'immobile
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>

                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Passaporto dell'immobile</h1>
                            <div className="row border-top row-column-border row-column-menu-left">

                                <section className="col-lg-12 it-page-sections-container">
                                    <article id="anagraficaimmobile" className="it-page-section anchor-offset">

                                        <div className="card-wrapper card-teaser-wrapper">
                                            <div className="card  shadow mt-3 rounded">
                                            <div className="card-body">

                                                <h5 className="card-title">
                                                  <svg className="icon mr-2">
                                                    <use href={ sprite2 + "#it-camera" }></use>
                                                    </svg>
                                                 Anagrafica immobile
                                                </h5>
                                                <div className="card-text mt-2">

                                                    <Row  className="mb-3">
                                                      <Col xs='3'>
                                                        <Map className="position-relative" daticatastali={datoCatastale}/>

                                                      </Col>
                                                      <Col xs='5'>
                                                          <dl className='row'>
                                                            <dt className='col-sm-5'>Indirizzo</dt>
                                                            <dd className='col-sm-7'>
                                                              {datiImmobile.indirizzo??'-'}
                                                            </dd>
                                                            <dt className='col-sm-5'>Civico</dt>
                                                            <dd className='col-sm-7'>
                                                              {datiImmobile.civico??'-'}
                                                            </dd>
                                                            <dt className='col-sm-5'>Cod catastale comune</dt>
                                                            <dd className='col-sm-7'>
                                                              {datiImmobile.codcat??'-'}
                                                            </dd>
                                                            <dt className='col-sm-5'>Foglio</dt>
                                                            <dd className='col-sm-7'>
                                                              {datiImmobile.foglio??'-'}
                                                            </dd>
                                                            <dt className='col-sm-5'>Particella</dt>
                                                            <dd className='col-sm-7'>
                                                              {datiImmobile.particella??'-'}
                                                            </dd>
                                                            <dt className='col-sm-5'>Subalterno</dt>
                                                            <dd className='col-sm-7'>
                                                              {datiImmobile.subalterno??'-'}
                                                            </dd>
                                                          </dl>

                                                      </Col>
                                                      <Col xs='4'>
                                                          <dl className='row'>
                                                            <dt className='col-sm-5'>Regione</dt>
                                                            <dd className='col-sm-7'>
                                                              {datiImmobile.regione??'-'}
                                                            </dd>
                                                            <dt className='col-sm-5'>Provincia</dt>
                                                            <dd className='col-sm-7'>
                                                              {datiImmobile.provincia??'-'}
                                                            </dd>
                                                            <dt className='col-sm-5'>Comune</dt>
                                                            <dd className='col-sm-7'>
                                                              {datiImmobile.comune??'-'}
                                                            </dd>
                                                            <dt className='col-sm-5'>Anno costruzione</dt>
                                                            <dd className='col-sm-7'>
                                                              {datiImmobile.annoc??'-'}
                                                            </dd>

                                                          </dl>

                                                      </Col>
                                                    </Row>

                                                </div>
                                            </div>
                                          </div>
                                        </div>

                                    </article>
                                    <hr></hr>

                                    <article id="apeimmobile" className="it-page-section anchor-offset">

                                        <div className="card-wrapper card-teaser-wrapper">
                                            <div className="card  shadow mt-3 rounded">
                                            <div className="card-body">

                                                <h5 className="card-title">
                                                    <svg className="icon mr-2 ">
                                                      <use href={ sprite2 + "#it-bookmark" }></use>
                                                    </svg>
                                                 APE - Attestati Prestazione energetica
                                                </h5>
                                                <div className="card-text mt-2">
                                                  <Ape data={apeCod} datoCatastale={datoCatastale}/>


                                                </div>
                                            </div>
                                          </div>
                                        </div>

                                    </article>

                                    <article id="bonusdetrazioni" className="it-page-section anchor-offset">

                                        <div className="card-wrapper card-teaser-wrapper">
                                            <div className="card  shadow mt-3 rounded">
                                            <div className="card-body">

                                                <h5 className="card-title">
                                                    <svg className="icon mr-2">
                                                      <use href={ sprite2 + "#it-card" }></use>
                                                    </svg>
                                                 ECOBONUS
                                                </h5>
                                                <div className="card-text mt-2">

                                                  <Bonus data={cpidCod} datoCatastale={datoCatastale}/>


                                                </div>
                                            </div>
                                          </div>
                                        </div>

                                    </article>

                                    <article id="librettiimpianto" className="it-page-section anchor-offset">

                                        <div className="card-wrapper card-teaser-wrapper">
                                            <div className="card  shadow mt-3 rounded">
                                            <div className="card-body">

                                                <h5 className="card-title">
                                                      <svg className="icon mr-2">
                                                        <use href={ sprite2 + "#it-inbox" }></use>
                                                      </svg>
                                                   Libretti impianti termici
                                                </h5>
                                                <div className="card-text mt-2">
                                                  <LibrettiImpianto />



                                                </div>
                                            </div>
                                          </div>
                                        </div>

                                    </article>

                                </section>
                                </div>

                            </div>

                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Cassetto;
