import React, { Fragment,useEffect, useState } from "react";

import "../App.css";
//import sprite2 from "../assets/svg/sprite.svg";
// import { Link } from "react-router-dom";
import {Button,Modal,ModalBody,ModalFooter, LinkList, LinkListItem } from 'design-react-kit';
import axios from "axios";
import { Row,Col } from 'design-react-kit';
import sprite2 from "../assets/svg/sprite.svg";
// import Map from "./Mappaimmobile.js";
import inverno from "../assets/img/inverno.png";
import estate from "../assets/img/estate.png";
import smile1 from "../assets/img/smile1.png";
import smile2 from "../assets/img/smile2.png";
import smile3 from "../assets/img/smile3.png";


const backgroundColorApe= {
  'A4':'#11340d', //A4
  'A3':'#24711d',
  'A2':'#37ae2d',
  'A1':'#2bd51f',
  'B':'#bbdd31',
  'C':'#bbbb10',
  'D':'#f29133',
  'E':'#ed612c',
  'F':'#c43536',
  'G':'#ff0000', // G
};
  const ContentApe= (item,props) => {

    let smileestate=smile1
    let smileinverno=smile1
    let prestazioneestate='alta'
    let prestazioneinverno='alta'

        switch(item.estate) {
            case '0':
              smileestate=smile1
              prestazioneestate='alta'
              break;
            case '1':
              smileestate=smile3
              prestazioneestate='media'

              break;
            case '2':
              smileestate=smile2
              prestazioneestate='bassa'

              break;
            default:
              smileestate=smile1
              prestazioneestate='alta'

              break;
          }
        switch(item.inverno) {
          case '0':
            smileinverno=smile1
            prestazioneinverno='alta'

            break;
          case '1':
            smileinverno=smile3
            prestazioneinverno='media'

            break;
          case '2':
            smileinverno=smile2
            prestazioneinverno='bassa'

            break;
          default:
            smileinverno=smile1
            prestazioneinverno='alta'

            break;
          }


  //        useEffect(() => {



  //        }, [props.data]);



    return(
      <div>
        <article id="dettaglioape" className="it-page-section anchor-offset">

            <div className="card-wrapper card-teaser-wrapper">
                <div className="card  shadow mt-3 rounded">
                <div className="card-body">

                    <h5 className="card-title">
                      <svg className="icon mr-2">
                        <use href={ sprite2 + "#it-camera" }></use>
                        </svg>
                        CODICE APE: {item.codide} - VALIDO DA { (item.valda instanceof Date) ? item.valda.toLocaleDateString() : new Date(item.valda).toLocaleDateString() }
                    </h5>
                    <div className="card-text mt-2">

                        <Row  className="mb-3">
                          <Col xs='6'>
                              <dl className='row'>
                                <dt className='col-sm-5'>Regione</dt>
                                <dd className='col-sm-7'>
                                  {item.nome_reg??'-'}
                                </dd>
                                <dt className='col-sm-5'>Indirizzo</dt>
                                <dd className='col-sm-7'>
                                  {item.indirizzo??'-'}
                                </dd>
                                <dt className='col-sm-5'>Anno costruzione</dt>
                                <dd className='col-sm-7'>
                                  {item.annoc??'-'}
                                </dd>
                                <dt className='col-sm-5 mt-2'>Classe Energetica</dt>
                                <dd className='col-sm-7'>
                                  {item.claen??'-'}
                                </dd>


                              </dl>

                          </Col>
                          <Col xs='6'>
                          {//"clima_inv":true,"clima_est":false,"ventilazione_mec":false,"produzione_acs":true,"illuminazione":true,"trasporto_pc":false
                          }
                              <h5>Servizi energetici presenti</h5>
                              <div class="it-list-wrapper">
                                <LinkList>



                                  {item.clima_inv && (<LinkListItem><svg className="icon icon-xs  mr-1"><use href={ sprite2 + "#it-check-circle" }></use></svg><span>Climatizzazione invernale</span></LinkListItem>)}

                                  {item.clima_est && (<LinkListItem><svg className="icon icon-xs  mr-1"><use href={ sprite2 + "#it-check-circle" }></use></svg><span>Climatizzazione estiva</span></LinkListItem>)}
                                  {item.ventilazione_mec && (<LinkListItem><svg className="icon icon-xs  mr-1"><use href={ sprite2 + "#it-check-circle" }></use></svg><span>Ventilazione meccanica</span></LinkListItem>)}
                                  {item.produzione_acs && (<LinkListItem><svg className="icon icon-xs  mr-1"><use href={ sprite2 + "#it-check-circle" }></use></svg><span>Prod. acqua calda sanitaria</span></LinkListItem>)}
                                  {item.illuminazione && (<LinkListItem><svg className="icon icon-xs  mr-1"><use href={ sprite2 + "#it-check-circle" }></use></svg><span>Illuminazione</span></LinkListItem>)}
                                  {item.trasporto_pc && (<LinkListItem><svg className="icon icon-xs  mr-1"><use href={ sprite2 + "#it-check-circle" }></use></svg><span>Trasporto di persone o cose</span></LinkListItem>)}
                                </LinkList>
                              </div>


                          </Col>

                          <Col xs='12'>

                            <dl className='row mt-5'>
                              <dt className='col-sm-9 mb-2'>Se il tuo immobile fosse stato costruito dopo il 2015 avrebbe questa classe energetica</dt>
                              <dd className='col-sm-3 m-auto p-2 mt-2 text-center text-bold' style={{backgroundColor: backgroundColorApe[item.ce_nuovi]}}>
                                <h5 className="text-secondary">{item.ce_nuovi ??'-'}</h5>
                              </dd>
                            </dl>
                          </Col>

                        </Row>

                    </div>
                </div>
              </div>
            </div>

        </article>
        <article id="apeimmobile" className="it-page-section anchor-offset">

            <div className="card-wrapper card-teaser-wrapper">
                <div className="card  shadow mt-3 rounded">
                <div className="card-body">

                    <h5 className="card-title">
                        <svg className="icon mr-2 ">
                          <use href={ sprite2 + "#it-chart-line" }></use>
                        </svg>
                        PRESTAZIONI ENERGETICHE DELL'INVOLUCRO EDILIZIO
                    </h5>
                    <div className="card-text mt-2 ">
                      In base alle informazioni presenti sul tuo APE, l'involucro edilizio del tuo immobile, quindi gli elementi costruttivi opachi (pareti, solai, ecc.) e trasparenti (finestre), hanno queste prestazioni energetiche.
                      <div className="row mt-5">

                        <div className="col text-right">
                          <img src={smileinverno} className="smileinverno" alt=""/>
                        </div>

                        <div className="col imagePrestEnerg">
                          <img src={inverno} className="imgPrestEnerg" alt=""/>
                        </div>
                        <div className="col imagePrestEnerg">

                          <img src={estate} className="imgPrestEnerg" alt=""/>
                        </div>
                        <div className="col text-left">
                          <img src={smileestate} className="smileestate" alt=""/>

                        </div>
                      </div>
                      <div className="row mt-3">

                        <div className="col text-right">
                        </div>

                        <div className="col imagePrestEnerg">
                          <h6>Prestazione {prestazioneinverno}</h6>
                        </div>
                        <div className="col imagePrestEnerg">

                        <h6>Prestazione {prestazioneestate}</h6>
                        </div>
                        <div className="col text-left">

                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>

        </article>
        </div>

      )
  }


    const ContentApeSimulazione= (item,props,simval,simvalTot,simval2,simvalTot2) => {


      const rageSimulazione={
        'surismin':(item.suris-10),
        'surismax':(item.suris+10),
        'supdimin':(item.supdi-10),
        'supdimax':(item.supdi+10),
        'vlrismin':(item.vlris-10),
        'vlrismax':(item.vlris+10),
        'annocmin':(item.annoc-10),
        'annocmax':(item.annoc+10),
      }

      return(
        <div>

          <article id="simulatoreape" className="it-page-section anchor-offset">

              <div className="card-wrapper card-teaser-wrapper">
                  <div className="card  shadow mt-3 rounded">
                  <div className="card-body">

                      <h5 className="card-title">
                          <svg className="icon mr-2 ">
                            <use href={ sprite2 + "#it-chart-line" }></use>
                          </svg>
                          CONFRONTA IL TUO IMMOBILE
                      </h5>
                      <div className="card-text mt-2 ">
                        Grazie al Sistema Informativo sugli Attestati di Prestazione Energetica, SIAPE, puoi vedere quali sono le prestazioni energetiche di immobili con caratteristiche simili al tuo.<br/><br/>
                        Gli APE nel SIAPE che certificano immobili con:
                        <LinkList>
                          <LinkListItem><span>La superficie riscaldata dichiarata è {item.suris} mq., la simulazione sarà fatta tra {rageSimulazione.surismin} e {rageSimulazione.surismax} mq.</span></LinkListItem>
                          <LinkListItem><span>La superficie disperdente dichiarata è {item.supdi} mq., la simulazione sarà fatta  tra {rageSimulazione.supdimin} e {rageSimulazione.supdimax} mq.</span></LinkListItem>
                          <LinkListItem><span>Il volume riscaldato dichiarato è {item.vlris} mc., la simulazione sarà fatta  tra {rageSimulazione.vlrismin} e {rageSimulazione.vlrismax} mc.</span></LinkListItem>
                          <LinkListItem><span>L'anno di costruzione dichiarato è {item.annoc}, la simulazione sarà fatta  tra {rageSimulazione.annocmin} e {rageSimulazione.annocmax}</span></LinkListItem>
                        </LinkList>

                        Totale di <strong>{simvalTot}</strong> e si collocano nelle seguenti classi energetiche:
                        <div className="row mt-2">
                          {Object.keys(backgroundColorApe).map((key) =>
                            <div className="simClassi" key={key} style={{width:'10%',backgroundColor: backgroundColorApe[key]}}>
                              {key}
                              <br/>
                              <b>{(simval[key])?(Math.round(simval[key]/simvalTot*100*10)/10):0} %</b>


                            </div>
                          )}

                        </div>
                        <br/>
                        <br/>
                        Gli APE nel SIAPE che certificano immobili con le stesse caratteristiche, ma certificati a seguito di una ristrutturazione importante o di una riqualificazione energetica sono <strong>{simvalTot2}</strong> e si collocano nelle seguenti classi energetiche:
                        <div className="row mt-2">
                          {Object.keys(backgroundColorApe).map((key) =>
                            <div className="simClassi" key={key} style={{width:'10%',backgroundColor: backgroundColorApe[key]}}>
                              {key}
                              <br/>
                              <b>{(simval2[key])?(Math.round(simval2[key]/simvalTot2*100*10)/10):0} %</b>



                            </div>
                          )}

                        </div>
                      </div>
                  </div>
                </div>
              </div>

          </article>
          </div>

        )
    }


function Ape(props) {

  const [data, setData] = useState(props.data);
  const [openApe, toggleModalApe] = useState(false);
  const [contentModalApe, setContentModalApe] = useState('');
  const toggleApe = () => toggleModalApe(!openApe);
  // const [smileestate, setSmileestate] = useState(smile1);
  // const [smileinverno, setSmileinverno] = useState(smile2);
  const [openApeSimulazione, toggleModalApeSimulazione] = useState(false);
  const [contentModalApeSimulazione, setContentModalApeSimulazione] = useState('');
  const toggleApeSimulazione = () => toggleModalApeSimulazione(!openApeSimulazione);

  const [loadingStat, setLoadingStat] = useState('none');

  let simval={}
  let simvalTot=0
  let simval2={}
  let simvalTot2=0


    const openModalApe = (item) => {


            setContentModalApe(ContentApe(item,props));
            toggleModalApe(true);



     };


     const openModalApeSimulazione = (item) => {

       const apeconfrontoSimulazione = async () => {
    //     setLoading(true);
         setLoadingStat('block');

         let URL='//pnpe2.enea.it/api/data/rpc/classe_confronto?codice_ape='+item.codide
           axios.get(
             URL
           )
           .then((response)=>{
             response.data.map((val, i) => {
               simval[val['classe']]=val['num']
               simvalTot+=val['num']
               return []
             })
             let URL='//pnpe2.enea.it/api/data/rpc/classe_confronto_motiv?codice_ape='+item.codide
               axios.get(
                 URL
               )
               .then((response)=>{

                 response.data.map((val, i) => {
                   simval2[val['classe']]=val['num']
                   simvalTot2+=val['num']
                   return []

                 })
                 setContentModalApeSimulazione(ContentApeSimulazione(item,props,simval,simvalTot,simval2,simvalTot2));
                 toggleModalApeSimulazione(true);
               })
               .finally(()=>{
              //     setLoading(false);
                   setLoadingStat('none');
                 }
               )
               .catch((e)=>{
                 //console.log(e)
               })



           })
           .finally(()=>{
             //  setLoading(false);
             }
           )
           .catch((e)=>{
             //console.log(e)
           })
         };

         apeconfrontoSimulazione();




      };



  useEffect(() => {


    if(props.data){
      let URL='//pnpe2.enea.it/api/data/pnpe2_ape_validi_new?or=(codide.eq.'+props.data.join(',codide.eq.')+')'
        axios.get(
          URL
        )
        .then((response)=>{
          setData(response.data)
        })
        .finally(()=>{
          //  setLoading(false);
          }
        )
        .catch((e)=>{
          //console.log(e)
        })

    }


  }, [props.data]);



  if (data) {
    return (
      <div>
              <div className="">

                      <p className="card-text text-sans-serif">
                          <table className="table  w-100">
                            <thead>
                              <tr>
                                <th scope="col">Codice Ape</th>
                                <th scope="col">Status</th>
                                <th scope="col">Valido da</th>
                                <th scope="col">Classe energetica</th>
                                <th scope="col">Epglren</th>
                                <th scope="col">Epglnren</th>
                                <th scope="col">CO2</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                            {data.map((item, i) => {
                              return (
                                <Fragment>
                                  <tr key={i}>
                                    <td>{item.codide}</td>
                                    <td>Inviato</td>
                                      <td>{ (item.valda instanceof Date) ? item.valda.toLocaleDateString() : new Date(item.valda).toLocaleDateString() }</td>
                                    <td>{item.claen}</td>
                                    <td>{item.epglren}</td>
                                    <td>{item.epglnren}</td>
                                    <td>{item.co2}</td>
                                    <td>
                                    <Button color='primary' onClick={() => openModalApe(item)} className='mr-3'  size='xs'>
                                      Dettagli
                                    </Button>
                                    <Button color='primary' onClick={() => openModalApeSimulazione(item)} className='mr-3'  size='xs'>
                                      Immobili simili
                                    </Button>
                                    </td>
                                  </tr>

                                </Fragment>
                              );
                            })}

                            </tbody>
                          </table>
                            <Modal fade centered isOpen={openApe} toggleApe={toggleApe} labelledBy='esempio14' size='lg'>

                              <ModalBody>
                                {contentModalApe}
                              </ModalBody>
                              <ModalFooter>
                                <Button color='secondary' onClick={toggleApe} size='xs'>
                                  Chiudi
                                </Button>
                              </ModalFooter>
                            </Modal>

                            <Modal fade centered isOpen={openApeSimulazione} toggleApeSimulazione={toggleApeSimulazione} labelledBy='esempio14' size='lg'>

                              <ModalBody>
                                {contentModalApeSimulazione}
                              </ModalBody>
                              <ModalFooter>
                                <Button color='secondary' onClick={toggleApeSimulazione} size='xs'>
                                  Chiudi
                                </Button>
                              </ModalFooter>
                            </Modal>
                      </p>
                    </div>
                    <div class={`spinner-border-overlay d-${loadingStat}`} role="status">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>


    )
  } else {
    return null;
  }
};

export default Ape;
