import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import { Link } from 'react-router-dom';
import { LinkList, LinkListItem } from 'design-react-kit';
import sprite2 from "../assets/svg/sprite.svg";

function Epc() {
  return (
    <div>
    <HeaderTop />
          <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="servizi">Servizi</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Epc
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Contratto Prestazione Energetica (EPC)</h1>
                            <div className="row">
                                <div className="col-md-9">
                                Il contratto di prestazione energetica, conosciuto con l’acronimo inglese EPC (di recente aggiornato in EnPC), trova, per la prima volta, formale riconoscimento nel panorama normativo italiano con il Decreto legislativo n. 102/2014, che recepisce la Direttiva comunitaria n. 2012/27/UE.                             
                                </div>
                                <div className="col-md-3">
                                <a className="nav-link text-right" rel="noreferrer" href="https://www.enea.it/it/Ricerca_sviluppo/documenti/ricerca-di-sistema-elettrico/adp-mise-enea-2015-2017/edifici-nzeb/rds_par2016_266.pdf" target="_blank"><span class="btn btn-primary text-white">Linee guida</span></a>
                                </div>
                           </div>
                            <div className="row border-top row-column-border row-column-menu-left">
                                <aside className="col-lg-4">
                                    <div className="sticky-wrapper navbar-wrapper">
                                    <nav
                                        className="navbar it-navscroll-wrapper it-top-navscroll navbar-expand-lg"
                                    >
                                        <button
                                        className="custom-navbar-toggler"
                                        type="button"
                                        aria-controls="navbarNav"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                        data-target="#navbarNav"
                                        >
                                        <span className="it-list"></span>Sezioni
                                        </button>
                                        <div className="navbar-collapsable" id="navbarNav">
                                        <div className="overlay"></div>
                                        <div className="close-div sr-only">
                                            <button className="btn close-menu" type="button">
                                            <span className="it-close"></span>Chiudi
                                            </button>
                                        </div>
                                        <a className="it-back-button" href="/#">
                                            <svg className="icon icon-sm icon-primary align-top">
                                            <use xlinkhref="/assets/bootstrap-italia/dist/svg/sprite.svg#it-chevron-left"></use>
                                            </svg>
                                            <span>Torna indietro</span></a
                                        >
                                        <div className="menu-wrapper">
                                            <div className="link-list-wrapper menu-link-list">
                                            <h3 className="no_toc">Sezioni</h3>
                                            <ul className="link-list">
                                                <li className="nav-item">
                                                <a className="nav-link" href="#descrizione"
                                                    ><span>Descrizione</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="#normative"
                                                    ><span>Normativa</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="#bibliografia"
                                                    ><span>Bibliografia</span></a
                                                >
                                                </li>
                                                
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </nav>
                                    </div>
                                </aside>
                                <section className="col-lg-8 it-page-sections-container">
                                    <article id="descrizione" className="it-page-section anchor-offset">
                                    <h4>Descrizione</h4>
                                    <p className="text-serif">
                                    Oggetto dell’EPC è il miglioramento delle prestazioni energetiche di un edificio o di un impianto, attraverso la regolamentazione di un servizio energetico, globale e complesso.<br/>
                                    Il legislatore individua il contratto EPC come lo strumento adatto alla riqualificazione energetica degli edifici, in ragione della sua idoneità a produrre risparmi misurabili e verificabili, il cui controvalore consente di ripagare gli investimenti in efficienza energetica.<br/>
                                    Il cliente (beneficiario della misura di miglioramento dell’efficienza energetica) ha il duplice vantaggio di non dover (necessariamente) sostenere l’investimento iniziale e di vedere realizzata una riqualificazione energetica i cui obiettivi (minimi) di efficientamento sono garantiti contrattualmente.<br/>
                                    Il fornitore (che solitamente coincide con una società di servizi energetici: ESCo) è incentivato a porre in essere le misure di efficienza energetica pattuite e garantirne l’efficacia, in quanto recupera il proprio investimento con i risparmi generati per tutta la durata contrattuale.<br/>
                                    L’EPC si connota quindi come un contratto “win win”, poiché gli interessi delle parti convergono verso l’obiettivo comune di massimizzazione dei risparmi energetici.<br/>
                                    </p>
                                    
                                    </article>
                                    <hr></hr>
                                    <article id="normative" className="it-page-section anchor-offset mt-5">
                                    <h4>Normativa</h4>
                                    <p className="text-serif">
                                        <h5>Comunitaria</h5>
                                        <LinkList>
                                            <LinkListItem>
                                            <span><a href="https://www.senato.it/service/PDF/PDFServer/BGT/01150060.pdf" target="_blank" rel="noreferrer"> <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            Direttiva 2018/2002/UE sull'efficienza energetica – modificativa della Direttiva 2012/27/UE </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <span><a href="https://www.senato.it/service/PDF/PDFServer/BGT/01143499.pdf" target="_blank" rel="noreferrer"><svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            Direttiva 2018/844/UE sulla prestazione energetica nell'edilizia – modificativa della Direttiva 2010/31/UE </a></span>
                                            </LinkListItem>
                                        </LinkList>
                                        <h5>Nazionale</h5>
                                        <LinkList>
                                            <LinkListItem>
                                            <span><a href="https://www.normattiva.it/uri-res/N2Ls?urn:nir:stato:decreto.legislativo:2020;73~art9" target="_blank" rel="noreferrer"> <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            D.lgs. 73/2020 </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <span><a href="https://www.gazzettaufficiale.it/eli/id/2020/06/10/20G00066/sg" target="_blank" rel="noreferrer"><svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            D.lgs. 48/2020 </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <span><a href="https://www.normattiva.it/uri-res/N2Ls?urn:nir:stato:decreto.legislativo:2014-07-04;102!vig=" target="_blank" rel="noreferrer"><svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            D.lgs. 102/2014 </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <span><a href="https://www.normattiva.it/uri-res/N2Ls?urn:nir:stato:decreto.legislativo:2008;115" target="_blank" rel="noreferrer"><svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            D.lgs. 115/2008 </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <span><a href="https://www.bosettiegatti.eu/public/2016_0050_codice_contratti.pdf" target="_blank" rel="noreferrer"><svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            D.lgs. 50/2016 s.m.i. – nuovo Codice dei contratti pubblici aggiornato al D.lgs. 56/2017 «correttivo» e alle modifiche della L. 120/2020 di conversione del Decreto semplificazioni e L. 108/2021 di conversione del Decreto Semplificazioni bis – PNRR. </a></span>
                                            </LinkListItem>
                                        </LinkList>
                                    </p>
                                    </article>
                                    <article id="bibliografia" className="it-page-section anchor-offset">
                                    <hr/>
                                    <h4>Bibliografia</h4>
                                    <p className="text-serif">
                                    <a href="https://iris.enea.it/handle/20.500.12079/6810?mode=complete" target="_blank" rel="noreferrer">I contratti di prestazione energetica (EPC). Aspetti giuridici degli EPC e ipotesi per il superamento dell'incertezza normativa sulla regolazione degli elementi essenziali del contratto</a>
                                       <br/>
                                    </p>
                                    
                                    </article>
                                </section>
                                </div>

                            </div>

                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Epc;
