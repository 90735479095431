import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import Softwareinc from "../components/softwareinc.js";
import "../App.css";
import { Link } from 'react-router-dom';

function Software() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Software and Tools
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Software and Tools</h1>
                            <p>
                            Una vetrina tecnologica sui principali strumenti messi a punto da ENEA per una prima valutazione del fabbisogno energetico dell’edificio, dei possibili interventi di ammodernamento tecnologico e della loro valutazione costi-benefici, in termini di efficientamento energetico e risparmio economico. Strumenti di supporto a tecnici e decision maker del settore pubblico, ma d’ausilio anche ai privati cittadini per meglio comprendere e migliorare il comportamento energetico della propria abitazione.<br/>
                            L’impiego degli applicativi non sostituisce in alcun modo la diagnosi energetica condotta da tecnici qualificati del settore.
                            </p>
                            <Softwareinc />
                            </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Software;