import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import { Link } from 'react-router-dom';
import { LinkList, LinkListItem } from 'design-react-kit';
import sprite2 from "../assets/svg/sprite.svg";

function Contotermico() {
  return (
    <div>
    <HeaderTop />
          <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="servizi">Servizi</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Conto Termico
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Conto Termico</h1>
                            <div className="row">
                                <div className="col-md-9">
                                Grazie al Conto Termico è possibile riqualificare i propri edifici per migliorarne le prestazioni energetiche, riducendo in tal modo i costi dei consumi e recuperando in tempi brevi parte della spesa sostenuta.</div>
                                <div className="col-md-3">
                                <a className="nav-link text-right" rel="noreferrer" href="https://www.gse.it/servizi-per-te/efficienza-energetica/conto-termico/documenti" target="_blank"><span class="btn btn-primary text-white">Guide</span></a>
                                </div>
                           </div>
                            <div className="row border-top row-column-border row-column-menu-left">
                                <aside className="col-lg-4">
                                    <div className="sticky-wrapper navbar-wrapper">
                                    <nav
                                        className="navbar it-navscroll-wrapper it-top-navscroll navbar-expand-lg"
                                    >
                                        <button
                                        className="custom-navbar-toggler"
                                        type="button"
                                        aria-controls="navbarNav"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                        data-target="#navbarNav"
                                        >
                                        <span className="it-list"></span>Sezioni
                                        </button>
                                        <div className="navbar-collapsable" id="navbarNav">
                                        <div className="overlay"></div>
                                        <div className="close-div sr-only">
                                            <button className="btn close-menu" type="button">
                                            <span className="it-close"></span>Chiudi
                                            </button>
                                        </div>
                                        <a className="it-back-button" href="/#">
                                            <svg className="icon icon-sm icon-primary align-top">
                                            <use xlinkhref="/assets/bootstrap-italia/dist/svg/sprite.svg#it-chevron-left"></use>
                                            </svg>
                                            <span>Torna indietro</span></a
                                        >
                                        <div className="menu-wrapper">
                                            <div className="link-list-wrapper menu-link-list">
                                            <h3 className="no_toc">Sezioni</h3>
                                            <ul className="link-list">
                                                <li className="nav-item">
                                                <a className="nav-link" href="#descrizione"
                                                    ><span>Descrizione</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="#normative"
                                                    ><span>Normativa</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="https://www.gse.it/servizi-per-te/efficienza-energetica/conto-termico/modulistica" target="_blank" rel="noreferrer"
                                                    ><span>Modulistica</span></a
                                                >
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </nav>
                                    </div>
                                </aside>
                                <section className="col-lg-8 it-page-sections-container">
                                    <article id="descrizione" className="it-page-section anchor-offset">
                                    <h4>Descrizione</h4>
                                    <p className="text-serif">
                                    Il Conto Termico incentiva interventi per l'incremento dell'efficienza energetica e la produzione di energia termica da fonti rinnovabili per impianti di piccole dimensioni. I beneficiari sono principalmente le Pubbliche amministrazioni, ma anche imprese e privati, che potranno accedere a fondi per 900 milioni di euro annui, di cui 200 destinati alle PA. <br/>
                                    Recentemente, il Conto Termico è stato rinnovato rispetto a quello introdotto dal D.M. 28/12/2012.<br/>
                                    Oltre ad un ampliamento delle modalità di accesso e dei soggetti ammessi (sono ricomprese fra le PA anche le società cooperative sociali e le cooperative di abitanti), sono previsti nuovi interventi di efficienza energetica. E' stata inoltre rivista la dimensione degli impianti ammissibili e snellita la procedura di accesso diretto per apparecchi con caratteristiche già approvate e certificate (Catalogo).<br/>
                                    Il limite massimo per l'erogazione degli incentivi in un'unica rata è di 5.000 euro e i tempi di pagamento sono all'incirca di 2 mesi.<br/>
                                    Per ulteriori approfondimenti clicca <a href="https://www.gse.it/servizi-per-te/efficienza-energetica/conto-termico" target="_blank" rel="noreferrer">qui</a> 
                                    </p>
                                    </article>
                                    <hr></hr>
                                    <article id="normative" className="it-page-section anchor-offset mt-5">
                                    <h4>Normativa</h4>
                                    <p className="text-serif">
                                        <LinkList>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.gse.it/documenti_site/Documenti GSE/Servizi per te/CONTO TERMICO/NORMATIVA/Decreto interministeriale 16 febbraio 2016.PDF" target="_blank" rel="noreferrer">Decreto MISE 16/02/2016 </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.gse.it/documenti_site/Documenti GSE/Servizi per te/CONTO TERMICO/NORMATIVA/Allegato decreto interministeriale 16 febbraio 2016.PDF" target="_blank" rel="noreferrer">Allegato DM 16/02/2016 </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.gse.it/documenti_site/Documenti GSE/Servizi per te/CONTO TERMICO/REGOLE APPLICATIVE/REGOLE_APPLICATIVE_CT.pdf" target="_blank" rel="noreferrer">Regole Applicative </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.gse.it/supporto/manuali-moduli-e-procedure" target="_blank" rel="noreferrer">Manuali, moduli e procedure </a></span>
                                            </LinkListItem>
                                        </LinkList>
                                        Per eventuali aggiornamenti consultare la sezione DECRETO E REGOLE al seguente <a href="https://www.gse.it/servizi-per-te/efficienza-energetica/conto-termico" target="_blank" rel="noreferrer">link</a> 

                                    </p>
                                    </article>
                                </section>
                                </div>

                            </div>

                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Contotermico;
