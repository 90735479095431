import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import { Link } from 'react-router-dom';
import { LinkList, LinkListItem } from 'design-react-kit';
import sprite2 from "../assets/svg/sprite.svg";

function Certificatibianchi() {
  return (
    <div>
    <HeaderTop />
          <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="servizi">Servizi</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Certificati bianchi
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Certificati Bianchi</h1>
                            <div className="row">
                                <div className="col-md-9">
                                    Se realizzi un intervento di efficienza energetica che garantisce un risparmio energetico sostanziale, puoi ottenere dei titoli trasformabili in denaro, i cosiddetti certificati bianchi. 
                                </div>
                                <div className="col-md-3">
                                <a className="nav-link text-right" rel="noreferrer" href="https://www.gse.it/servizi-per-te/efficienza-energetica/certificati-bianchi/documenti" target="_blank"><span class="btn btn-primary text-white">Documenti</span></a>
                                </div>
                           </div>
                            <div className="row border-top row-column-border row-column-menu-left">
                                <aside className="col-lg-4">
                                    <div className="sticky-wrapper navbar-wrapper">
                                    <nav
                                        className="navbar it-navscroll-wrapper it-top-navscroll navbar-expand-lg"
                                    >
                                        <button
                                        className="custom-navbar-toggler"
                                        type="button"
                                        aria-controls="navbarNav"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                        data-target="#navbarNav"
                                        >
                                        <span className="it-list"></span>Sezioni
                                        </button>
                                        <div className="navbar-collapsable" id="navbarNav">
                                        <div className="overlay"></div>
                                        <div className="close-div sr-only">
                                            <button className="btn close-menu" type="button">
                                            <span className="it-close"></span>Chiudi
                                            </button>
                                        </div>
                                        <a className="it-back-button" href="/#">
                                            <svg className="icon icon-sm icon-primary align-top">
                                            <use xlinkhref="/assets/bootstrap-italia/dist/svg/sprite.svg#it-chevron-left"></use>
                                            </svg>
                                            <span>Torna indietro</span></a
                                        >
                                        <div className="menu-wrapper">
                                            <div className="link-list-wrapper menu-link-list">
                                            <h3 className="no_toc">Sezioni</h3>
                                            <ul className="link-list">
                                                <li className="nav-item">
                                                <a className="nav-link" href="#descrizione"
                                                    ><span>Descrizione</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="#normative"
                                                    ><span>Normativa</span></a
                                                >
                                                </li>
                                                
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </nav>
                                    </div>
                                </aside>
                                <section className="col-lg-8 it-page-sections-container">
                                    <article id="descrizione" className="it-page-section anchor-offset">
                                    <h4>Descrizione</h4>
                                    <p className="text-serif">
                                    Il meccanismo dei certificati bianchi, entrato in vigore nel 2005, è il principale strumento di promozione dell'efficienza energetica in Italia.<br/>
                                    I certificati bianchi sono titoli negoziabili che certificano il conseguimento di risparmi negli usi finali di energia attraverso interventi e progetti di incremento dell'efficienza energetica. Un certificato equivale al risparmio di una Tonnellata Equivalente di Petrolio (TEP).<br/><br/>

                                        Per ulteriori approfondimenti clicca <a href="https:\www.gse.it\servizi-per-te\efficienza-energetica\certificati-bianchi" target="_blank" rel="noreferrer">qui</a> 

                                    </p>
                                    
                                    </article>
                                    <hr></hr>
                                    <article id="normative" className="it-page-section anchor-offset mt-5">
                                    <h4>Normativa</h4>
                                    <p className="text-serif">
                                        <LinkList>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.gse.it/documenti_site/Documenti GSE/Servizi per te/CERTIFICATI BIANCHI/DOCUMENTI/Schema Decreto Direttoriale 30 aprile 2019.pdf" target="_blank" rel="noreferrer">Decreto Direttoriale 30 aprile 2019 </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.gse.it/documenti_site/Documenti GSE/Servizi per te/CERTIFICATI BIANCHI/NORMATIVA/DM MISE su risparmio ed efficienza energetica in gu.pdf" target="_blank" rel="noreferrer">D.M. 10 maggio 2018 </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.gse.it/documenti_site/Documenti GSE/Servizi per te/CERTIFICATI BIANCHI/NORMATIVA/DM 11 gennaio 2017.PDF" target="_blank" rel="noreferrer">D.M.11 gennaio 2017 </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.gse.it/documenti_site/Documenti GSE/Servizi per te/CERTIFICATI BIANCHI/NORMATIVA/Decreto Ministeriale 28 dicembre 2012.PDF" target="_blank" rel="noreferrer">D.M. Sviluppo Economico 28/12/2012 </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.gse.it/documenti_site/Documenti GSE/Servizi per te/CERTIFICATI BIANCHI/NORMATIVA/Linee guida EEN 9-11.PDF" target="_blank" rel="noreferrer">Deliberazione dell'Autorità 09/2011 </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.gse.it/documenti_site/Documenti GSE/Servizi per te/CERTIFICATI BIANCHI/NORMATIVA/DM 2004 GAS.pdf" target="_blank" rel="noreferrer">D.M. Attività Produttive 20/07/2004 GAS </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.gse.it/documenti_site/Documenti GSE/Servizi per te/CERTIFICATI BIANCHI/NORMATIVA/DM 2004 Elettrico.pdf" target="_blank" rel="noreferrer">D.M. Attività Produttive 20/07/2004 ELETTRICO </a></span>
                                            </LinkListItem>
                                        </LinkList>
                                    </p>
                                    <p className="text-serif">Per eventuali aggiornamenti consultare la sezione NORMATIVA al seguente <a href="https://www.gse.it/servizi-per-te/efficienza-energetica/certificati-bianchi" target="_blank" rel="noreferrer">link</a></p>
                                    </article>
                                </section>
                                </div>

                            </div>

                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Certificatibianchi;
