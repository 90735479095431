import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../scss/bootstrap-italia-custom.scss";
import "../App.css";
import { Link } from 'react-router-dom';

function Glossario() {
  return (
    <div>
    <HeaderTop />
         <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="/formazione_professionale">Formazione</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Glossario EE
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Glossario EE</h1>
                            <p className="mt-4">
                            <div className="sppb-addon sppb-addon-module card-columns"><div className="sppb-addon-content"><ul className="category-module mod-list">
                                 <li>
                                <div className="mod-articles-category-group">A</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-a/acquirente-unico.html"  target="_blank" rel="noreferrer">
                                                    Acquirente Unico</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-a/addizionalita.html"  target="_blank" rel="noreferrer">
                                                    Addizionalità (Additionality)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-a/aggregatore.html"  target="_blank" rel="noreferrer">
                                                    Aggregatore </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-a/ammodernamento.html"  target="_blank" rel="noreferrer">
                                                    Ammodernamento ( Retrofit)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-a/ammortamento.html"  target="_blank" rel="noreferrer">
                                                    Ammortamento</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-a/analisi-costi-benefici.html"  target="_blank" rel="noreferrer">
                                                    Analisi costi benefici</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-a/analisi-costi-sociali-societal-cost-test.html"  target="_blank" rel="noreferrer">
                                                    Analisi costi sociali (Societal Cost Test)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-a/analisi-costi-sociali-societal-cost-test-2.html"  target="_blank" rel="noreferrer">
                                                    Analisi costi totali delle risorse (Total Resource Cost Test) </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-a/analisi-dei-costi-delle-societa-di-erogazione-di-servizi-energetici-utility-cost-test.html"  target="_blank" rel="noreferrer">
                                                    Analisi dei Costi delle Società di erogazione di servizi energetici (Utility Cost Test)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-a/analisi-dell-impatto-di-una-misura-sul-contribuente-ratepayer-impact-measure-test-rim-test.html"  target="_blank" rel="noreferrer">
                                                    Analisi dell’impatto di una misura sul contribuente (Ratepayer Impact Measure Test  RIM test )</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-a/ape-attestato-di-prestazione-energetica.html"  target="_blank" rel="noreferrer">
                                                    APE - Attestato di Prestazione Energetica </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-a/ape-convenzionale.html"  target="_blank" rel="noreferrer">
                                                    APE convenzionale</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-a/arera.html"  target="_blank" rel="noreferrer">
                                                    ARERA</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-a/azionamento-a-velocita-regolabile-variabile-adjustable-speed-drive-asd.html"  target="_blank" rel="noreferrer">
                                                    Azionamento a velocità regolabile variabile (Adjustable Speed Drive ASD)</a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">B</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-b/bacino-di-offerta-elettrica.html"  target="_blank" rel="noreferrer">
                                                    Bacino di offerta elettrica ( Power Pool) </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-b/batteria-di-alimentazione-ausiliaria.html"  target="_blank" rel="noreferrer">
                                                    Batteria di alimentazione ausiliaria (Auxiliary Power Unit)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-b/bilancio-di-sostenibilita.html"  target="_blank" rel="noreferrer">
                                                    Bilancio di sostenibilità</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-b/bilancio-energetico.html"  target="_blank" rel="noreferrer">
                                                    Bilancio energetico</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-b/biocarburanti.html"  target="_blank" rel="noreferrer">
                                                    Biocarburanti</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-b/biogas.html"  target="_blank" rel="noreferrer">
                                                    Biogas</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-b/bioliquidi.html"  target="_blank" rel="noreferrer">
                                                    Bioliquidi</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-b/biomassa.html"  target="_blank" rel="noreferrer">
                                                    Biomassa</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-b/blower-door-test.html"  target="_blank" rel="noreferrer">
                                                    Blower Door Test</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-b/building-commissioning.html"  target="_blank" rel="noreferrer">
                                                    Building Commissioning</a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">C</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/caldaia.html"  target="_blank" rel="noreferrer">
                                                    Caldaia ( Furnace) </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/cdr.html"  target="_blank" rel="noreferrer">
                                                    CDR</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/certificati-bianchi-o-titoli-di-efficienza-energetica.html"  target="_blank" rel="noreferrer">
                                                    Certificati bianchi ( o titoli di efficienza energetica)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/ciclo-combinato.html"  target="_blank" rel="noreferrer">
                                                    Ciclo combinato</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/climatizzatori-monoblocco.html"  target="_blank" rel="noreferrer">
                                                    Climatizzatori Monoblocco ( Packaged system) </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/cogenerazione.html"  target="_blank" rel="noreferrer">
                                                    Cogenerazione (Combined Heat and Power)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/combustione-magra.html"  target="_blank" rel="noreferrer">
                                                    Combustione magra ( Lean-burn) </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/comunita-energetiche.html"  target="_blank" rel="noreferrer">
                                                    Comunità energetiche </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/concorrenza-nel-mercato-all-ingrosso.html"  target="_blank" rel="noreferrer">
                                                    Concorrenza nel  mercato all’ingrosso (Wholesale Competition)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/condensatore.html"  target="_blank" rel="noreferrer">
                                                    Condensatore (Capacitor)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/condizionatori-ad-aria-centralizzati.html"  target="_blank" rel="noreferrer">
                                                    Condizionatori ad Aria Centralizzati (Central Air Conditioners)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/consumo-finale-lordo-di-energia.html"  target="_blank" rel="noreferrer">
                                                    Consumo finale lordo di energia</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/consumo-interno-lordo-di-energia-elettrica.html"  target="_blank" rel="noreferrer">
                                                    Consumo interno lordo di energia elettrica</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/contatore-elettronico-avanzato-o-contatore-intelligente.html"  target="_blank" rel="noreferrer">
                                                    Contatore elettronico avanzato o Contatore Intelligente (Advanced Metering Infrastructure/ Smart Meter )</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/conto-termico.html"  target="_blank" rel="noreferrer">
                                                    Conto Termico</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/conversione-fattori-di.html"  target="_blank" rel="noreferrer">
                                                    Conversione, fattori di</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/cooperative-elettriche-rurali.html"  target="_blank" rel="noreferrer">
                                                    Cooperative elettriche rurali (Rural Electric Cooperative)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/costi-incrementali.html"  target="_blank" rel="noreferrer">
                                                    Costi incrementali (Incremental Cost )</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/costo-livellato-dell-elettricita.html"  target="_blank" rel="noreferrer">
                                                    Costo livellato dell’elettricità (Levelized Cost of Electricity)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/costo-medio-unitario-della-bolletta.html"  target="_blank" rel="noreferrer">
                                                    Costo medio unitario della bolletta</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/crescita-compatta.html"  target="_blank" rel="noreferrer">
                                                    Crescita Compatta ( Compact growth) </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-c/curva-di-carico.html"  target="_blank" rel="noreferrer">
                                                    Curva di Carico </a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">D</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-d/demand-side-response-dsr.html"  target="_blank" rel="noreferrer">
                                                    Demand-side response (DSR)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-d/detrazioni-fiscali-per-l-efficienza-energetica.html"  target="_blank" rel="noreferrer">
                                                    Detrazioni fiscali per l’efficienza energetica</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-d/diagnosi-energetica.html"  target="_blank" rel="noreferrer">
                                                    Diagnosi energetica</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-d/diagnosi-energetica-in-abitazione-residenziale.html"  target="_blank" rel="noreferrer">
                                                    Diagnosi energetica in abitazione residenziale  ( Diagnostic Home Energy Surveys)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-d/diagnosi-energetica-su-edifici-residenziali.html"  target="_blank" rel="noreferrer">
                                                    Diagnosi energetica su edifici residenziali  (Comprehensive Home Energy Audits)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-d/dipendenza-energetica.html"  target="_blank" rel="noreferrer">
                                                    Dipendenza energetica</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-d/direttiva-comunitaria-o-europea.html"  target="_blank" rel="noreferrer">
                                                    Direttiva comunitaria (o europea)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-d/disaccoppiamento-delle-tariffe.html"  target="_blank" rel="noreferrer">
                                                    Disaccoppiamento delle tariffe (Decoupling)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-d/dispacciamento.html"  target="_blank" rel="noreferrer">
                                                    Dispacciamento</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-d/distributore-di-energia.html"  target="_blank" rel="noreferrer">
                                                    Distributore di energia</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-d/distribuzione-dell-energia.html"  target="_blank" rel="noreferrer">
                                                    Distribuzione dell’energia </a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">E</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/ecologia-industriale.html"  target="_blank" rel="noreferrer">
                                                    Ecologia Industriale (Industrial Ecology)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/edificio-a-energia-quasi-zero.html"  target="_blank" rel="noreferrer">
                                                    Edificio a energia quasi zero (Nearly Zero-Energy Building, NZEB)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/edificio-ad-alte-prestazioni.html"  target="_blank" rel="noreferrer">
                                                    Edificio ad alte prestazioni (High Performance Building, HPB) </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/edificio-verde.html"  target="_blank" rel="noreferrer">
                                                    Edificio Verde (Green Building)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/effetto-rimbalzo.html"  target="_blank" rel="noreferrer">
                                                    Effetto rimbalzo (Rebound Effect)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/elettricita-all-ingrosso.html"  target="_blank" rel="noreferrer">
                                                    Elettricità all’ingrosso (Wholesale Electricity)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/elettrificazione-dei-parcheggi-lunga-sosta-per-mezzi-pesanti.html"  target="_blank" rel="noreferrer">
                                                    Elettrificazione dei parcheggi lunga sosta per mezzi pesanti (Truck Stop Electrification TSE)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/energia-aerotermica.html"  target="_blank" rel="noreferrer">
                                                    Energia aerotermica</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/energia-da-fonti-rinnovabili.html"  target="_blank" rel="noreferrer">
                                                    Energia da fonti rinnovabili</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/energia-elettrica-richiesta-sulla-rete.html"  target="_blank" rel="noreferrer">
                                                    Energia elettrica richiesta sulla rete</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/energia-recuperata.html"  target="_blank" rel="noreferrer">
                                                    Energia Recuperata (Recovered Energy)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/energia-usi-finali.html"  target="_blank" rel="noreferrer">
                                                    Energia, usi finali</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/energy-manager.html"  target="_blank" rel="noreferrer">
                                                    Energy manager</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/energy-performance-contract-epc.html"  target="_blank" rel="noreferrer">
                                                    Energy Performance Contract (EPC) </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/energy-service-company-esco.html"  target="_blank" rel="noreferrer">
                                                    Energy Service Company (ESCO)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/esercizio-e-manutenzione.html"  target="_blank" rel="noreferrer">
                                                    Esercizio e Manutenzione (Operation and Maintenance)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-e/esperto-in-gestione-dell-energia-ege.html"  target="_blank" rel="noreferrer">
                                                    Esperto in Gestione dell’Energia (EGE)</a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">F</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-f/fattore-di-picco-coincidentale.html"  target="_blank" rel="noreferrer">
                                                    Fattore di picco coincidentale (Coincidental Peak Factor)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-f/feebate-neologismo.html"  target="_blank" rel="noreferrer">
                                                    Feebate ( neologismo)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-f/feed-in-tariff-fit.html"  target="_blank" rel="noreferrer">
                                                    Feed in Tariff (FIT)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-f/feed-in-premium-fip.html"  target="_blank" rel="noreferrer">
                                                    Feed-in Premium (FIP)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-f/fondo-nazionale-efficienza-energetica-fnee.html"  target="_blank" rel="noreferrer">
                                                    Fondo Nazionale Efficienza Energetica (FNEE)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-f/fonti-energetiche-assimilate.html"  target="_blank" rel="noreferrer">
                                                    Fonti energetiche assimilate</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-f/fonti-energetiche-convenzionali.html"  target="_blank" rel="noreferrer">
                                                    Fonti energetiche convenzionali</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-f/fonti-energetiche-primarie.html"  target="_blank" rel="noreferrer">
                                                    Fonti energetiche primarie</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-f/fonti-energetiche-rinnovabili.html"  target="_blank" rel="noreferrer">
                                                    Fonti energetiche rinnovabili</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-f/fonti-energetiche-secondarie-o-derivate.html"  target="_blank" rel="noreferrer">
                                                    Fonti energetiche secondarie o derivate</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-f/free-rider.html"  target="_blank" rel="noreferrer">
                                                    Free Rider</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-f/fuori-picco-di-carico.html"  target="_blank" rel="noreferrer">
                                                    Fuori picco (di carico) (Off-Peak)</a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">G</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-g/gestione-del-carico.html"  target="_blank" rel="noreferrer">
                                                    Gestione del carico (Load Management ) </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-g/gas-di-cokeria.html"  target="_blank" rel="noreferrer">
                                                    Gas di cokeria</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-g/gas-di-petrolio-liquefatti-gpl.html"  target="_blank" rel="noreferrer">
                                                    Gas di petrolio liquefatti (gpl)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-g/gas-naturale-liquefatto.html"  target="_blank" rel="noreferrer">
                                                    Gas naturale Liquefatto (Liquefied Natural Gas)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-g/gas-serra.html"  target="_blank" rel="noreferrer">
                                                    Gas serra (Greenhouse Gas)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-g/generazione-di-elettricita.html"  target="_blank" rel="noreferrer">
                                                    Generazione di elettricità (Electricity Generation)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-g/generazione-distribuita-di-energia.html"  target="_blank" rel="noreferrer">
                                                    Generazione distribuita di energia</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-g/generazione-elettrica-distribuita.html"  target="_blank" rel="noreferrer">
                                                    Generazione Elettrica Distribuita  (distributed generation)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-g/generazione-elettrica-rinnovabile.html"  target="_blank" rel="noreferrer">
                                                    Generazione Elettrica Rinnovabile (Renewable Generation)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-g/gestione-del-carico-elettrico.html"  target="_blank" rel="noreferrer">
                                                    Gestione del carico elettrico (Load Management)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-g/gestione-della-domanda-di-energia.html"  target="_blank" rel="noreferrer">
                                                    Gestione della Domanda di Energia (Demand-Side Management, DMS)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-g/gestore-dei-mercati-energetici-gme.html"  target="_blank" rel="noreferrer">
                                                    Gestore dei Mercati Energetici - GME</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-g/gestore-servizi-energetici-gse.html"  target="_blank" rel="noreferrer">
                                                    Gestore Servizi Energetici- GSE</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-g/gruppo-di-acquisto-di-energia.html"  target="_blank" rel="noreferrer">
                                                    Gruppo di acquisto di energia</a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">I</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-i/impresa-verticalmente-integrata.html"  target="_blank" rel="noreferrer">
                                                    Impresa Verticalmente Integrata (Vertical Integration)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-i/intensita-energetica.html"  target="_blank" rel="noreferrer">
                                                    Intensità energetica</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-i/interconnessione.html"  target="_blank" rel="noreferrer">
                                                    Interconnessione (Interconnection)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-i/involucro-termico.html"  target="_blank" rel="noreferrer">
                                                    Involucro termico (Thermal Envelope)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-i/ipcc.html"  target="_blank" rel="noreferrer">
                                                    IPCC</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-i/ipmvp.html"  target="_blank" rel="noreferrer">
                                                    IPMVP</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-i/iso-50001.html"  target="_blank" rel="noreferrer">
                                                    ISO 50001</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-i/isolamento-termico-o-cappottatura.html"  target="_blank" rel="noreferrer">
                                                    Isolamento Termico ( o cappottatura ) (Weatherization)</a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">K</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-k/kw-chilowatt.html"  target="_blank" rel="noreferrer">
                                                    kW - chilowatt</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-k/kwh-chilowattora.html"  target="_blank" rel="noreferrer">
                                                    kWh -  chilowattora </a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">L</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-l/lampade-fluorescenti-compatte.html"  target="_blank" rel="noreferrer">
                                                    Lampade fluorescenti compatte (Compact Fluorescent Lamp)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-l/lato-della-domanda.html"  target="_blank" rel="noreferrer">
                                                    Lato della Domanda (Demand side)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-l/lato-dell-offerta.html"  target="_blank" rel="noreferrer">
                                                    Lato dell’offerta (Supply-Side)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-l/limatura-del-picco.html"  target="_blank" rel="noreferrer">
                                                    Limatura del picco (Peak Shaving)</a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">M</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-m/macchina-frigorifera.html"  target="_blank" rel="noreferrer">
                                                    Macchina frigorifera (Chiller)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-m/markal.html"  target="_blank" rel="noreferrer">
                                                    MARKAL</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-m/mercato-dell-energia-elettrica-all-ingrosso.html"  target="_blank" rel="noreferrer">
                                                    Mercato dell’energia  elettrica all’ingrosso (Wholesale Power Market)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-m/misura-di-ammodernamento.html"  target="_blank" rel="noreferrer">
                                                    Misura di ammodernamento (Retrofit Measure)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-m/misura-e-verifica-m-v.html"  target="_blank" rel="noreferrer">
                                                    Misura e Verifica (M&amp;V)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-m/misure-per-l-efficienza-energetica.html"  target="_blank" rel="noreferrer">
                                                    Misure per l’efficienza energetica (Energy Efficiency Measure)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-m/monitoraggio.html"  target="_blank" rel="noreferrer">
                                                    Monitoraggio</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-m/mw-megawatt.html"  target="_blank" rel="noreferrer">
                                                    MW Megawatt</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-m/mwh-megawatt-ora.html"  target="_blank" rel="noreferrer">
                                                    MWh Megawatt-ora </a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">O</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-o/olio-combustibile.html"  target="_blank" rel="noreferrer">
                                                    Olio combustibile (Fuel Oil) </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-o/oneri-di-sistema.html"  target="_blank" rel="noreferrer">
                                                    Oneri di sistema</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-o/ossidi-di-azoto-nox.html"  target="_blank" rel="noreferrer">
                                                    Ossidi di azoto (NOx)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-o/ossidi-di-zolfo-sox.html"  target="_blank" rel="noreferrer">
                                                    Ossidi di zolfo (SOx)</a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">P</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/paee.html"  target="_blank" rel="noreferrer">
                                                    PAEE</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/perdite-di-trasporto-e-trasformazione-dell-energia-elettrica.html"  target="_blank" rel="noreferrer">
                                                    Perdite di trasporto e trasformazione dell’energia elettrica</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/pianificazione-integrata-delle-risorse.html"  target="_blank" rel="noreferrer">
                                                    Pianificazione Integrata delle Risorse (Integrated Resource Plan)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/piano-clima-energia.html"  target="_blank" rel="noreferrer">
                                                    Piano Clima Energia </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/piano-nazionale-integrato-per-l-energia-e-il-clima-2030-pniec.html"  target="_blank" rel="noreferrer">
                                                    Piano Nazionale Integrato per l’Energia e il Clima 2030 (PNIEC)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/picco-di-domanda.html"  target="_blank" rel="noreferrer">
                                                    Picco di domanda (Peak Demand)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/pioggia-acida.html"  target="_blank" rel="noreferrer">
                                                    Pioggia acida (Acid Rain)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/pompe-di-calore.html"  target="_blank" rel="noreferrer">
                                                    Pompe di calore (Heat Pump)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/potenza-efficiente-di-un-impianto-di-generazione.html"  target="_blank" rel="noreferrer">
                                                    Potenza efficiente (di un impianto di generazione)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/potenziale-economico.html"  target="_blank" rel="noreferrer">
                                                    Potenziale economico (Economic Potential)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/potenziale-raggiungibile-di-efficienza-energetica.html"  target="_blank" rel="noreferrer">
                                                    Potenziale raggiungibile di efficienza energetica (Achievable Potential)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/potenziale-tecnico.html"  target="_blank" rel="noreferrer">
                                                    Potenziale tecnico (Technical Potential)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/potere-calorifico.html"  target="_blank" rel="noreferrer">
                                                    Potere calorifico</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/prezzi-in-tempo-reale.html"  target="_blank" rel="noreferrer">
                                                    Prezzi in tempo reale  (Real Time Pricing)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/primes.html"  target="_blank" rel="noreferrer">
                                                    PRIMES</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/produzione-energia-primaria.html"  target="_blank" rel="noreferrer">
                                                    Produzione energia primaria</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/produzione-netta-di-energia-elettrica.html"  target="_blank" rel="noreferrer">
                                                    Produzione netta di energia elettrica</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/programma-di-riqualificazione-energetica-della-pubblica-amministrazione-centrale-prepac.html"  target="_blank" rel="noreferrer">
                                                    Programma di riqualificazione energetica della Pubblica Amministrazione Centrale (PREPAC)  </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/programmi-basati-sui-comportamenti.html"  target="_blank" rel="noreferrer">
                                                    Programmi basati sui comportamenti (Behavior-Based Programs)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/prosumer.html"  target="_blank" rel="noreferrer">
                                                    Prosumer</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-p/protocollo-di-kyoto.html"  target="_blank" rel="noreferrer">
                                                    Protocollo di Kyoto </a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">Q</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-q/qualita-ambientale-degli-interni.html"  target="_blank" rel="noreferrer">
                                                    Qualità ambientale degli interni (Indoor Environmental Quality  IEQ)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-q/qualita-dell-aria-negli-edifici.html"  target="_blank" rel="noreferrer">
                                                    Qualità dell’aria negli edifici (Indoor Air Quality)</a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">R</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-r/r-d-ricerca-e-sviluppo.html"  target="_blank" rel="noreferrer">
                                                    R&amp;D Ricerca e Sviluppo </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-r/reti-energetiche-di-trasporto-e-distribuzione.html"  target="_blank" rel="noreferrer">
                                                    Reti energetiche di trasporto e distribuzione</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-r/retrocommissioning.html"  target="_blank" rel="noreferrer">
                                                    Retrocommissioning</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-r/ricerca-di-sistema-elettrico.html"  target="_blank" rel="noreferrer">
                                                    Ricerca di Sistema Elettrico</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-r/rifiuti-urbani.html"  target="_blank" rel="noreferrer">
                                                    Rifiuti urbani</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-r/riscaldamento-globale.html"  target="_blank" rel="noreferrer">
                                                    Riscaldamento globale (Global Warming)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-r/riscaldamento-ventilazione-e-aria-condizionata-hvac.html"  target="_blank" rel="noreferrer">
                                                    Riscaldamento, Ventilazione e Aria Condizionata (HVAC) (Heating, Ventilation, and Air Conditioning)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-r/riserve.html"  target="_blank" rel="noreferrer">
                                                    Riserve</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-r/risparmi-annuali-incrementali-in-termini-di-efficienza-energetica.html"  target="_blank" rel="noreferrer">
                                                    Risparmi annuali incrementali in termini di efficienza energetica (Incremental Annual Savings)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-r/risparmi-cumulativi.html"  target="_blank" rel="noreferrer">
                                                    Risparmi cumulativi ( Cumulative Savings)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-r/risparmi-totali-annuali.html"  target="_blank" rel="noreferrer">
                                                    Risparmi totali annuali (Total Annual Savings)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-r/risparmio-energetico.html"  target="_blank" rel="noreferrer">
                                                    Risparmio energetico (Energy Conservation)</a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">S</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/satisficing.html"  target="_blank" rel="noreferrer">
                                                    Satisficing</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/servizi-ancillari-o-ausiliari.html"  target="_blank" rel="noreferrer">
                                                    Servizi ancillari o ausiliari</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/siape.html"  target="_blank" rel="noreferrer">
                                                    SIAPE</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/sistema-di-autobus-espresso.html"  target="_blank" rel="noreferrer">
                                                    Sistema di autobus espresso (Bus Rapid Transit) </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/sistema-elettrico-interconnesso.html"  target="_blank" rel="noreferrer">
                                                    Sistema elettrico Interconnesso (Bulk Power System)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/sistema-elettrico-nazionale.html"  target="_blank" rel="noreferrer">
                                                    Sistema Elettrico Nazionale</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/sistema-tariffario-avanzato-o-intelligente.html"  target="_blank" rel="noreferrer">
                                                    Sistema Tariffario Avanzato (o intelligente) ( Smart Rate Design)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/sistemi-di-gestione-tecnologica-ed-energetica-degli-edifici.html"  target="_blank" rel="noreferrer">
                                                    Sistemi di gestione tecnologica ed energetica degli edifici (Building Energy Management System - BEMS) </a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/sistemi-di-produzione-e-distribuzione-dell-acqua-calda-sanitaria.html"  target="_blank" rel="noreferrer">
                                                    Sistemi di produzione e distribuzione dell’acqua calda sanitaria (Hot Water Distribution Systems)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/sistemi-efficienti-di-utenza.html"  target="_blank" rel="noreferrer">
                                                    Sistemi Efficienti di Utenza</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/social-marketing.html"  target="_blank" rel="noreferrer">
                                                    Social Marketing</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/societa-elettriche-cooperative.html"  target="_blank" rel="noreferrer">
                                                    Società elettriche cooperative (Cooperative Electric Utility)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/societa-multiservizi.html"  target="_blank" rel="noreferrer">
                                                    Società Multiservizi</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/sostituzione-al-deterioramento.html"  target="_blank" rel="noreferrer">
                                                    Sostituzione  al deterioramento (Replace-on-Burnout)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/spillover.html"  target="_blank" rel="noreferrer">
                                                    Spillover</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/standards-minimi-di-efficienza-energetica.html"  target="_blank" rel="noreferrer">
                                                    Standards Minimi di Efficienza Energetica (Minimum Efficiency Standards)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/stoccaggio.html"  target="_blank" rel="noreferrer">
                                                    Stoccaggio</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-s/strategia-energetica-nazionale-sen.html"  target="_blank" rel="noreferrer">
                                                    Strategia energetica nazionale (SEN)</a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">T</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-t/tariffe-di-picco-critico.html"  target="_blank" rel="noreferrer">
                                                    Tariffe di picco critico (Critical Peak Pricing)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-t/tariffe-per-fasce-orarie.html"  target="_blank" rel="noreferrer">
                                                    Tariffe per fasce orarie (Time of Use Rates)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-t/tassa-sul-carbonio.html"  target="_blank" rel="noreferrer">
                                                    Tassa sul carbonio (Carbon tax)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-t/tasso-di-sostituzione-dei-beni-e-delle-attrezzature.html"  target="_blank" rel="noreferrer">
                                                    Tasso di sostituzione dei beni e delle attrezzature (Capital Stock Turnover Rate)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-t/tecnologia-emergente.html"  target="_blank" rel="noreferrer">
                                                    Tecnologia emergente (Emerging Technology)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-t/teleriscaldamento-o-teleraffrescamento.html"  target="_blank" rel="noreferrer">
                                                    Teleriscaldamento o Teleraffrescamento</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-t/transizione-energetica.html"  target="_blank" rel="noreferrer">
                                                    Transizione energetica</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-t/trasferimento-del-carico.html"  target="_blank" rel="noreferrer">
                                                    Trasferimento del carico (Load shifting)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-t/trasformatore-elettrico.html"  target="_blank" rel="noreferrer">
                                                    Trasformatore elettrico (Transformer)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-t/trasmissione-di-energia-elettrica.html"  target="_blank" rel="noreferrer">
                                                    Trasmissione di energia elettrica (Electricity Transmission)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-t/trasmittanza-termica.html"  target="_blank" rel="noreferrer">
                                                    Trasmittanza Termica</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-t/trasporto-multimodale.html"  target="_blank" rel="noreferrer">
                                                    Trasporto multimodale (Multimodal Transportation)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-t/turbocompressione.html"  target="_blank" rel="noreferrer">
                                                    Turbocompressione (Turbocharging)</a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">U</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-u/unita-di-climatizzazione-rooftop.html"  target="_blank" rel="noreferrer">
                                                    Unità di climatizzazione rooftop </a></li>
                                                </ul>
                            </li>
                                    <li>
                                <div className="mod-articles-category-group">V</div>
                                <ul>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-v/valutazione-degli-investimenti-in-conto-capitale-per-efficienza-energetica.html"  target="_blank" rel="noreferrer">
                                                    Valutazione degli investimenti in conto capitale per efficienza energetica</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-v/valutazione-del-potenziale-di-efficienza.html"  target="_blank" rel="noreferrer">
                                                    Valutazione del potenziale di efficienza (Efficiency Resource Assessment)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-v/valutazione-post-occupazione.html"  target="_blank" rel="noreferrer">
                                                    Valutazione post occupazione (Post-Occupancy Evaluation)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-v/veicolo-a-celle-a-combustibile.html"  target="_blank" rel="noreferrer">
                                                    Veicolo a celle a combustibile (Fuel Cell Vehicle)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-v/veicolo-bifuel-a-gas-di-petrolio-liquefatto.html"  target="_blank" rel="noreferrer">
                                                    Veicolo Bifuel a Gas di petrolio Liquefatto</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-v/veicolo-bifuel-a-gas-naturale.html"  target="_blank" rel="noreferrer">
                                                    Veicolo Bifuel a Gas naturale (Natural Gas Vehicle)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-v/veicolo-con-motore-flex.html"  target="_blank" rel="noreferrer">
                                                    Veicolo con motore Flex (Flexible Fuel Vehicle)</a></li>
                                                        <li><a className="mod-articles-category-title " href="https://www.efficienzaenergetica.enea.it/glossario-efficienza-energetica/lettera-v/veicolo-elettrico-ibrido-plug-in.html"  target="_blank" rel="noreferrer">
                                                    Veicolo elettrico ibrido Plug-in (Plug-in Hybrid-Electric Vehicle)</a></li>
                                                </ul>
                            </li>
                                </ul>
                    </div></div>

                            </p>
                            </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Glossario;