import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import kcom from "../assets/img/kcom.png";
import { Link } from 'react-router-dom';
import { Button} from 'design-react-kit';



function Kcom() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="/formazione_professionale">Formazione</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Piattaforma Multimediale KCOM
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Piattaforma Multimediale KCOM </h1>
                            <div className="row">
                                <p className="col-md-8">
                                K-COM è una piattaforma realizzata nei laboratori ENEA, presso il Centro Ricerche ENEA Casaccia, finalizzata alla comunicazione in tempo reale (sincrona), asincrona e ibrida basata sul modello "ENEA NetLesson", il sistema di video lezioni utilizzato disseminare le conoscenze all'utenza della Pubblica Amministrazione. <br/>
                                L'utenza finale sarà collegata da remoto in ambienti attrezzati con la "Learning Room" dell'ENEA.<br/>                                
                                I servizi offerti alle Regioni pilota saranno orientati allo scenario reale di riferimento sulle tematiche di interesse, classificate secondo i settori tecnologici, le tecnologie e gli attori del territorio.
                                </p>
                                <img className="col-md-4 mw-300 h-100" src={kcom} title="kcom" alt="kcom" />
                            </div>
                           
                         </div>
                        
                    </div>
                </section>
                <div className="text-center">
               <a href="http://www.kcom.enea.it" target="_blank" rel="noreferrer"><Button className="m-auto" color='primary'>Accedi</Button></a>
                </div>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Kcom;