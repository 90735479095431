import React, { Fragment } from "react";
import "../App.css";
//import sprite2 from "../assets/svg/sprite.svg";
import { Link } from "react-router-dom";
//import {Table } from 'design-react-kit';


function ListaImmobili(props) {
  return (
    <div>
            <div className="">
              <div
                className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal card-teaser-block-3"
              >
                <div className="card  rounded">

                  <div className="card-body">
                    <h5 className="card-title pb-3">Lista immobili</h5>
                    <div className="card-text text-sans-serif">
                        <table className="table  w-100">
                          <thead>
                            <tr>
                              <th >#</th>
                              <th scope="col">Comune</th>
                              <th scope="col">Indirizzo</th>
                              <th scope="col">Sezione</th>
                              <th scope="col">Foglio</th>
                              <th scope="col">Particella</th>
                              <th scope="col">Subalterno</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                          {props.dati.map((item, i) => {
                            return (
                              <Fragment>
                                <tr key={i}>
                                  <td className="toggler">
                                  {item.id}
                                  </td>
                                  <td>{item.codcat}</td>
                                  <td>{item.indirizzo},{item.civico}</td>
                                  <td>{item.foglio}</td>
                                  <td>-</td>
                                  <td>{item.particella}</td>
                                  <td>{item.subalterno}</td>
                                  <td>
                                    <Link className="btn btn-primary" to={`/cassetto/${item.id}`} >dettagli</Link>
                                  </td>
                                </tr>

                              </Fragment>
                            );
                          })}
                          </tbody>
                        </table>

                    </div>
                  </div>
                </div>

              </div>
            </div>
        </div>
  );
};

export default ListaImmobili;
