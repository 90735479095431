import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import harp from "../assets/img/harp.png";
import "../App.css";
import { Link } from 'react-router-dom';


function Harp() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                               <Link to="/software">Software</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                HARPa
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>HARPa</h1>
                            <div className="row">
                                <p className="col-md-8">
                                    HARPa è l'applicazione che stima la classe energetica dei generatori di calore obsoleti, quindi sprovvisti di etichetta energetica. Si basa sui regolamenti europei 811 e 812 del 2013 ed è dedicata agli impianti autonomi di riscaldamento e acqua calda sanitaria a uso domestico.<br/>
                                    Con pochi e semplici dati, HARPa calcola la classe energetica del vecchio generatore e la esprime in forma di etichetta.<br/>
                                    HARPa è frutto del progetto europeo HARP (Heating Appliances Retrofit Planning), finanziato dal programma Horizon 2020. Questa versione è l'adattamento curato dai componenti italiani di HARP: ENEA, Assotermica ed Eurac Research.<br/><br/>
                                    Per usare l'applicativo clicca <a href="https://harpa.enea.it" target={"_blank"} rel="noreferrer">qui</a><br/>
                                    Per approfondire clicca <a href="https://harpa.enea.it/static/media/D3.1-Labelling-methodologies-and-validation-report.fb1f30641344cfeb02a4.pdf" target={"_blank"} rel="noreferrer">qui</a> e <a href="https://www.mdpi.com/1996-1073/14/21/7044" target={"_blank"} rel="noreferrer">qui</a><br/>
                                    Per informazioni sul progetto HARP clicca <a href="https://cordis.europa.eu/project/id/847049/it" target={"_blank"} rel="noreferrer">qui</a><br/>
                                </p>
                                <img className="col-md-4 mw-300 h-100" src={harp} title="harp" alt="harp" />
                            </div>
                           
                         </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Harp;