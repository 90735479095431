import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import { Link } from 'react-router-dom';
import pub2 from "../assets/img/pub2.png";
import pub1 from "../assets/img/pub1.png";
import news2 from "../assets/img/news2.jpg";
import raee from "../assets/img/raee.png";
import sprite2 from "../assets/svg/sprite.svg";
import { LinkListItem, LinkList } from 'design-react-kit';



function Pubblicazioni() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Pubblicazioni
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div className="px-lg-4 py-lg-2 col-lg-8">
                            <h1>Pubblicazioni</h1>
                                <div className="row mt-4">
                                <div className="col-xs-12 col-md-6">
                                    <div className="card-wrapper">
                                    <div className="card card-img no-after rounded shadow">
                                        <div className="img-responsive-wrapper">
                                        <div className="img-responsive img-responsive-panoramic">
                                            <figure className="img-wrapper">
                                            <img src={news2} title="news2" alt="news2" />
                                            </figure>
                                        </div>
                                        </div>
                                        <div className="card-body">
                                        <h5 className="card-title">
                                        Fonderie - Quaderni dell'efficienza energetica 
                                        </h5>
                                        <div className="category-top">
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-calendar" }></use>
                                                </svg>
                                                <span> 21 Marzo 2022</span>
                                            </div>
                                        <p className="card-text">
                                        La pubblicazione sulle Fonderie è il terzo volume della collana “Quaderni dell’efficienza energetica” realizzato da ENEA nell’ambito dell’Accordo di Programma MiSE (ora in capo al MiTE) - ENEA: “Ricerca di Sistema Elettrico 2019 – 2021”.
                                        </p>
                                        <div className="mt-5">
                                        <a className="read-more" href="https://www.efficienzaenergetica.enea.it/pubblicazioni/fonderie-quaderni-dell-efficienza-energetica.html" target="_blank" rel="noreferrer">
                                            <span className="text">Leggi tutto</span>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-arrow-right" }></use>
                                            </svg>
                                        </a>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div> 
                                <div className="col-xs-12 col-md-6">
                                    <div className="card-wrapper">
                                    <div className="card card-img no-after rounded shadow">
                                        <div className="img-responsive-wrapper">
                                        <div className="img-responsive img-responsive-panoramic">
                                            <figure className="img-wrapper">
                                            <img src={pub2} title="news2" alt="pub2" />
                                            </figure>
                                            
                                        </div>
                                        </div>
                                        <div className="card-body">
                                        <h5 className="card-title">
                                        Rapporto Annuale sulla Certificazione Energetica degli Edifici 2021 
                                        </h5>
                                        <div className="category-top">
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-calendar" }></use>
                                                </svg>
                                                <span> 16 Dicembre 2021</span>
                                            </div>
                                        <p className="card-text">
                                        La seconda edizione del Rapporto annuale sulla Certificazione Energetica degli Edifici, risultato della collaborazione tra ENEA e CTI (Comitato Termotecnico Italiano), fotografa l’evoluzione delle prestazioni energetiche del parco edilizio nazionale allo stato 2020.
                                        </p>
                                        <div className="mt-5">
                                        <a className="read-more" href="https://www.efficienzaenergetica.enea.it/pubblicazioni/rapporto-annuale-sulla-certificazione-energetica-degli-edifici/rapporto-annuale-sulla-certificazione-energetica-degli-edifici-2021.html" target="_blank" rel="noreferrer">
                                            <span className="text">Leggi di più</span>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-arrow-right" }></use>
                                            </svg>
                                        </a>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="card-wrapper">
                                    <div className="card card-img no-after rounded shadow">
                                        <div className="img-responsive-wrapper">
                                        <div className="img-responsive img-responsive-panoramic">
                                            <figure className="img-wrapper">
                                            <img src={raee} title="raee" alt="raee" />
                                            </figure>
                                        </div>
                                        </div>
                                        <div className="card-body">
                                        <h5 className="card-title">
                                        Rapporto Annuale sull'EFFICIENZA ENERGETICA 2021 
                                        </h5>
                                        <div className="category-top">
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-calendar" }></use>
                                                </svg>
                                                <span> 01 Dicembre 2021  </span>
                                            </div>
                                        <p className="card-text">
                                        La decima edizione del Rapporto Annuale sull’Efficienza Energetica dell’ENEA analizza lo stato e l’evoluzione dell’attuazione delle misure per l’efficienza energetica a livello nazionale, valutando le performance rispetto ai risultati ottenuti nel 2020 a livello di politiche e di strumenti attuativi.
                                        </p>
                                        <div className="mt-5">
                                        <a className="read-more" href="https://www.efficienzaenergetica.enea.it/pubblicazioni/raee-rapporto-annuale-sull-efficienza-energetica/rapporto-annuale-sull-efficienza-energetica-2022.html" target="_blank" rel="noreferrer">
                                            <span className="text">Leggi di più</span>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-arrow-right" }></use>
                                            </svg>
                                        </a>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="card-wrapper">
                                    <div className="card card-img no-after rounded shadow">
                                        <div className="img-responsive-wrapper">
                                        <div className="img-responsive img-responsive-panoramic">
                                            <figure className="img-wrapper">
                                            <img src={pub1} title="pub1" alt="pub1" />
                                            </figure>
                                            
                                        </div>
                                        </div>
                                        <div className="card-body">
                                            
                                        <h5 className="card-title">
                                        Le detrazioni fiscali per l’efficienza energetica e l’utilizzo delle fonti rinnovabili di energia negli edifici esistenti - Rapporto Annuale 2021 (Dati 2020) 
                                        </h5>
                                        <div className="category-top">
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-calendar" }></use>
                                                </svg>
                                                <span> 01 Dicembre 2021   </span>
                                            </div>
                                        <p className="card-text">
                                        A circa un anno di attuazione del superecobonus 110%, a settembre 2021 sono oltre 40.000 gli interventi ovviati o già conclusi, per oltre 6 miliardi di investimenti ammessi a finanziamento (di cui circa 4,3 già realizzati), cui corrispondono circa 1.300 GWh/anno di risparmio energetico.
                                        </p>
                                        <div className="mt-5">
                                        <a className="read-more" href="https://www.efficienzaenergetica.enea.it/pubblicazioni/rapporto-annuale-detrazioni-fiscali/rapporto-annuale-detrazioni-fiscali-2021.html" target="_blank" rel="noreferrer">
                                            <span className="text">Leggi di più</span>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-arrow-right" }></use>
                                            </svg>
                                        </a>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                            <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                                <div className="row">
                                <div className="col text-center">
                                    <a href="https://www.efficienzaenergetica.enea.it/pubblicazioni/archivio-pubblicazioni.html" target="_blank" rel="noreferrer" className="btn btn-primary mt-2 w-100">Tutte le pubblicazioni</a>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="pt-5 pt-lg-2 col-lg-4">
                        <LinkList className="footer-list clearfix">
                            <LinkListItem
                            header
                            tag="h3"
                            >
                            FILTRI DI RICERCA
                            </LinkListItem>
                            <LinkListItem href="https://www.efficienzaenergetica.enea.it/pubblicazioni/raee-rapporto-annuale-sull-efficienza-energetica.html" className="mt-4" target="_blank" rel="noreferrer">
                            <span className="text-capitalize">
                                 <svg className="icon icon-primary">
                                    <use href={ sprite2 + "#it-chevron-right" }></use>
                                </svg>
                                RAEE - Rapporto annuale sull'efficienza energetica
                            </span>
                            </LinkListItem>
                            <LinkListItem href="https://www.efficienzaenergetica.enea.it/pubblicazioni/rapporto-annuale-detrazioni-fiscali.html" className="mt-1" target="_blank" rel="noreferrer">
                            <span>
                                 <svg className="icon icon-primary">
                                    <use href={ sprite2 + "#it-chevron-right" }></use>
                                </svg>
                             Rapporto annuale sulle detrazioni fiscali
                            </span>
                            </LinkListItem>
                            <LinkListItem href="https://www.efficienzaenergetica.enea.it/pubblicazioni/rapporto-annuale-sulla-certificazione-energetica-degli-edifici.html" className="mt-1" target="_blank" rel="noreferrer">
                            <span>
                                 <svg className="icon icon-primary">
                                    <use href={ sprite2 + "#it-chevron-right" }></use>
                                </svg>
                                Rapporto annuale sulla certificazione energetica degli edifici
                            </span>
                            </LinkListItem>
                            <LinkListItem href="https://www.efficienzaenergetica.enea.it/pubblicazioni/archivio-pubblicazioni.html" className="mt-1" target="_blank" rel="noreferrer">
                            <span>
                                <svg className="icon icon-primary">
                                    <use href={ sprite2 + "#it-chevron-right" }></use>
                                </svg>
                                Tutte le Pubblicazioni
                            </span>
                            </LinkListItem>
                        </LinkList>
                        </div>
                    </div>
                    <nav className="pagination-wrapper justify-content-center mt-3 d-none" aria-label="Navigazione centrata">
                        <ul className="pagination">
                            <li className="page-item disabled">
                            <a className="page-link" href="/#" tabindex="-1" aria-hidden="true">
                                <svg className="icon icon-primary"><use href={ sprite2 + "#it-chevron-left" }></use></svg>
                                <span className="sr-only">Pagina precedente</span>
                            </a>
                            </li>
                            <li className="page-item">
                            <a className="page-link" href="/#" aria-current="page">
                                <span className="d-inline-block d-sm-none">Pagina </span>1
                            </a>
                            </li>
                            <li className="page-item"><a className="page-link" href="/#">2</a></li>
                            <li className="page-item"><a className="page-link" href="/#">3</a></li>
                            <li className="page-item">
                            <a className="page-link" href="/#">
                                <span className="sr-only">Pagina successiva</span>
                                <svg className="icon icon-primary"><use href={ sprite2 + "#it-chevron-right" }></use></svg>
                            </a>
                            </li>
                        </ul>
                        </nav>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Pubblicazioni;