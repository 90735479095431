import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import test4ecasa from "../assets/img/test4ecasa.png";
import "../App.css";
import { Link } from 'react-router-dom';


function Test4ecasa() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                               <Link to="/software">Software</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                              Test4Ecasa 
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Test4Ecasa </h1>
                            <div className="row">
                                <p className="col-md-8">
                                4ECAsa è un test interattivo che permette a tutti di confrontare con pochi click i consumi della propria abitazione con quelli di un edificio di recente costruzione con le medesime caratteristiche e ubicato nella stessa località, ma edificato con criteri di alta efficienza energetica.<br/>
                                 Il test suggerisce inoltre i passi da compiere per rendere l’abitazione più efficiente nel rispetto dell’ambiente. Attraverso la registrazione al portale , il cittadino contribuirà a fornire a ENEA dati utili sui consumi reali e riceverà una sintesi qualitativa del potenziale risparmio energetico e economico in virtù dei lavori di riqualificazione ipotizzati. 4ECAsa è stato realizzato dall’ENEA nell’ambito del progetto europeo REQUEST2ACTION,  che ha portato allo sviluppo di un portale, <a href="http://www.portale4e.it/" target={"_blank"} rel="noreferrer">PORTALE4E</a>,  dedicato all’efficienza energetica degli edifici esistenti.<br/>
                                  Il PORTALE4E intende supportare proprietari, progettisti, operatori, investitori e pianificatori nelle azioni e nelle decisioni relative al recupero edilizio.<br/> 
                                 Contiene informazioni su metodologie, software e politiche nazionali nel settore.<br/>
                                 Per utilizzare l'applicativo clicca <a href="http://www.portale4e.it/4Ecasa/gioco1.aspx" target={"_blank"} rel="noreferrer">qui</a> 
                                </p>
                                <img className="col-md-4 mw-300 h-100" src={test4ecasa} title="test4ecasa" alt="test4ecasa" />
                            </div>
                           
                         </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Test4ecasa;