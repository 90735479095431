import React, { useEffect, useState } from "react";
import L from "leaflet";
import { MapContainer, GeoJSON, useMap,TileLayer } from "react-leaflet";
// import { BasemapLayer, FeatureLayer } from "react-esri-leaflet";
import axios from "axios";

// @constants
const GEOSERVER = '//pnpe2.enea.it/geoserver/ows';
const MyData = (props) => {
  const [data, setData] = useState();
  const mappa = useMap();
  mappa.dragging.disable();
  mappa.touchZoom.disable();
  mappa.doubleClickZoom.disable();
  mappa.scrollWheelZoom.disable();
  // mappa.zoomControl.disable();
  // mappa.removeControl(mappa.zoomControl);





  useEffect(() => {

    let codcat=props.daticatastali.codcat
    let foglio=props.daticatastali.foglio
    let particella=props.daticatastali.particella

    const REQUEST_PARAMS = {
      service : 'WFS',
      version : '2.0',
      request : 'GetFeature',
      typeName : 'gisdb:edifici_lazio',
      CQL_FILTER: "cod_comune='"+codcat+"' and foglio='"+foglio+"' and particella='"+particella+"'",
      outputFormat: 'application/json',
      format_options : 'callback:getJson',
      SrsName : 'EPSG:4326'
    };
    axios.get(GEOSERVER, { params: REQUEST_PARAMS })
    .then((response)=>{
      console.log(response.data)
      setData(response.data);
        //setDati({'datiJson':response.data,'idRegioneSel':0,'annoSel':0})
    })
    .finally(()=>{
      //  setLoading(false);

      }
    )
    .catch((e)=>{
      //console.log(e)
    })



  }, []);





  function style(feature) {
    return {
      weight: 0,
      opacity: 1,
      color: '#06c',
      fillOpacity: .7
    };
  }



  if (data) {
    // These next 3 lines purely for debuggins:
    const geojsonObject = L.geoJSON(data);

  //  mappa.panTo(geojsonObject.getBounds().getCenter());
    //        mappa.zoomIn(13);

    mappa.flyTo(geojsonObject.getBounds().getCenter(), 18, {
            animate: true,
            duration: 1
    });

    // mappa.on('zoomend', function () {
    //   alert();
    //
    // });

    //mappa.fitBounds(geojsonObject.getBounds());
    // end debugging


    return <GeoJSON data={data}  style={style}/>

  } else {
    return null;
  }


};


const Map = (props) => {
  if(props.daticatastali.codcat){
    return (
      <MapContainer center={[41.68, 12.78]} zoom={8} id="mappaimmobile">
{
//      <TileLayer
//        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//      />
      }

      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
      />

      <MyData daticatastali={props.daticatastali}/>

    </MapContainer>
    );
  }else{
    return (
      <MapContainer center={[41.68, 12.78]} zoom={5} id="mappaimmobile">
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
      />
{ //     <FeatureLayer url={'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'} />
}
    </MapContainer>
    );
  }
};

export default Map;
