import React, { Fragment,useEffect, useState } from "react";

import "../App.css";
//import sprite2 from "../assets/svg/sprite.svg";
// import { Link } from "react-router-dom";
import {Button,Modal,ModalBody,ModalFooter,Row,Col } from 'design-react-kit';
import axios from "axios";
import sprite2 from "../assets/svg/sprite.svg";
// import Map from "./Mappaimmobile.js";



function Bonus(props) {


    const [data, setData] = useState(props.data);
    const [openApe, toggleModalApe] = useState(false);
    const [contentModalApe, setContentModalApe] = useState('');
    const toggleApe = () => toggleModalApe(!openApe);

    const ContentApe= (item) => {
    //  console.log(item)
      return(
        <div>
          <article id="anagraficaimmobile" className="it-page-section anchor-offset">

              <div className="card-wrapper card-teaser-wrapper">
                  <div className="card  shadow mt-3 rounded">
                  <div className="card-body">

                      <h5 className="card-title">
                          <svg className="icon mr-2">
                            <use href={ sprite2 + "#it-camera" }></use>
                          </svg>
                          CODICE CPID: {item.cpid}

                      </h5>
                      <div className="card-text mt-2">

                          <Row  className="mb-3">

                            <Col xs='6'>
                                <dl className='row'>
                                  <dt className='col-sm-5'>Data fine lavori</dt>
                                  <dd className='col-sm-7'>
                                  { (item.datafinelavori instanceof Date) ? item.datafinelavori.toLocaleDateString() : new Date(item.datafinelavori).toLocaleDateString() }
                                  </dd>
                                  <dt className='col-sm-5'>Risparmio stimato</dt>
                                  <dd className='col-sm-7'>
                                  { (item.risparmiostimato.toLocaleString('it-IT')) } €
                                  </dd>
                                  <dt className='col-sm-5'>Detrazione massima totale</dt>
                                  <dd className='col-sm-7'>
                                  { (item.detrazionemassimatot.toLocaleString('it-IT')) } €
                                  </dd>
                                  <dt className='col-sm-5'>Detrazione totale</dt>
                                  <dd className='col-sm-7'>
                                  { (item.detrazionetot.toLocaleString('it-IT')) } €
                                  </dd>
                                  <dt className='col-sm-5'>Detrazione fruibile totale</dt>
                                  <dd className='col-sm-7'>
                                  { (item.detrazionefruibiletot.toLocaleString('it-IT')) } €
                                  </dd>
                                  <dt className='col-sm-5'>Detrazione complessiva</dt>
                                  <dd className='col-sm-7'>
                                  { (item.detrazionecomplessivatot.toLocaleString('it-IT')) } €
                                  </dd>


                                </dl>

                            </Col>
                            <Col xs='6'>

                                <dl className='row'>
                                  <dt className='col-sm-5'>Regione</dt>
                                  <dd className='col-sm-7'>
                                    {item.regione??'-'}
                                  </dd>
                                  <dt className='col-sm-5'>Indirizzo</dt>
                                  <dd className='col-sm-7'>
                                    {item.indirizzoimmobile??'-'}, {item.civicoimmobile??'-'} - {item.capimmobile??'-'} {item.comuneubicazione??'-'} ({item.provinciaubicazione??'-'})
                                  </dd>

                                  <dt className='col-sm-5'>Anno costruzione</dt>
                                  <dd className='col-sm-7'>
                                    {item.annocostruzione??'-'}
                                  </dd>

                                </dl>

                            </Col>
                            </Row>
                            <h5>Numero interventi</h5>

                            <Row  className="mb-3">

                            <Col xs='6'>
                              <dl className='row'>
                                <dt className='col-sm-8'>Pareti verticali</dt>
                                <dd className='col-sm-4'>
                                  {item.numeroparetiverticali??0}
                                </dd>
                                <dt className='col-sm-8'>Pareti orizzontali</dt>
                                <dd className='col-sm-4'>
                                  {item.numeroparetiorizzontali??0}
                                </dd>

                                <dt className='col-sm-8'>Pavimenti soffitti</dt>
                                <dd className='col-sm-4'>
                                  {item.numeropavimentisoffitti??0}
                                </dd>
                                <dt className='col-sm-8'>Solare termico</dt>
                                <dd className='col-sm-4'>
                                  {item.numerosolaretermico??0}
                                </dd>
                                <dt className='col-sm-8'>Infissi</dt>
                                <dd className='col-sm-4'>
                                  {item.numeroinfissi??0}
                                </dd>
                                <dt className='col-sm-8'>Coperture</dt>
                                <dd className='col-sm-4'>
                                  {item.numerocoperture??0}
                                </dd>
                                <dt className='col-sm-8'>Caldaie condensazione</dt>
                                <dd className='col-sm-4'>
                                  {item.numerocaldaiecondensazione??0}
                                </dd>
                                <dt className='col-sm-8'>Generatore aria calda</dt>
                                <dd className='col-sm-4'>
                                  {item.numerogeneratoreariacalda??0}
                                </dd>
                              </dl>
                            </Col>
                            <Col xs='6'>
                              <dl className='row'>
                                <dt className='col-sm-8'>Pompe di calore</dt>
                                <dd className='col-sm-4'>
                                  {item.numeropompedicalore??0}
                                </dd>
                                <dt className='col-sm-8'>Sistema ibrido</dt>
                                <dd className='col-sm-4'>
                                  {item.numerosistemaibrido??0}
                                </dd>

                                <dt className='col-sm-8'>Microgeneratori</dt>
                                <dd className='col-sm-4'>
                                  {item.numeromicrogeneratori??0}
                                </dd>
                                <dt className='col-sm-8'>Impianti a biomassa</dt>
                                <dd className='col-sm-4'>
                                  {item.numeroimpiantibiomassa??0}
                                </dd>
                                <dt className='col-sm-8'>Building automation</dt>
                                <dd className='col-sm-4'>
                                  {item.numerobuildingautomation??0}
                                </dd>
                                <dt className='col-sm-8'>Scaldacqua</dt>
                                <dd className='col-sm-4'>
                                  {item.numeroscaldacqua??0}
                                </dd>
                                <dt className='col-sm-8'>Altro intervento</dt>
                                <dd className='col-sm-4'>
                                  {item.numeroaltrointervento??0}
                                </dd>

                              </dl>

                            </Col>
                          </Row>

                      </div>
                  </div>
                </div>
              </div>

          </article>

        </div>

        )
    }
    const openModalApe = (item) => {
       setContentModalApe(ContentApe(item));
       toggleModalApe(true);
     };




  useEffect(() => {


    if(props.data){

      let URL='//pnpe2.enea.it/api/data/pnpe2_ecobonus1?or=(cpid.eq.'+props.data.join(',cpid.eq.')+')'
        axios.get(
          URL
        )
        .then((response)=>{
          setData(response.data)

          // let temp=response.data
          //
          // setDatiImmobile({indirizzo:temp[0].indirizzo,civico:temp[0].civico,codcat:temp[0].codcat,foglio:temp[0].foglio,particella:temp[0].particella,subalterno:temp[0].subalterno})
          //
          // let daticatastali={codcat:temp[0].codcat,foglio:temp[0].foglio,particella:temp[0].particella,subalterno:temp[0].subalterno}
          // getApeCpid(daticatastali)
          // setDatoCatastale({codcat:temp[0].codcat,foglio:temp[0].foglio,particella:temp[0].particella,subalterno:temp[0].subalterno})
            //setDati({'datiJson':response.data,'idRegioneSel':0,'annoSel':0})
        })
        .finally(()=>{
          //  setLoading(false);
          }
        )
        .catch((e)=>{
          //console.log(e)
        })

    }


  }, [props.data]);



  if (data) {
    return (
      <div>
              <div className="">

                      <p className="card-text text-sans-serif">
                          <table className="table  w-100">
                            <thead>
                              <tr>
                                <th scope="col">Codice CPID</th>
                                <th scope="col">Valido da</th>
                                <th scope="col">Comma</th>
                                <th scope="col">Risparmio stimato</th>
                                <th scope="col"></th>

                              </tr>
                            </thead>
                            <tbody>
                            {data.map((item, i) => {
                              return (
                                <Fragment>
                                  <tr key={i}>
                                    <td>{item.cpid}</td>
                                    <td>{ (item.datafinelavori instanceof Date) ? item.datafinelavori.toLocaleDateString() : new Date(item.datafinelavori).toLocaleDateString() }</td>
                                    <td>{item.comma}</td>
                                    <td>{item.risparmiostimato.toLocaleString('it-IT')} €</td>

                                    <td>
                                    <Button color='primary' onClick={() => openModalApe(item)} className='mr-3'  size='xs'>
                                      Dettagli
                                    </Button>
                                    </td>
                                  </tr>

                                </Fragment>
                              );
                            })}
                            </tbody>
                          </table>
                          <Modal fade centered isOpen={openApe} toggleApe={toggleApe} labelledBy='esempio14' size='lg'>

                            <ModalBody>
                              {contentModalApe}
                            </ModalBody>
                            <ModalFooter>
                              <Button color='secondary' onClick={toggleApe} size='xs'>
                                Chiudi
                              </Button>
                              <Button color='primary' onClick={toggleApe} size='xs'>
                                Salva modifiche
                              </Button>
                            </ModalFooter>
                          </Modal>
                      </p>
                    </div>
                  </div>

    )
  } else {
    return null;
  }
};

export default Bonus;
