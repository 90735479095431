import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import { Link } from 'react-router-dom';
import { LinkList, LinkListItem } from 'design-react-kit';
import sprite2 from "../assets/svg/sprite.svg";

function Fondonazionale() {
  return (
    <div>
    <HeaderTop />
          <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="servizi">Servizi</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Fondo Nazionale Efficienza Energetica
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Il Fondo Nazionale Efficienza Energetica</h1>
                            <div className="row">
                                Il Fondo Nazionale per l’efficienza energetica favorisce gli interventi necessari per il raggiungimento degli obiettivi nazionali di efficienza energetica, promuovendo il coinvolgimento di istituti finanziari, nazionali e comunitari, e investitori privati sulla base di un’adeguata condivisione dei rischi. 
                           </div>
                            <div className="row border-top row-column-border row-column-menu-left">
                                <aside className="col-lg-4">
                                    <div className="sticky-wrapper navbar-wrapper">
                                    <nav
                                        className="navbar it-navscroll-wrapper it-top-navscroll navbar-expand-lg"
                                    >
                                        <button
                                        className="custom-navbar-toggler"
                                        type="button"
                                        aria-controls="navbarNav"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                        data-target="#navbarNav"
                                        >
                                        <span className="it-list"></span>Sezioni
                                        </button>
                                        <div className="navbar-collapsable" id="navbarNav">
                                        <div className="overlay"></div>
                                        <div className="close-div sr-only">
                                            <button className="btn close-menu" type="button">
                                            <span className="it-close"></span>Chiudi
                                            </button>
                                        </div>
                                        <a className="it-back-button" href="/#">
                                            <svg className="icon icon-sm icon-primary align-top">
                                            <use xlinkhref="/assets/bootstrap-italia/dist/svg/sprite.svg#it-chevron-left"></use>
                                            </svg>
                                            <span>Torna indietro</span></a
                                        >
                                        <div className="menu-wrapper">
                                            <div className="link-list-wrapper menu-link-list">
                                            <h3 className="no_toc">Sezioni</h3>
                                            <ul className="link-list">
                                                <li className="nav-item">
                                                <a className="nav-link" href="#descrizione"
                                                    ><span>Descrizione</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="#normative"
                                                    ><span>Normativa</span></a
                                                >
                                                </li>
                                                
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </nav>
                                    </div>
                                </aside>
                                <section className="col-lg-8 it-page-sections-container">
                                    <article id="descrizione" className="it-page-section anchor-offset">
                                    <h4>Descrizione</h4>
                                    <p className="text-serif">
                                    Il Fondo Nazionale per l’efficienza energetica, istituito  presso il Ministero dello sviluppo economico (articolo 15, comma 1, del decreto legislativo 4 luglio 2014, n. 102), è disciplinato dal  <a href="https://www.gazzettaufficiale.it/eli/id/2018/03/06/18A01498/sg" target="_blank" rel="noreferrer">decreto interministeriale 22 dicembre 2017</a>. <br/>

                                    ll Fondo sostiene gli interventi di efficienza energetica realizzati dalle imprese, ivi comprese le ESCO, e dalla Pubblica Amministrazione, su immobili, impianti e processi produttivi. Nello specifico gli interventi sostenuti devono riguardare:
                                    <ul>
                                        <li>la riduzione dei consumi di energia nei processi industriali,</li>
                                        <li>la realizzazione e l’ampliamento di reti per il teleriscaldamento,</li>
                                        <li>l’efficientamento di servizi ed infrastrutture pubbliche, inclusa l’illuminazione pubblica;</li>
                                        <li>la riqualificazione energetica degli edifici.</li>
                                    </ul>
                                    Il Fondo ha una natura rotativa e si articola in due sezioni che operano per:
                                    <ul>
                                        <li>la concessione di garanzie su singole operazioni di finanziamento</li>
                                        <li>l’erogazione di finanziamenti a tasso agevolato.</li>
                                    </ul>
                                    Le risorse finanziarie stanziate per l'incentivo ammontano a 310 milioni di euro, così suddivise:
                                    <ul>
                                        <li>30% garanzie</li>
                                        <li>70% finanziamenti agevolati.</li>
                                    </ul>
                                    La sezione garanzie prevede inoltre una riserva del 30% per gli interventi riguardanti reti o impianti di teleriscaldamento, mentre il 20% delle risorse stanziate per la concessione di finanziamenti è riservata alla PA. <br/>
                                    Le agevolazioni concesse alle imprese sono cumulabili con agevolazioni contributive o finanziarie previste da altre normative comunitarie, nazionali e regionali nel limite del Regolamento de minimis laddove applicabile, o entro le intensità di aiuto massime consentite dalla vigente normativa dell’Unione Europea in materia di aiuti di Stato. <br/>
                                    Per quanto riguarda le agevolazioni concesse alla Pubblica Amministrazione, esse sono cumulabili con altri incentivi, nei limiti di un finanziamento complessivo massimo pari al 100 per cento dei costi ammissibili. <br/>
                                    La <a href="https://www.invitalia.it/cosa-facciamo/rafforziamo-le-imprese/fnee" target="_blank" rel="noreferrer">gestione del Fondo</a> è affidata ad Invitalia sulla base di apposita convenzione con il Ministero dello sviluppo economico e il Ministero dell’ambiente e della tutela del territorio e del mare. 

                                    </p>
                                    
                                    </article>
                                    <hr></hr>
                                    <article id="normative" className="it-page-section anchor-offset mt-5">
                                    <h4>Normativa</h4>
                                    <p className="text-serif">
                                    Questa sezione contiene la normativa inerente il  FNEE, l’ incentivo che sostiene la realizzazione di interventi finalizzati a garantire il raggiungimento degli obiettivi nazionali di efficienza energetica. 
                                        <LinkList>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://www.mise.gov.it/index.php/it/normativa/decreti-interministeriali/2039729-decreto-interministeriale-5-aprile-2019-fondo-nazionale-per-l-efficienza-energetica-modalita-operative-per-la-presentazione-delle-domande-di-agevolazione" target="_blank" rel="noreferrer">Decreto interministeriale 5 aprile 2019</a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="http://www.gazzettaufficiale.it/eli/id/2018/03/06/18A01498/sg" target="_blank" rel="noreferrer">Decreto interministeriale 22 dicembre 2017 (GU n.54 del 6 marzo 2018) </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://invitaliacdn.azureedge.net/-/media/invitalia/documenti/rafforziamo-le-imprese/fnee/fnee-decreto-legislativo-4-luglio-2014-n-102.pdf?la=it-it&hash=4CF3E4F230F7EFC2FA7AC9EDF7EFCE950DCF859F" target="_blank" rel="noreferrer">Decreto 4 luglio 2014, n.102 (art. 15)</a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://invitaliacdn.azureedge.net/-/media/invitalia/documenti/rafforziamo-le-imprese/fnee/regolamento-ue-1047_2013---de-minimis.pdf?la=it-it&hash=C9BE8A52B15CB33F0B44AA497B1E86146A7B79FA" target="_blank" rel="noreferrer">Regolamento UE 1047/2013 - De Minimis </a></span>
                                            </LinkListItem>
                                            <LinkListItem>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-pa" }></use>
                                            </svg>
                                            <span><a href="https://invitaliacdn.azureedge.net/-/media/invitalia/documenti/rafforziamo-le-imprese/fnee/regolamento-ue-651_2014.pdf?la=it-it&hash=AA70E4990CFD886B4A64F4DB9F78687E87D65493" target="_blank" rel="noreferrer">Regolamento UE 651/2014</a></span>
                                            </LinkListItem>
                                        </LinkList>
                                    </p>
                                    <p className="text-serif">Per eventuali aggiornamenti consultare la sezione NORMATIVA al seguente <a href="https://www.invitalia.it/cosa-facciamo/rafforziamo-le-imprese/fnee/normativa" target="_blank" rel="noreferrer">link</a></p>
                                    </article>
                                </section>
                                </div>

                            </div>

                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Fondonazionale;
