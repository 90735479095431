import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import { Link } from 'react-router-dom';

function Prepac() {
  return (
    <div>
    <HeaderTop />
          <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                <Link to="servizi">Servizi</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Prepac
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Prepac</h1>
                            <div className="row">
                                <div className="col-md-9">
                                Programma di Riqualificazione Energetica degli edifici della Pubblica Amministrazione Centrale.
                                </div>
                                <div className="col-md-3">
                                    <a className="nav-link" href="https://www.mise.gov.it/images/stories/documenti/linee_guida_prepac_12_luglio_2017.pdf" target="_blank" rel="noreferrer">
                                        <span class="btn btn-primary text-white">Linee Guida</span>
                                    </a>
                                </div>
                           </div>
                            <div className="row border-top row-column-border row-column-menu-left">
                                <aside className="col-lg-4">
                                    <div className="sticky-wrapper navbar-wrapper">
                                    <nav
                                        className="navbar it-navscroll-wrapper it-top-navscroll navbar-expand-lg"
                                    >
                                        <button
                                        className="custom-navbar-toggler"
                                        type="button"
                                        aria-controls="navbarNav"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                        data-target="#navbarNav"
                                        >
                                        <span className="it-list"></span>Sezioni
                                        </button>
                                        <div className="navbar-collapsable" id="navbarNav">
                                        <div className="overlay"></div>
                                        <div className="close-div sr-only">
                                            <button className="btn close-menu" type="button">
                                            <span className="it-close"></span>Chiudi
                                            </button>
                                        </div>
                                        <a className="it-back-button" href="/#">
                                            <svg className="icon icon-sm icon-primary align-top">
                                            <use xlinkhref="/assets/bootstrap-italia/dist/svg/sprite.svg#it-chevron-left"></use>
                                            </svg>
                                            <span>Torna indietro</span></a
                                        >
                                        <div className="menu-wrapper">
                                            <div className="link-list-wrapper menu-link-list">
                                            <h3 className="no_toc">Sezioni</h3>
                                            <ul className="link-list">
                                                <li className="nav-item">
                                                <a className="nav-link" href="#descrizione"
                                                    ><span>Descrizione</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="https://www.gse.it/servizi-per-te/efficienza-energetica/prepac" target="_blank" rel="noreferrer"
                                                    ><span>Approfondimenti</span></a
                                                >
                                                </li>
                                                
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </nav>
                                    </div>
                                </aside>
                                <section className="col-lg-8 it-page-sections-container">
                                    <article id="descrizione" className="it-page-section anchor-offset">
                                    <h4>Descrizione</h4>
                                    <p className="text-serif">
                                        Il Programma di Riqualificazione Energetica degli edifici della Pubblica Amministrazione Centrale (PREPAC) è stato istituito dall’art. 5 del Dlgs102/2014 e ha come obiettivo quello di conseguire, nel periodo 2014-2030, la riqualificazione energetica di almeno il 3% annuo della totalità della superficie climatizzata degli immobili della Pubblica Amministrazione Centrale.<br/>
                                        Il decreto legislativo ha voluto in questo modo riconoscere l’esemplarità delle azioni della Pubblica Amministrazione, in particolar di quella Centrale, quale settore trainante per la trasformazione del parco edilizio nazionale verso edifici sempre più efficienti. La predisposizione del programma è stata inizialmente affidata al Ministero dello Sviluppo Economico (ora al Ministero della Transizione Ecologica) che ne ha disciplinato l’attuazione attraverso l’emanazione del Decreto Ministeriale16 settembre 2016 (Decreto PREPAC).<br/>
                                        Il coordinamento e il monitoraggio dello stato di avanzamento del Programma sono stati assegnati alla Cabina di Regia per l’efficienza energetica, istituita con l’art. 4 del Dlgs 102/2014 e modificata dal successivo Dlgs 73/2020.<br/>
                                        L’ENEA e il GSE (Gestore dei Servizi Energetici) sono state incaricate di fornire uno specifico supporto tecnico alle attività della Cabina di Regia. In questo ambito sono state realizzate nel 2016 delle Linee Guida che illustrano i criteri generali e le indicazioni operative per la predisposizione e la presentazione delle proposte progettuali ai fini dell’ammissione al Programma PREPAC.<br/>
                                        La partecipazione al programma prevede la predisposizione, di proposte progettuali per interventi di efficienza energetica, espressamente indicati in una Diagnosi Energetica o in un Attestato di Prestazione Energetica (APE), su edifici di proprietà della Pubblica Amministrazione Centrale e da esse occupati, inseriti nel portale IPER dell’Agenzia del Demanio. Le proposte di intervento devono essere elaborate entro il 30 giugno di ogni anno, con termine ultimo di invio entro i 15 giorni successivi. A tal riguardo, è prevista l’istituzione di un sito web ministeriale (portale informatico) per assicurare la gestione delle proposte di intervento e di tutta la documentazione e degli adempimenti inerenti.<br/>
                                        Le tipologie degli interventi ammessi riguardano l’involucro, gli impianti tecnici, la realizzazione di sistemi di produzione di energia elettrica o termica e altri interventi in grado di ridurre i consumi di energia primaria dei servizi energetici previsti dal D.M. 26 giugno 2015 (Requisiti minimi). Gli interventi ammessi sono finanziabili fino al 100% dei costi di realizzazione, con la possibilità di accedere anche ad altri incentivi, sino a totale copertura della spesa.<br/>
                                        La predisposizione annuale del programma prevede la definizione di una graduatoria delle proposte presentate e ritenute ammissibili dal punto di vista tecnico-economico, basata su specifici criteri di valutazione (costo del kWh risparmiato, entità del cofinanziamento, tempi di realizzazione previsti). Il 20% delle risorse disponibili viene riservato a proposte che prevedono interventi sugli impianti tecnici e sull’involucro edilizio e risparmi non inferiori al 50% (progetti esemplari).<br/>
                                        Le attività per la realizzazione degli interventi sono affidate ai Provveditorati per le Opere Pubbliche e all’Agenzia del Demanio, con il supporto delle Amministrazioni proponenti.<br/>

                                        Per ulteriori approfondimenti clicca <a href="https://www.mase.gov.it/energia/efficienza-energetica/pubblica-amministrazione" target="_blank" rel="noreferrer">qui</a> 

                                    </p>
                                    </article>
                                    <hr></hr>
                                    
                                </section>
                                </div>

                            </div>

                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Prepac;
