import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import docet from "../assets/img/docet.jpg";
import "../App.css";
import { Link } from 'react-router-dom';


function Docet() {
  return (
    <div>
        <HeaderTop />
        <div className="container my-4">
            <section id="briciole" className="container px-4 my-4">
                <div className="row">
                    <div className="col px-lg-4">
                        <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/software">Software</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                    Docet
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section id="intro" className="container px-4 my-4">
                <div className="row">
                    <div>
                        <h1>Docet</h1>
                        <div className="row">
                            <div className="col-md-8">
                                <p>
                                In occasione del superamento della Procedura di Verifica di Sorveglianza Periodica prevista per gli strumenti di calcolo e software commerciali ai fini del rilascio della dichiarazione CTI (ai sensi art 7 DM 26.06.2015) l'<strong>ENEA</strong>, in collaborazione con l'<strong>Istituto per le Tecnologie della Costruzione del CNR</strong>, ha reso disponibile una <strong>nuova versione di DOCET scaricabile unicamente attraverso il sito ENEA</strong>.</p>
                                <p>L'applicativo, utilizzabile esclusivamente per la certificazione energetica di immobili residenziali esistenti con superficie fino a 200 m2 che non siano stati sottoposti a ristrutturazioni importanti, consente la redazione dell'Attestato di Prestazione Energetica (APE), il documento che certifica la prestazione e la classe energetica di un immobile indicando gli interventi migliorativi ed economicamente più convenienti.</p>
                                <p>L'ultima versione del <strong>software gratuito DOCET</strong> mantiene le caratteristiche originarie dell'applicativo che utilizza un metodo semplificato secondo quanto previsto dal D.M. 26/06/2015 (Allegato 1, paragrafo 4.2.2) ed esegue una valutazione standard dell'immobile, vale a dire A2 (asset rating), con condizioni climatiche e comportamento dell'utenza standard.</p>
                                <p>Il calcolo della prestazione energetica del sistema edificio-impianti viene effettuato nel pieno rispetto delle norme tecniche previste dai DM 26.06.2015: UNI/TS 11300-1:2014, UNI/TS 11300-2:2019, UNI/TS 11300-3:2010, UNI/TS 11300-4:2016 e delle norme tecniche di supporto nelle ultime versioni aggiornate. </p>
                                <p>Le verifiche effettuate rispondono ai Requisiti Minimi di legge previsti per la specifica categoria di interventi.</p>
                                <p><strong>DOCET</strong> prevede un utilizzo da parte di utenti con specifica preparazione che siano a conoscenza delle limitazioni dichiarate per l'utilizzo e che siano in grado di gestire la fase di reperimento dati ed il loro corretto inserimento sia nel caso optassero per input predeterminati dal software sia nel caso di inserimento manuale.</p>
                                <p>Alcune novità introdotte nella nuova versione riguardano la richiesta di input aggiuntivi in grado di dettagliare in maniera più puntuale la descrizione dell'immobile (es: una migliore caratterizzazione delle superfici trasparenti per il calcolo degli apporti solari).</p>
                                <p>Data la forte variabilità dei prezzi energetici sono stati aggiornati i costi unitari dei combustibili: si raccomanda in ogni caso sempre un'attenta verifica da parte del tecnico certificatore. </p>
                                <p>Qualora gli interventi di riqualificazione proposti evidenzino tempi di ritorno non congrui (ad es: rientro dell'investimento superiore alla vita utile degli elementi sostituiti), sia nel caso di interventi sull'involucro edilizio che sui sistemi impiantistici, l'<strong>ultima versione di DOCET evidenzia</strong> un messaggio che invita a modificare l'intervento ipotizzato perché economicamente non conveniente. </p>
                                <p><strong>DOCET</strong> consente di generare ed esportare un file di interscambio (sia nella versione standard-ridotta v.12 che in formato esteso v.5) necessario per il trasferimento degli APE ai sistemi informativi regionali che a loro volta alimentano il SIAPE (Sistema Informativo sugli Attestati di Prestazione Energetica a livello nazionale gestito da ENEA in attuazione del DM 26.06.2015). Per l'esportazione degli APE occorre verificare il formato dati *.xml richiesto dalla Regione presso la quale ricade l'immobile da certificare.</p>
                                <p>Per maggiori informazioni sull'applicabilità, sulle specifiche tecniche, sulle metodologie di calcolo e sulla compilazione del software si raccomanda la consultazione del Manuale Utente disponibile in download insieme all'ultima versione di <strong>DOCET</strong>.
                                </p>
                                <p>
                                    <strong>DOCET</strong> è scaricabile attraverso il seguente link: <a href="https://www.efficienzaenergetica.enea.it/servizi-per/cittadini/docet.html" target="_blank" rel="noreferrer">https://www.efficienzaenergetica.enea.it/servizi-per/cittadini/docet.html</a>
                                </p>
                                <p>Per ulteriori informazioni: info.docet@itc.cnr.it</p>
                            </div>
                            
                            <img className="col-md-4 mw-300 h-100" src={docet} title="docet" alt="docet" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <FooterBottom />
    </div>
  );
};

export default Docet;
