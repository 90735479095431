import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../scss/bootstrap-italia-custom.scss";
import "../App.css";
import { Link } from 'react-router-dom';
import nzeb1 from '../assets/img/nzeb1.png';
import nzeb2 from '../assets/img/nzeb2.png';


function Nzeb() {
  return (
    <div>
    <HeaderTop />
         <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Edificio nZEB
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Edificio nZEB</h1>
                            <p className="mt-4">
                            Per edificio nZEB, si intende un sistema edificio-impianto ad altissima prestazione energetica in cui il fabbisogno energetico, sia in regime invernale che estivo, molto basso o quasi nullo, è coperto in misura significativa da energia da fonti rinnovabili, prodotta in situ”, come stabilito da D.M. 26 giugno 2015 e dal D.Lgs. n.28 del 3 marzo 2011. <br/>
                            A partire dal 1 gennaio 2021 in Italia tutti gli edifici di nuova costruzione o soggetti ad una ristrutturazione importante di primo livello devono rispondere ai requisiti tecnici e prestazionali imposti dall’Allegato 1 del D.M. 26/6/2015 per gli edifici a energia quasi zero (nZEB - nearly Energy Zero Building). <br/>
                            Nella fattispecie, un edificio si definisce nZEB quando risultano inferiori ai corrispondenti valori calcolati per l’edificio di riferimento, ossia un edificio identico a quello di progetto o reale in termini di geometria, orientamento, ubicazione territoriale, destinazione d’uso e situazione al contorno e avente caratteristiche termiche e parametri energetici predeterminati, gli indici di seguito elencati:
                           <ul>
                               <li>Il coefficiente medio globale di scambio termico, H’<sub>T</sub> che indica il livello prestazionale dell’involucro disperdente comprensivo dei ponti termici;</li>
                               <li>Il rapporto tra l’area solare equivalente estiva e l’area della superficie utile Asol,est /Asup,utile, che regola gli apporti solari e quindi considera non solo le caratteristiche dell’involucro trasparente ma anche gli ombreggiamenti, gli elementi schermanti nonché la latitudine e l’orientamento stesso degli edifici;</li>
                               <li>Gli indici di prestazione termica utile in regime invernale, EPH,nd, ed estivo, EPC,nd, che sono unicamente dipendenti dalle caratteristiche dell’involucro disperdente e quindi dal rapporto apporti-dispersioni dell’edificio considerato;</li>
                               <li>L’indice di prestazione energetica globale espresso in energia primaria, EPgl, tot, che considera il sistema impiantistico a servizio della climatizzazione invernale, estiva e della produzione di acqua calda sanitaria;</li>
                               <li>I rendimenti medi stagionali relativi agli impianti di climatizzazione invernale ηH, estiva ηC, e di produzione di acqua calda sanitaria, ηw;</li>
                           </ul>
                            Ulteriore requisito, definito dal D.Lgs n.28/2011, consiste nell’obbligo di integrazione da fonti rinnovabili ovvero:
                            <ul>
                                <li>Copertura del 50% da fonti energetiche rinnovabili dei consumi previsti per la produzione di acqua calda sanitaria;</li>
                                <li>Copertura del 50% da fonti energetiche rinnovabili dei consumi previsti per la produzione di acqua calda sanitaria, riscaldamento e raffrescamento.</li>
                            </ul>
                            <div className="text-center my-5">
                            <img className="m-auto w-100 mw-50"
                                src={nzeb1}
                                alt='Edificio nzeb'
                                title='Edificio nzeb'
                            />
                            </div>
                            Nella tabella i requisiti da rispettare nella progettazione di un edificio nZEB:
                            <div className="text-center my-5">
                            <img className="m-auto w-100"
                                src={nzeb2}
                                alt='Edificio nzeb'
                                title='Edificio nzeb'
                            />
                            </div>
                           
                            </p>
                            </div>
                        
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Nzeb;