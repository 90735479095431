import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";
import { Link } from 'react-router-dom';
import sprite2 from "../assets/svg/sprite.svg";

function Servizi() {
  return (
    <div>
    <HeaderTop />
          <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Servizi
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h2>Servizi per la riqualificazione energetica degli edifici</h2>
                            <p className="mt-3">Da questa pagina è possibile navigare nei vari servizi messi a disposizione per la riqualificazione energetica degli edifici suddivisi per categoria di utenza.</p>
                            <div className="row border-top row-column-border row-column-menu-left">
                                <aside className="col-lg-4">
                                    <div className="sticky-wrapper navbar-wrapper">
                                    <nav
                                        className="navbar it-navscroll-wrapper it-top-navscroll navbar-expand-lg"
                                    >
                                        <button
                                        className="custom-navbar-toggler"
                                        type="button"
                                        aria-controls="navbarNav"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                        data-target="#navbarNav"
                                        >
                                        <span className="it-list"></span>Classi di utenza
                                        </button>
                                        <div className="navbar-collapsable" id="navbarNav">
                                        <div className="overlay"></div>
                                        <div className="close-div sr-only">
                                            <button className="btn close-menu" type="button">
                                            <span className="it-close"></span>Chiudi
                                            </button>
                                        </div>
                                        <a className="it-back-button" href="/#">
                                            <svg className="icon icon-sm icon-primary align-top">
                                            <use xlinkhref="/assets/bootstrap-italia/dist/svg/sprite.svg#it-chevron-left"></use>
                                            </svg>
                                            <span>Torna indietro</span></a
                                        >
                                        <div className="menu-wrapper">
                                            <div className="link-list-wrapper menu-link-list">
                                            <h3 className="no_toc">Classi di utenza</h3>
                                            <ul className="link-list">
                                                <li className="nav-item active">
                                                <a className="nav-link active" href="#cittadini"
                                                    ><span>Cittadini</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="#pa"
                                                    ><span>Pubblica Amministrazione</span></a
                                                >
                                                </li>
                                                <li className="nav-item">
                                                <a className="nav-link" href="#imprese"
                                                    ><span>MiTe e Conferenza Unificata                                                    </span></a
                                                >
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </nav>
                                    </div>
                                </aside>
                                <section className="col-lg-8 it-page-sections-container">
                                    <article id="cittadini" className="it-page-section anchor-offset">
                                    <h4>Per i cittadini</h4>
                                    <p className="text-serif">
                                    I servizi a disposizione dei cittadini forniscono loro un valido supporto per comprendere la prestazione energetica del proprio immobile, per orientarsi agevolmente tra le varie tipologie di intervento di riqualificazione energetica ed i relativi vantaggi fiscali. Il Portale stesso, inoltre,  fornisce ai cittadini una sorta di cassetto digitale con tutte le pratiche digitali dell’immobile relative all’efficienza energetica.
                                    </p>
                                    <div className="card-wrapper card-teaser-wrapper">
                                    <div className="card card-teaser shadow mt-3 rounded">
                                        <div className="card-body">
                                            
                                            <h5 className="card-title">
                                            <svg className="icon">
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-tool" }></use>
                                                </svg>
                                            </svg>
                                            Certificazione energetica edifici
                                            </h5>
                                            <div className="card-text mt-2">
                                            <Link to="/ape"><p className="mt-3">Ulteriori dettagli</p></Link>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="card card-teaser shadow mt-3 rounded">
                                        <div className="card-body">
                                            
                                            <h5 className="card-title">
                                            <svg className="icon">
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-tool" }></use>
                                                </svg>
                                            </svg>
                                            Detrazioni Fiscali
                                            </h5>
                                            <div className="card-text mt-2">
                                            <Link to="/detrazioni_fiscali"><p className="mt-3">Ulteriori dettagli</p></Link>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="card card-teaser shadow mt-3 rounded">
                                        <div className="card-body">
                                            
                                            <h5 className="card-title">
                                            <svg className="icon">
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-tool" }></use>
                                                </svg>
                                            </svg>
                                            Conto termico
                                            </h5>
                                            <div className="card-text mt-2">
                                            <Link to="/conto_termico"><p className="mt-3">Ulteriori dettagli</p></Link>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </article>
                                    <hr></hr>
                                    <article id="pa" className="it-page-section anchor-offset mt-5">
                                    <h4>Per la Pubblica Amministrazione</h4>
                                    <p className="text-serif">
                                    Sia che si parli di PA centrale che locale, all’interno del Portale, nella sezione dei servizi si potranno trovare utilità in base al ruolo:<br/><br/>
                                    <ul>
                                    <li><h5>PA come proprietario di immobili</h5>
                                    Nella maggior parte dei casi, l’ente pubblico che accede al Portale gestisce più immobili. Da qui la necessità di estendere le analisi e le valutazioni non al singolo immobile, ma anche di allargarle a tutto il patrimonio dell’ente, sviluppando elaborazioni per categoria di immobile (i.e. scuole, uffici, ecc.) o per una porzione definita di territorio, gestendo anche la programmazione degli interventi.
                                    </li>
                                    <li className="mt-3"><h5>PA come ente pianificatore</h5>
                                    In questo caso il Portale dovrà interfacciarsi con la PA costituendo un sistema di supporto alle decisioni, fornendo informazioni sullo stato di fatto del territorio di competenza e fornendo indicatori di monitoraggio delle politiche energetiche locali o nazionali, a seconda che si tratti di PA locali o centrali. 
                                    </li>
                                    </ul>
                                    </p>
                                    <div className="card-wrapper card-teaser-wrapper">
                                    <div className="card card-teaser shadow mt-3 rounded">
                                        <div className="card-body">
                                            
                                            <h5 className="card-title">
                                            <svg className="icon">
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-tool" }></use>
                                                </svg>
                                            </svg>
                                            Certificazione energetica edifici
                                            </h5>
                                            <div className="card-text mt-2">
                                            <Link to="/ape"><p className="mt-3">Ulteriori dettagli</p></Link>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="card card-teaser shadow mt-3 rounded">
                                        <div className="card-body">
                                            
                                            <h5 className="card-title">
                                            <svg className="icon">
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-tool" }></use>
                                                </svg>
                                            </svg>
                                            Fondo Nazionale EE
                                            </h5>
                                            <div className="card-text mt-2">
                                            <Link to="/fondo_nazionale"><p className="mt-3">Ulteriori dettagli</p></Link>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="card card-teaser shadow mt-3 rounded">
                                        <div className="card-body">
                                            
                                            <h5 className="card-title">
                                            <svg className="icon">
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-tool" }></use>
                                                </svg>
                                            </svg>
                                            PREPAC
                                            </h5>
                                            <div className="card-text mt-2">
                                            <Link to="/prepac"><p className="mt-3">Ulteriori dettagli</p></Link>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="card card-teaser shadow mt-3 rounded">
                                        <div className="card-body">
                                            
                                            <h5 className="card-title">
                                            <svg className="icon">
                                                <svg className="icon">
                                                <use href={ sprite2 + "#it-tool" }></use>
                                                </svg>
                                            </svg>
                                            EPC
                                            </h5>
                                            <div className="card-text mt-2">
                                            <Link to="/epc"><p className="mt-3">Ulteriori dettagli</p></Link>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </article>
                                    <hr></hr>
                                    <article id="imprese" className="it-page-section anchor-offset mt-5">
                                    <h4>MiTe e Conferenza Unificata</h4>
                                    <p className="text-serif">
                                    Questa categoria di utenza puà accedere ad una vista di report statistici annuali inerenti sia il parco immobiliare pubblico che quello privato, fornendo indicazioni sia cartografiche che tabellari ad un livello di dettaglio al più comunale.
                                    </p>
                                    </article>
                                </section>
                                </div>

                            </div>

                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Servizi;
