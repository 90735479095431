import React, { useEffect, useState } from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../scss/bootstrap-italia-custom.scss";
import "../App.css";
import Chart from "../components/grafico";
import { CardBody, CardTitle, Select, Card, Icon,  Row, Col,Callout,CalloutTitle,CalloutText } from 'design-react-kit';
import axios from "axios";
import Map from "../components/mappa.js";




function Statistiche() {
//  const [data, setData] = useState();
  const [dataRegionitemp, setDataRegionitemp] = useState([]);
  const [dataRegionitemp2, setDataRegionitemp2] = useState([]);
  const [dataRegionitemp3, setDataRegionitemp3] = useState([]);
  const [dataRegionitemp4, setDataRegionitemp4] = useState([]);
  const [dati1, setDati1] = useState({});
  const [dati1Tot, setDati1Tot] = useState(0);
  const [dati2, setDati2] = useState({});
  const [dati3, setDati3] = useState({});
  const [regioneSelected, setRegioneSelected] = useState(0);
  const [annoSelected, setAnnoSelected] = useState(0);
  //const [anno, setAnno] = useState(0);
  const [loading, setLoading] = useState(true);
  const [numinterventi, setNuminterventi] = useState(0);
  const [richieste, setRichieste] = useState(0);
  const [risparmio, setRisparmio] = useState(0);


  // const [temp,setTemp] = useState({
  //   labels: [],
  //   datasets: [
  //     {
  //       label: '# of Votes',
  //       data:[],
  //       backgroundColor: [
  //         '#11340d', //A4
  //         '#24711d',
  //         '#37ae2d',
  //         '#2bd51f',
  //         '#bbdd31',
  //         '#bbbb10',
  //         '#f29133',
  //         '#ed612c',
  //         '#c43536',
  //         '#ff0000', // G
  //       ],
  //
  //
  //       borderWidth: 1,
  //     },
  //   ],
  // })

  const temp = {
    labels: [],
    datasets: [
      {
        label: '# of Votes',
        data:[],
        backgroundColor: [
          '#11340d', //A4
          '#24711d',
          '#37ae2d',
          '#2bd51f',
          '#bbdd31',
          '#bbbb10',
          '#f29133',
          '#ed612c',
          '#c43536',
          '#ff0000', // G
        ],


        borderWidth: 1,
      },
    ],

  }

    const temp3 = {
      labels: [''],
      datasets: [],
    }
  //
  const classi= ['A4','A3','A2','A1','B','C','D','E','F','G']
  const backgroundColor= [
    '#11340d', //A4
    '#24711d',
    '#37ae2d',
    '#2bd51f',
    '#bbdd31',
    '#bbbb10',
    '#f29133',
    '#ed612c',
    '#c43536',
    '#ff0000'
  ]

  const temp2 = {
    labels: ['A4','A3','A2','A1','B','C','D','E','F','G'],
    datasets: [
      {
        label: ['Epglren medio'],
        data:[],
        backgroundColor: '#11340d',

        stack: 'Stack 0',
        borderWidth: 2,
      },
      {
        label: ['Epglnren medio'],
        data:[],
        backgroundColor: '#f29133',
        stack: 'Stack 0',
        borderWidth: 2,
      },


    ],
  }


    const setDati = (args) =>{

      let tempArr=[]

      const tempArray1=JSON.parse(JSON.stringify((temp)))
      let datiRegione=JSON.parse(JSON.stringify((args.datiJson)))
      let regSel=parseFloat(args.idRegioneSel)
      let annoSel=parseFloat(args.annoSel)
      datiRegione.map((dato) => {


        if((!regSel || parseFloat(dato.cod_reg)===regSel) && (!annoSel || parseFloat(dato.anno)===annoSel)){

          Object.keys(dato).map((key, value) =>{
              if(key!=='cod_reg' && key!=='anno'){
                let val=dato[key]
                let label=key.replaceAll('_',' ').toUpperCase()
                tempArr[label]=(tempArr[label])?tempArr[label]+val:val

              }
              return tempArr
           })
         }
         return tempArr
      })
      if(tempArr){
        Object.keys(tempArr).map((key, value) =>{
            let val=tempArr[key]
            tempArray1.datasets[0].data.push(val)
            key=key.replaceAll('_',' ').toUpperCase()
            tempArray1.labels.push(key)
            return tempArr
          }
        )
      }
      //setTemp(tempArray)
      // console.log(Object.keys(tempArr).length)

      if (Object.keys(tempArr).length >= 1){

        setDati1Tot(Object.values(tempArr).reduce((result,number)=> result+number))

      }else{
        setDati1Tot(0)
      }
      setDati1(tempArray1)
    }

    //
    const setDatiEpgl = (args) =>{

      const epglren_medioArr=[]
      const epglren_medioArr_count=[]
      const epglnren_medioArr=[]
      const epglnren_medioArr_count=[]
      const tempArray2=JSON.parse(JSON.stringify((temp2)))
      let regSel=parseFloat(args.idRegioneSel)
      let annoSel=parseFloat(args.annoSel)
      let datiRegione=JSON.parse(JSON.stringify((args.datiJson)))

      datiRegione.map((dato) => {

        if((!regSel || parseFloat(dato.cod_reg)===regSel) && (!annoSel || parseFloat(dato.anno)===annoSel)){
          epglren_medioArr[dato.classe]=(epglren_medioArr[dato.classe])?epglren_medioArr[dato.classe]+dato.epglren_medio:dato.epglren_medio
          epglnren_medioArr[dato.classe]=(epglnren_medioArr[dato.classe])?epglnren_medioArr[dato.classe]+dato.epglnren_medio:dato.epglnren_medio
          epglren_medioArr_count[dato.classe]=(epglren_medioArr_count[dato.classe])?epglren_medioArr_count[dato.classe]+1:1
          epglnren_medioArr_count[dato.classe]=(epglnren_medioArr_count[dato.classe])?epglnren_medioArr_count[dato.classe]+1:1
        //  tempArr['epglnren_medio'][dato.classe]=(tempArr['epglnren_medio'][dato.classe])?tempArr['epglnren_medio'][dato.classe]+dato.epglnren_medio:dato.epglnren_medio

         }
         return []

      })
      if(epglren_medioArr){
        classi.map((key, value) =>{
          tempArray2.datasets[0].data.push(epglren_medioArr[key]/epglren_medioArr_count[key])
          return tempArray2

        })

      }
      if(epglnren_medioArr){
        classi.map((key, value) =>{
          tempArray2.datasets[1].data.push(epglnren_medioArr[key]/epglnren_medioArr_count[key])

          return tempArray2

        })
      }


      //setTemp(tempArray)
      setDati2(tempArray2)
    }

    //
    const setDatiBonus = (args) =>{

      const tempArr2=[]
      const tempArray3=JSON.parse(JSON.stringify((temp3)))
      let num_risp=0

      let datiRegione=JSON.parse(JSON.stringify((args.datiJson)))
      let regSel=parseFloat(args.idRegioneSel)
      let annoSel=parseFloat(args.annoSel)
      datiRegione.map((dato) => {

        if((!regSel || parseFloat(dato.cod_reg)===regSel) && (!annoSel || parseFloat(dato.anno)===annoSel)){
          // console.log(args.annoSel)
          num_risp+=parseFloat(dato.risparmio_stimato)

          Object.keys(dato).map((key, value) =>{
              if(key!=='cod_reg' && key!=='anno' && key!=='risparmio_stimato'){
                let val=dato[key]
                let label=key.replaceAll('_',' ').toUpperCase()
                tempArr2[label]=(tempArr2[label])?tempArr2[label]+val:val



              }
              return tempArr2

           })
         }
         return tempArr2
      })

      if(tempArr2){
        let i=0
        Object.keys(tempArr2).map((key, value) =>{

            let val=tempArr2[key]

            key=key.replaceAll('_',' ').toUpperCase()

            tempArray3.datasets.push({'label':key,'data':[val],'backgroundColor':backgroundColor[i] })

            i++
            return tempArr2
          }
        )
      }

      setRisparmio(num_risp)
      setDati3(tempArray3)
    }

    const setRiepilogo = (args) =>{

      let num_int=0
      let num_ric=0

      let datiRegione=args.datiJson
      let regSel=parseFloat(args.idRegioneSel)
      let annoSel=parseFloat(args.annoSel)

      datiRegione.map((dato) => {

        if((!regSel || parseFloat(dato.cod_reg)===regSel) && (!annoSel || parseFloat(dato.anno)===annoSel)){
          num_int+=dato.numero_interventi
          num_ric+=dato.richieste_detrazioni

         }

         return []

      })

      console.log(num_int)
      console.log(num_ric)
      //setTemp(tempArray)      //setTemp(tempArray)
      setNuminterventi(num_int)
      setRichieste(num_ric)
    }


    const handleChangeRegione = e => {
      setRegioneSelected(e.value);
      setDati({'datiJson':dataRegionitemp,'idRegioneSel':e.value,'annoSel':annoSelected})
      setDatiEpgl({'datiJson':dataRegionitemp2,'idRegioneSel':e.value,'annoSel':annoSelected})
      setDatiBonus({'datiJson':dataRegionitemp3,'idRegioneSel':e.value,'annoSel':annoSelected})
      setRiepilogo({'datiJson':dataRegionitemp4,'idRegioneSel':e.value,'annoSel':annoSelected})
    }

    const handleChangeAnno = e => {
      setAnnoSelected(e.value);
      setDati({'datiJson':dataRegionitemp,'idRegioneSel':regioneSelected,'annoSel':e.value})
      setDatiEpgl({'datiJson':dataRegionitemp2,'idRegioneSel':regioneSelected,'annoSel':e.value})
      setDatiBonus({'datiJson':dataRegionitemp3,'idRegioneSel':regioneSelected,'annoSel':e.value})
      setRiepilogo({'datiJson':dataRegionitemp4,'idRegioneSel':regioneSelected,'annoSel':e.value})
    }





  useEffect(() => {

    setLoading(true);
    axios.get(
      "//pnpe2.enea.it/api/data/pnpe2_ape_classe_regione_anno"
    )
    .then((response)=>{
      setDataRegionitemp(response.data)
        setDati({'datiJson':response.data,'idRegioneSel':0,'annoSel':0})
    })
    .finally(()=>{
        setLoading(false);
      }
    )
    .catch((e)=>{
      //console.log(e)
    })

    setLoading(true);

    axios.get(
      "//pnpe2.enea.it/api/data/pnpe2_ape_epgl_regione_anno"
    )
    .then((response)=>{
      setDataRegionitemp2(response.data)
      setDatiEpgl({'datiJson':response.data,'idRegioneSel':0,'annoSel':0})
    })
    .finally(()=>{
        setLoading(false);
      }
    )
    .catch((e)=>{
      //console.log(e)
    })


    setLoading(true);

    axios.get(
      "//pnpe2.enea.it/api/data/pnpe2_ecobonus_comma_regione_anno"
    )
    .then((response)=>{
      setDataRegionitemp3(response.data)
      setDatiBonus({'datiJson':response.data,'idRegioneSel':0,'annoSel':0})
    })
    .finally(()=>{
        setLoading(false);
      }
    )
    .catch((e)=>{
      //console.log(e)
    })

    setLoading(true);

    axios.get(
      "//pnpe2.enea.it/api/data/pnpe2_ecobonus_regione_anno"
    )
    .then((response)=>{
      setDataRegionitemp4(response.data)
      setRiepilogo({'datiJson':response.data,'idRegioneSel':0,'annoSel':0})
    })
    .finally(()=>{
        setLoading(false);
      }
    )
    .catch((e)=>{
      //console.log(e)
    })


  }, []);

//  console.log(datiRegione)



if(!dati3){
  return null;
}
  return (
    <div>
    <HeaderTop />



    {loading && <div>Loading</div>}

        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                  <a href="/">Home</a><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                  Statistiche Nazionali
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                <h1>Statistiche Nazionali</h1>

                  <Row  className="mb-3">
                    <Col>
                    <div className='card-wrapper '>

                      <Card teaser noWrapper className='rounded shadow'>
                        <CardBody>
                          <CardTitle>
                          Filtri ricerca
                          </CardTitle>
                          <div className="mt-5">
                            <div>

                              <div className='form-row'>

                                <Col md='6'>
                                  <div className='bootstrap-select-wrapper'>
                                  <label>Regioni e Province Autonome</label>
                                  <Select
                                    id='selectExampleClassic'
                                    onChange={handleChangeRegione}
                                    options={[
                                      { value: '0', label: 'Tutte le regioni e province autonome' },
                                      { value: '13', label: 'ABRUZZO' },
                                      { value: '17', label: 'BASILICATA' },
                                      { value: '22', label: 'BOLZANO' },
                                      { value: '18', label: 'CALABRIA' },
                                      { value: '15', label: 'CAMPANIA' },
                                      { value: '08', label: 'EMILIA ROMAGNA' },
                                      { value: '06', label: 'FRIULI V. G.' },
                                      { value: '12', label: 'LAZIO' },
                                      { value: '07', label: 'LIGURIA' },
                                      { value: '03', label: 'LOMBARDIA' },
                                      { value: '11', label: 'MARCHE' },
                                      { value: '14', label: 'MOLISE' },
                                      { value: '01', label: 'PIEMONTE' },
                                      { value: '16', label: 'PUGLIA' },
                                      { value: '20', label: 'SARDEGNA' },
                                      { value: '19', label: 'SICILIA' },
                                      { value: '09', label: 'TOSCANA' },
                                      { value: '21', label: 'TRENTO' },
                                      { value: '10', label: 'UMBRIA' },
                                      { value: '02', label: 'VAL D’AOSTA' },
                                      { value: '05', label: 'VENETO' }
                                    ]}
                                    placeholder='Tutte le regioni'
                                    aria-label='Tutte le regioni'
                                  />
                                </div>
                              </Col>
                              <Col md='6' className="paddingsearch">
                                <div className='bootstrap-select-wrapper'>
                                  <label>Anno</label>
                                  <Select
                                    id='annoForm'
                                    onChange={handleChangeAnno}
                                    options={[
                                      { value: '0', label: 'Tutti gli anni' },
                                      { value: '2018', label: '2018' },
                                      { value: '2019', label: '2019' },
                                      { value: '2020', label: '2020' },
                                      { value: '2021', label: '2021' },
                                      { value: '2022', label: '2022' },
                                      { value: '2023', label: '2023' }

                                    ]}
                                    placeholder='Tutti gli anni'
                                    aria-label='Tutti gli anni'
                                  />
                                </div>
                              </Col>
                            </div>

                          </div>
                          </div>
                        </CardBody>
                      </Card>
                      </div>

                      </Col>
                    </Row>




                <Row>
                  <Col xs='12' lg='6'>
                    {/* Start card */}
                    <Card spacing className='card-bg card-big border-bottom-card'>
                      <div className='flag-icon'></div>
                      <div className='etichetta'>
                        <Icon icon='it-chart-line' title="ECOBONUS"/>
                        <span>ECOBONUS</span>
                      </div>
                      <CardBody>

                        <Row>
                          <Col xs='12' lg='12'>
                              <Callout >
                                <CalloutTitle>N° Richieste</CalloutTitle>
                                <CalloutText>
                                  <h4 className="text-center">{richieste.toLocaleString('it-IT')}</h4>
                                </CalloutText>
                              </Callout>
                            </Col>
                            <Col xs='12' lg='12'>


                              <Callout>
                                <CalloutTitle>N° Interventi</CalloutTitle>
                                <CalloutText>
                                  <h4 className="text-center">{numinterventi.toLocaleString('it-IT')}</h4>
                                </CalloutText>
                              </Callout>
                            </Col>
                            <Col xs='12' lg='12' className="d-none">

                                <Callout>
                                <CalloutTitle>Risparmio (MWh/anno)</CalloutTitle>
                                <CalloutText>
                                  <h4 className="text-center">{risparmio && (risparmio/1000).toLocaleString('it-IT')}</h4>
                                </CalloutText>
                              </Callout>
                            </Col>
                          </Row>

                      </CardBody>
                    </Card>
                    {/* end card */}
                  </Col>

                  <Col xs='12' lg='6'>
                    {/* Start card */}
                    <Card spacing className='card-bg card-big border-bottom-card'>
                      <div className='flag-icon'></div>
                      <div className='etichetta'>
                        <Icon icon='it-chart-line' title="SIAPE"/>
                        <span>SIAPE</span>
                      </div>
                      <CardBody className="graficopadd pl-5 pr-5 text-center">

                          {dati1['datasets'] && <Chart tipo='pie' data={dati1} titolo='Numero di APE per classe energetica'/>}
                          {
                            (dati1Tot && parseInt(dati1Tot)>0)
                            ? (<h6 className="mt-3">Totale: {dati1Tot.toLocaleString('it-IT')}</h6>)
                            : (<h6 className="alert alert-warning mt-3">Nessun dato disponibile!</h6>)
                          }

                          {//(dati1Tot && parseInt(dati1Tot)==0) && <h6 className="mt-3">d</h6>
                          }


                      </CardBody>
                    </Card>
                    {/* end card */}
                  </Col>



                </Row>



                <Row>
                  <Col xs='12' lg='6'>
                    {/* Start card */}
                    <Card spacing className='card-bg card-big border-bottom-card'>
                      <div className='flag-icon'></div>
                      <div className='etichetta'>
                        <Icon icon='it-chart-line' title="ECOBONUS"/>
                        <span>ECOBONUS</span>
                      </div>
                      <CardBody className="graficopadd text-center">

                          {
                            dati3['datasets'] && <Chart tipo='barlog'  data={dati3} titolo='Numero di richieste per tipo intervento'/>
                          }

                      </CardBody>
                    </Card>
                    {/* end card */}
                  </Col>

                  <Col xs='12' lg='6'>
                    {/* Start card */}
                    <Card spacing className='card-bg card-big border-bottom-card'>
                      <div className='flag-icon'></div>
                      <div className='etichetta'>
                        <Icon icon='it-chart-line' title="SIAPE"/>
                        <span> SIAPE</span>
                      </div>
                      <CardBody className="graficopadd text-center">

                          {
                           dati2['datasets'] && <Chart tipo='bar'  data={dati2} titolo={["EPgl medio per classe energetica"]}/>
                        }

                      </CardBody>
                    </Card>
                    {/* end card */}
                  </Col>



                </Row>

                <Row  className="mb-3">
                  <Col>
                    <Callout
                      color="danger"
                      highlight
                    >
                      <CalloutTitle className="mb-2">
                        <Icon
                          aria-hidden
                          icon="it-info-circle"
                        />
                        Anni di riferimento
                      </CalloutTitle>
                      <CalloutText>
                      Anni dal 2018 al 2023.{' '}
                      </CalloutText>
                    </Callout>
                  <Card spacing className='card-bg card-big border-bottom-card d-none'>
                      <div className='flag-icon'></div>
                      <div className='etichetta'>
                        <Icon icon='it-chart-line' />
                        <span>DATI GEOREFERENZIATI</span>
                      </div>
                      <CardBody className="py-0 mb-0">
                          <Map className="position-relative top-right-0 h-450" />
                        <Callout color="danger" highlight style={{"marginTop" : "0"}}>
                          <CalloutTitle className="mb-2">
                            <Icon
                              aria-hidden
                              icon="it-info-circle"
                            />
                            Anni di riferimento
                          </CalloutTitle>
                          <CalloutText>
                          Anni dal 2018 al 2023.
                          </CalloutText>
                        </Callout>
                      </CardBody>
                    </Card>
                    {/* end card */}
                  </Col>



                </Row>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Statistiche;
