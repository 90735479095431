import React from "react";
import "../assets/svg/ponmetroca.svg";
import "../assets/svg/sprite.svg";
import logo_portale from "../assets/img/logo_portale.png";
import { Link } from "react-router-dom";




function FooterBottom() {
  return (
    <footer className="it-footer">
        <div className="it-footer-main">
            <div className="container">
            <section>
                <div className="row clearfix">
                <div className="col-sm-12">
                    <div className="it-brand-wrapper">
                    <Link to="/#">
                        <img src={logo_portale} title="PNEE" alt="PNEE" width="82" height="70"/>
                        <div className="it-brand-text">
                            <h2 className="no_toc pl-2">PnPE<sup>2</sup></h2>
                            <h3 className="no_toc d-none d-md-block pl-2">Portale Nazionale sulla Prestazione Energetica degli Edifici<br/>Digital One Stop Shop</h3>
                        </div>
                        </Link>
                    </div>
                </div>
                </div>
            </section>
            <section>
                <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-6 pb-2">
                    <h4>
                    <Link to="/#" title="Vai alla pagina: Portale">Il portale</Link>
                    </h4>
                    <div className="link-list-wrapper">
                    <ul className="footer-list link-list clearfix">

                        <li> <Link className="list-item" to="/progetto" title="Vai alla pagina: Progetto">Progetto</Link></li>
                        <li><Link className="list-item" to="/attivita" title="Vai alla pagina: Attività">Attività</Link></li>
                        <li><Link className="list-item" to="/mappasito" title="Vai alla pagina: PNNR">Mappa del sito</Link></li>
                    </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 pb-2">
                    <h4>
                    <Link to="/#" title="Vai alla pagina: Formazione">Formazione</Link>
                    </h4>
                    <div className="link-list-wrapper">
                    <ul className="footer-list link-list clearfix">
                        <li><Link className="list-item" to="/formazione_professionale" title="Vai alla pagina: Come accedere">Formazione professionale</Link></li>
                        <li><Link className="list-item" to="/kcom" title="Vai alla pagina: Kcom">Kcom</Link></li>
                        <li><Link className="list-item" to="/elearn" title="Vai alla pagina: E-Learn">E-Learn</Link></li>
                        <li><Link className="list-item" to="/glossario" title="Vai alla pagina: Glossario">Glossario</Link></li>
                    </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 pb-2">
                    <h4>
                    <Link to="/#" title="Vai alla pagina: Novità">Novità</Link>
                    </h4>
                    <div className="link-list-wrapper">
                    <ul className="footer-list link-list clearfix">
                        <li><Link className="list-item" to="/notizie" title="Vai alla pagina: Notizie">Notizie</Link></li>
                        <li><Link className="list-item" to="/eventi" title="Vai alla pagina: Eventi">Eventi</Link></li>
                        <li><Link className="list-item" to="/pubblicazioni" title="Vai alla pagina: Pubblicazioni">Pubblicazioni</Link></li>
                    </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                    <h4>
                    <Link to="/#" title="Vai alla pagina: Contatti">Contatti</Link>
                    </h4>
                    <p>
                    <strong>ENEA</strong><br/> Lungotevere Thaon di Revel, 76 00196 ROMA Italia<br/>
                    Partita IVA 00985801000<br/>
                        Codice Fiscale 01320740580
                    </p>
                    <div className="link-list-wrapper d-none">
                    <ul className="footer-list link-list clearfix">
                        <li><span>EMAIL: </span><a className="list-item d-inline" href="mailto:pnpe2@enea.it" title="email">pnpe2@enea.it</a></li>
                    </ul>
                    </div>
                </div>
                </div>
            </section>
            </div>
        </div>
        <div className="it-footer-small-prints clearfix">
            <div className="container">
            <h3 className="sr-only text-white">Sezione Link Utili</h3>
            <ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
                <li className="list-inline-item"><a href="https://www.enea.it/it/info/note-legali" target="_blank" rel="noreferrer" title="Note Legali">Note legali</a></li>
                <li className="list-inline-item"><a href="https://www.enea.it/it/info/privacy" target="_blank" rel="noreferrer" title="Privacy-Cookies">Privacy policy</a></li>
                <li className="list-inline-item"><Link to="/accessibilita" title="Mappa del sito">Accessibilità</Link> </li>
            </ul>
            </div>
        </div>

        </footer>

  );
}

export default FooterBottom;
