import {React, useState} from "react";
import { CookieBar, CookieBarButtons, CookieBarButton, } from 'design-react-kit';
import { useCookies } from "react-cookie";



function Cookiebar() {
    const [cookies, setCookie] = useCookies(['cookie-name']);
    const [cookiedisplay,setcookiedisplay]=useState(cookies.openCookie);
    function setopenCookie() {
      setCookie('openCookie', 'none', { path: '/' });
      setcookiedisplay('none')
    }

  return (
    <div>
        <CookieBar style={{display: cookiedisplay}}>

        <p>
        Noi di ENEA non usiamo alcuna tecnica di profilazione degli utenti, ma solo cookies tecnici necessari al corretto funzionamento del sito e strumenti statistici. Fai però attenzione se usi funzioni che interagiscono con i social network, perchè questi potrebbero tracciare la tua navigazione con i loro cookies. Per saperne di più leggi l'informativa.{' '}
        </p>
        <CookieBarButtons className="text-center m-auto">
        <a href="https://www.enea.it/it/info/privacy" target="_blank" rel="noreferrer" className="cookiebar-btn">INFORMATIVA</a>
            <CookieBarButton onClick={()=> setopenCookie()}>
            Accetto
            <span className="sr-only">
                {' '}i cookies
            </span>
            </CookieBarButton>
        </CookieBarButtons>
        </CookieBar>
    </div>
  );
};

export default Cookiebar;
