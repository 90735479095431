import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import "../scss/bootstrap-italia-custom.scss";
import "../App.css";
import { Link } from 'react-router-dom';
import kcom from "../assets/img/kcom2.jpg";
import elearn from "../assets/img/elearn2.png";
import glossario from "../assets/img/glossario2.png";
import sprite2 from "../assets/svg/sprite.svg";



function Formazione_professionale() {
  return (
    <div>
    <HeaderTop />
        <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Formazione professionale
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Formazione professionale</h1>
                            <p className="mt-4">L’agenzia fornisce supporto tecnico-scientifico alla PA centrale e locale ed eroga formazione, di base e specialistica, nel campo dell’energy management nei settori civili, industriali e della pubblica amministrazione.<br/>
                                L’agenzia, in collaborazione con gli altri Dipartimenti ENEA, gestisce la Scuola delle Energie.<br/>
                                Si organizzano corsi e seminari per la formazione e l’aggiornamento professionale di operatori e professionisti sui temi chiave dell’efficienza energetica, con particolare attenzione all’evoluzione degli aspetti tecnici, normativi, finanziari, ai progetti e alle attività in corso.<br/><br/>
                                L’agenzia realizza anche campagne di formazione e informazione per gli utenti finali per promuovere scelte e comportamenti efficienti dal punto di vista energetico.<br/>
                                
                                <h5 className="mt-4">Desideri approfondire le tematiche energetiche?</h5>
                                ENEA ha progettato e realizzato una serie di Video Lezioni gratuite su energia ed efficienza energetica. Durano circa 20 minuti e sono indirizzate a cittadini, studenti, docenti, tecnici e addetti ai lavori. <br/>
                                <a href="https://italiainclassea.enea.it/e-learning/" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary"><span class="chip-label">Vai al Link</span></span></a> 
                               
                                <h5 className="mt-4">Hai la curiosità di conoscere alcuni casi esemplari di riqualificazione energetica di edifici?</h5>
                                Per scoprire le migliori “storie di efficienza energetica”, attraverso la raccolta di testimonianze dirette di tecnici, amministratori, cittadini e professionisti, guarda il primo info-reality sull’efficienza energetica “Italia in classe A – la serie”.<br/> 
                                <a href="http://italiainclassea.enea.it/la-serie/" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary"><span class="chip-label">Vai al Link</span></span></a> <br/><br/>

                                Per consultare schede tecniche di edifici <a href="http://www.portale4e.it/centrale_dettaglio_imprese.aspx?ID=2" target="_blank" rel="noreferrer">nZeB</a> realizzati, consulta la sezione “Selezione di casi nZEB” della Pubblicazione ENEA “Osservatorio degli edifici a energia quasi zero (nZeB) in Italia. 2016-2018” <br/> 
                                <a href="https://www.efficienzaenergetica.enea.it/pubblicazioni/osservatorio-degli-edifici-a-energia-quasi-zero-nzeb-in-italia-2016-2018.html" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary"><span class="chip-label">Vai al Link</span></span></a> <br/><br/>

                                
                                Per consultare casi studio di interventi di riqualificazione energetica di edifici portati avanti da Amministrazioni Pubbliche, consulta la Pubblicazione realizzata dal progetto ES-PA: “Casi studio per la riqualificazione energetica e la sicurezza sismica di edifici pubblici”. <br/>
                                <a href="https://www.espa.enea.it/prodotti-e-servizi/linee-guida-operative-per-la-riqualificazione-energetica-e-la-sicurezza-sismica-di-specifiche-tipologie-di-edifici-pubblici.html" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary"><span class="chip-label">Vai al Link</span></span></a> (previa registrazione)<br/>

                                <h5 className="mt-4">Corsi di aggiornamento in e-learning per professionisti</h5>
                                Accedendo alla piattaforma di e-learning dell’ENEA potrai scegliere tra corsi testuali, videolezioni e giochi pensati per essere fruiti “on demand” gratuitamente. E’ possibile richiedere l’attestato di partecipazione.<br/>
                                <a href="https://formazione.enea.it/el_corsi" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary"><span class="chip-label">Vai al Link</span></span></a><br/>

                                <h5 className="mt-4">Desideri esplorare le qualifiche e certificazioni valide sul territorio nazionale?</h5>
                                Consulta l’Atlante delle Qualificazioni pubblicato da INAPP (Istituto Nazionale per l’analisi delle Politiche Pubbliche) <br/>
                                <a href="https://atlantelavoro.inapp.org/atlante_repertori.php" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary"><span class="chip-label">Vai al Link</span></span></a><br/>

                                <h5 className="mt-4">Cerchi la professionalità più adatta alle tue esigenze?</h5>
                                Consulta l’Atlante delle Professioni pubblicato da INAPP (Istituto Nazionale per l’analisi delle Politiche Pubbliche)  <br/>
                                <a href="https://atlantelavoro.inapp.org/atlante_professioni.php" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary"><span class="chip-label">Vai al Link</span></span></a><br/><br/>

                                Ogni regione ha un proprio repertorio di profili professionali, diverso da quello delle altre regioni. <br/>
                                I repertori contengono delle schede che, per ogni figura professionale, descrivono le Unità di Competenza con le relative conoscenze e abilità.<br/>
                                Elenco link regionali dei Repertori degli standard professionali per ogni regione, per esempio:<br/>
                                <a href="http://www.ifl.servizirl.it/site" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary"><span class="chip-label">Lombardia</span></span></a><br/>
                                <a href="https://janet.regione.marche.it/RicercaProfili" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary"><span class="chip-label">Marche</span></span></a><br/>
                                <a href="https://selfi.regione.abruzzo.it/pages/repertorio-regionale-delle-qualificazioni-e-dei-profili" target="_blank" rel="noreferrer"><span class="chip chip-simple chip-primary"><span class="chip-label">Abruzzo</span></span></a>

                            </p>
                            <div className="row py-4">
                                <div className="col-xs-12 col-md-4">
                                    <div className="card-wrapper">
                                    <div className="card card-img no-after rounded shadow">
                                        <div className="img-responsive-wrapper">
                                        <div className="img-responsive img-responsive-panoramic">
                                            <figure className="img-wrapper">
                                            <img src={kcom} title="kcom" alt="kcom" />
                                            </figure>
                                            
                                        </div>
                                        </div>
                                        <div className="card-body">
                                        
                                        <h4 className="card-title">
                                        Piattaforma Multimediale K-Com
                                        </h4>
                                        <p className="card-text">
                                        La piattaforma di comunicazione della conoscenza in materia di efficienza energetica. Potrai navigare i percorsi di conoscenza, in modalità semplice o avanzata, selezionando la tematica di interesse e il tipo di risorsa preferito.
                                        </p>
                                        <div className="mt-5">
                                        <Link className="read-more" to="kcom">
                                            <span className="text">Leggi di più</span>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-arrow-right" }></use>
                                            </svg>
                                        </Link>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div> 
                                <div className="col-xs-12 col-md-4">
                                    <div className="card-wrapper">
                                    <div className="card card-img no-after rounded shadow">
                                        <div className="img-responsive-wrapper">
                                        <div className="img-responsive img-responsive-panoramic">
                                            <figure className="img-wrapper">
                                            <img src={elearn} title="elearn" alt="elearn" />
                                            </figure>
                                            
                                        </div>
                                        </div>
                                        <div className="card-body">
                                        
                                        <h4 className="card-title">
                                        Piattaforma di e-Learning ENEA
                                        </h4>
                                        <p className="card-text">
                                        La piattaforma di risorse per la formazione degli adulti e per la scuola. Un’ampia scelta di corsi e materiali gratuiti e fruibili “on demand”, senza vincoli di date di inizio e fine corso, né di orari. E' necessario solo registrarsi!
                                        </p>
                                        <div className="mt-5">
                                        <Link className="read-more" to="elearn">
                                            <span className="text">Leggi di più</span>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-arrow-right" }></use>
                                            </svg>
                                        </Link>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-4">
                                    <div className="card-wrapper">
                                    <div className="card card-img no-after rounded shadow">
                                        <div className="img-responsive-wrapper">
                                        <div className="img-responsive img-responsive-panoramic">
                                            <figure className="img-wrapper">
                                            <img src={glossario} title="glossario" alt="glossario" />
                                            </figure>
                                            
                                        </div>
                                        </div>
                                        <div className="card-body">
                                        
                                        <h4 className="card-title">
                                        Glossario
                                        </h4>
                                        <p className="card-text">
                                        Un glossario di facile consultazione per familiarizzare con i termini dell’ efficienza energetica.
                                        </p>
                                        <div className="mt-5">
                                        <Link className="read-more" to="glossario">
                                            <span className="text">Leggi di più</span>
                                            <svg className="icon">
                                            <use href={ sprite2 + "#it-arrow-right" }></use>
                                            </svg>
                                        </Link>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Formazione_professionale;