import React from "react";
import HeaderTop from "../components/HeaderTop.js";
import FooterBottom from "../components/FooterBottom.js";
import { Link } from "react-router-dom";
import { LinkList, LinkListItem } from 'design-react-kit';
import sprite2 from "../assets/svg/sprite.svg";


import normative from "../assets/img/normative.png";
import "../App.css";

function Normative() {
  return (
    <div>
    <HeaderTop />
           <div className="container my-4">
               <section id="briciole" className="container px-4 my-4">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator">/</span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Normative
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                        <div>
                            <h1>Normative </h1>
                            <div className="row">
                                <p className="col-md-8">
                                    <LinkList>
                                        <LinkListItem>
                                        <svg className="icon">
                                        <use href={ sprite2 + "#it-pa" }></use>
                                        </svg>
                                        <span><Link to="/ape">Certificazione Energetica degli Edifici (APE) </Link></span>
                                        </LinkListItem>
                                        <LinkListItem>
                                        <svg className="icon">
                                        <use href={ sprite2 + "#it-pa" }></use>
                                        </svg>
                                        <span><Link to="/detrazioni Fiscali">Detrazioni Fiscali</Link></span>
                                        </LinkListItem>
                                        <LinkListItem>
                                        <svg className="icon">
                                        <use href={ sprite2 + "#it-pa" }></use>
                                        </svg>
                                        <span><Link to="/diagnosi_energetiche">Diagnosi Energetiche </Link></span>
                                        </LinkListItem>
                                        <LinkListItem>
                                        <svg className="icon">
                                        <use href={ sprite2 + "#it-pa" }></use>
                                        </svg>
                                        <span><Link to="/conto_termico">Conto Termico </Link></span>
                                        </LinkListItem>
                                        <LinkListItem>
                                        <svg className="icon">
                                        <use href={ sprite2 + "#it-pa" }></use>
                                        </svg>
                                        <span><Link to="/certificati_bianchi">Certificati Bianchi </Link></span>
                                        </LinkListItem>
                                        <LinkListItem>
                                        <svg className="icon">
                                        <use href={ sprite2 + "#it-pa" }></use>
                                        </svg>
                                        <span><Link to="/fondo_nazionale">Fondo Nazionale Efficienza Energetica </Link></span>
                                        </LinkListItem>
                                        <LinkListItem>
                                        <svg className="icon">
                                        <use href={ sprite2 + "#it-pa" }></use>
                                        </svg>
                                        <span><Link to="/prepac">Linee guida PREPAC </Link></span>
                                        </LinkListItem>
                                        <LinkListItem>
                                        <svg className="icon">
                                        <use href={ sprite2 + "#it-pa" }></use>
                                        </svg>
                                        <span><Link to="/nzeb">NZEB </Link></span>
                                        </LinkListItem>
                                        <LinkListItem>
                                        <svg className="icon">
                                        <use href={ sprite2 + "#it-pa" }></use>
                                        </svg>
                                        <span><Link to="/epc">Contratto Prestazione Energetica (EPC)</Link></span>
                                        </LinkListItem>
                                    </LinkList>
                                </p>
                                <img className="col-md-4 mw-300 h-100" src={normative} title="normative" alt="normative" />
                            </div>

                         </div>
                        
                </section>
         </div>
    <FooterBottom />
    </div>
  );
};

export default Normative;